import debug from 'debug';
import {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {type DateRangeOptionsHookParams, getInstance} from '~common/hooks/dateRangeSelection/dateRangeSelectionSingleton';
import {setDateRangeOption} from '~common/state';
import {DateNavigatingInterval, DateRangeSelection, DefaultDateSetting} from '~common/types';

const d = debug('tacs.web.common.hooks.dateRangeSelection.useDateRangeSelectionSwitcher');

/**
 * This hook gets the route and options array and matches against the current pathname. If any of them match
 * it sets the required DateRangeSelection. If none of them match it resets to DateRangeSelection.DateRange.
 */
export function useDateRangeSelectionSwitcher() {
	const dispatch = useDispatch();
	const {pathname} = useLocation();
	const [firstLoad, setFirstLoad] = useState(true);
	const singleton = useMemo(() => {
		return getInstance();
	}, []);

	useEffect(() => {
		const result = singleton.getOptions().reduce(
			(memo, v) => {
				if (v.routeMatch(pathname)) {
					if (memo.matchedCount === 0) {
						return {...memo, matchedCount: 1, matchedOption: v};
					}
					return {...memo, matchedCount: memo.matchedCount + 1};
				}
				return memo;
			},
			{matchedCount: 0, matchedOption: null} as {matchedCount: number; matchedOption: DateRangeOptionsHookParams | null},
		);

		// This may seem useless at first, but it triggers a re-render which updates the date range option properly.
		if (firstLoad) setFirstLoad(false);

		d(`Matched: ${result.matchedCount}`);

		if (result.matchedCount === 0) {
			dispatch(
				setDateRangeOption({
					selection: DateRangeSelection.DateRange,
					allowFuture: false,
					lockRangeInFy: false,
					navigatingInterval: DateNavigatingInterval.FisYear,
				}),
			);
		} else {
			dispatch(
				setDateRangeOption({
					selection: result.matchedOption?.selection || DateRangeSelection.DateRange,
					allowFuture: result.matchedOption?.allowFuture,
					lockRangeInFy: !!result.matchedOption?.lockRangeInFy,
					defaultDateSetting: result.matchedOption?.defaultDateSetting || DefaultDateSetting.CurrentFy,
					navigatingInterval: (() => {
						switch (result.matchedOption?.selection) {
							case DateRangeSelection.FullMonth:
								return DateNavigatingInterval.Month;
							case DateRangeSelection.FullYear:
								return DateNavigatingInterval.CalYear;
							default:
								return result.matchedOption?.navigatingInterval;
						}
					})(),
				}),
			);
		}
	}, [dispatch, firstLoad, pathname, singleton]);
}
