import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getUserInfoById.graphql';

const defaultOptions = {} as const;
export type GetUserInfoByIdQueryVariables = Types.Exact<{
	id: Types.Scalars['String'];
}>;

export type GetUserInfoByIdQuery = {getUserById?: {id: string; givenName: string; surname: string; email: string} | null};

export type GetUserInfoByIdType = {id: string; givenName: string; surname: string; email: string};

/**
 * __useGetUserInfoByIdQuery__
 *
 * To run a query within a React component, call `useGetUserInfoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserInfoByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserInfoByIdQuery, GetUserInfoByIdQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetUserInfoByIdQuery, GetUserInfoByIdQueryVariables>(Operations, options);
}
export function useGetUserInfoByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserInfoByIdQuery, GetUserInfoByIdQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetUserInfoByIdQuery, GetUserInfoByIdQueryVariables>(Operations, options);
}
export type GetUserInfoByIdQueryHookResult = ReturnType<typeof useGetUserInfoByIdQuery>;
export type GetUserInfoByIdLazyQueryHookResult = ReturnType<typeof useGetUserInfoByIdLazyQuery>;
export type GetUserInfoByIdQueryResult = Apollo.QueryResult<GetUserInfoByIdQuery, GetUserInfoByIdQueryVariables>;
