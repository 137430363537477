import {useAuthenticatedState} from '@imperium/auth-client';
import debug from 'debug';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {getFullName} from '~lib/getFullName';
import {setActiveAgentId, setOfficeId, setOrganizationId, setOrganizationName, setUserId, setUserName} from '../state';
import {useGetLoggedInUserDetailsQuery} from './getLoggedInUserDetails';

const d = debug('tacs.web.user.hooks.useUserInfo');

export function useUserInfo() {
	const dispatch = useDispatch();
	const {id} = useAuthenticatedState();

	d(`Rendering useUserInfo hook: ${id}`);

	useGetLoggedInUserDetailsQuery({
		skip: !id,
		variables: {
			userId: id || '',
		},
		onCompleted: ({getUserById}) => {
			dispatch(setUserId(id || null));
			dispatch(setUserName(getFullName(getUserById) || null));
			dispatch(setActiveAgentId(getUserById?.activeAgent?.id || null));
			dispatch(setOrganizationId(getUserById?.activeAgent?.organization?.id || null));
			dispatch(setOrganizationName(getUserById?.activeAgent?.organization?.name || null));
			dispatch(setOfficeId(getUserById?.activeAgent?.office?.id || null));
		},
	});

	useEffect(() => {
		if (!id) {
			dispatch(setUserId(null));
			dispatch(setUserName(null));
			dispatch(setOrganizationId(null));
			dispatch(setOfficeId(null));
		}
	}, [dispatch, id]);

	return null;
}
