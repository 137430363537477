import {useCan} from '@imperium/auth-client';
import {createPages} from '@imperium/layout';
import loadable from '@loadable/component';
import {Loading} from '~common/components';
import {AccountTypeEnum, Permission} from '~core/graphql';
import {needsPermission} from '~lib/permissionHelpers';
import {ExportFiles} from './components/accountStatus/ExportFiles';
import {AccountEventSidebar} from './components/accounts/AccountEventTimeline/AccountEventSidebar';
import {AccountEventTimelineHeader} from './components/accounts/AccountEventTimeline/AccountEventTimelineHeader';
import {BasicAccountInfo} from './components/accounts/BasicAccountInfo';
import {BasicAccountInfoSidebar} from './components/accounts/BasicAccountInfoSidebar';
import {CorporateAccountInfo} from './components/accounts/CorporateAccountInfo';
import {CorporateAccountInfoSidebar} from './components/accounts/CorporateAccountInfoSidebar';
import {PersonalAccountInfo} from './components/accounts/PersonalAccountInfo';
import {PersonalAccountInfoSidebar} from './components/accounts/PersonalAccountInfoSidebar';
import {SelfEmployedAccountInfo} from './components/accounts/SelfEmployedAccountInfo';
import {SelfEmployedAccountInfoSidebar} from './components/accounts/SelfEmployedAccountInfoSidebar';
import {AuthorizedRepresentativeAddSidebar} from './components/authorizedRepresentatives/AuthorizedRepresentativeAddSidebar';
import {AuthorizedRepresentativeEditSidebar} from './components/authorizedRepresentatives/AuthorizedRepresentativeEditSidebar';
import {AuthorizedRepresentativeViewSidebar} from './components/authorizedRepresentatives/AuthorizedRepresentativeViewSidebar';
import {DirectorAddSidebar} from './components/directors/DirectorAddSidebar';
import {DirectorEditSidebar} from './components/directors/DirectorEditSidebar';
import {DirectorViewSidebar} from './components/directors/DirectorViewSidebar';
import {EmployeeAddSidebar} from './components/employees/EmployeeAddSidebar';
import {EmployeeEditSidebar} from './components/employees/EmployeeEditSidebar';
import {EmployeeViewSidebar} from './components/employees/EmployeeViewSidebar';
import {EmployeesSidebar} from './components/employees/EmployeesSidebar';
import {DeleteLeadButton} from './components/leads/DeleteLeadButton';
import {LeadsTableSidebar} from './components/leads/LeadsTableSidebar';
import {PartnerAddSidebar} from './components/partners/PartnerAddSidebar';
import {PartnerEditSidebar} from './components/partners/PartnerEditSidebar';
import {PartnerViewSidebar} from './components/partners/PartnerViewSidebar';
import UnmanagedAccountsSidebar from './components/unmanagedAccounts/UnmanagedAccountsSidebar';
import {routes} from './routes';
import {useAccountState} from './state';

const AccountEventTimeline = loadable(() => import('./components/accounts/AccountEventTimeline/AccountEventTimeline'));
const AccountCreate = loadable(() => import('./components/accounts/accountCreate/AccountCreateSteps/AccountCreateSteps'));
const AccountCreateFromLead = loadable(() => import('./components/accounts/AccountCreateFromLead/AccountCreateFromLead'));
const AccountStatus = loadable(() => import('./components/accountStatus/AccountStatus'));
const AuthorizedRepresentativeCreate = loadable(
	() => import('./components/authorizedRepresentatives/AuthorizedRepresentativeCreateForm/AuthorizedRepresentativeCreateForm'),
);
const AuthorizedRepresentativeEdit = loadable(
	() => import('./components/authorizedRepresentatives/AuthorizedRepresentativeEditForm/AuthorizedRepresentativeEditForm'),
);
const AuthorizedRepresentatives = loadable(
	() => import('./components/authorizedRepresentatives/AuthorizedRepresentativeTable/AuthorizedRepresentativeTable'),
);
const AuthorizedRepresentativeView = loadable(
	() => import('./components/authorizedRepresentatives/AuthorizedRepresentativeView/AuthorizedRepresentativeView'),
);
const DirectorCreate = loadable(() => import('./components/directors/DirectorCreateForm/DirectorCreateForm'));
const DirectorEdit = loadable(() => import('./components/directors/DirectorEditForm/DirectorEditForm'));
const DirectorTable = loadable(() => import('./components/directors/DirectorTable/DirectorTable'));
const DirectorView = loadable(() => import('./components/directors/DirectorView/DirectorView'));
const EmployeeCreate = loadable(() => import('./components/employees/EmployeeCreateForm'));
const Employees = loadable(() => import('./components/employees/EmployeeTable/EmployeeTable'));
const EmployeeEdit = loadable(() => import('./components/employees/EmployeeEditForm'), {
	resolveComponent: c => c.EmployeeEditForm,
});
const EmployeeView = loadable(() => import('./components/employees/EmployeeView/EmployeeView'));
const PartnerCreate = loadable(() => import('./components/partners/PartnerCreateForm/PartnerCreateForm'));
const PartnerEdit = loadable(() => import('./components/partners/PartnerEditForm/PartnerEditForm'));
const Partners = loadable(() => import('./components/partners/PartnerTable/PartnerTable'));
const PartnerView = loadable(() => import('./components/partners/PartnerView/PartnerView'));

const UnmanagedAccountsTable = loadable(() => import('./components/unmanagedAccounts/UnmanagedAccountsTable'));
const AgentAccountsTransfer = loadable(() => import('./components/accountTransfer/AgentAccountsTransfer'), {
	resolveComponent: c => c.AgentAccountsTransfer,
});
const ManagerAccountsTransfer = loadable(() => import('./components/accountTransfer/ManagerAccountsTransfer'), {
	resolveComponent: c => c.ManagerAccountsTransfer,
});
const CreateLeadForm = loadable(() => import('./components/leads/CreateLeadForm'), {
	resolveComponent: c => c.CreateLeadForm,
});
const ViewLeadForm = loadable(() => import('./components/leads/ViewLeadForm'), {
	resolveComponent: c => c.ViewLeadForm,
});
const EditLeadForm = loadable(() => import('./components/leads/EditLeadForm'), {
	resolveComponent: c => c.EditLeadForm,
});
const LeadsTable = loadable(() => import('./components/leads/LeadsTable'));
const LeadReminder = loadable(() => import('./components/leads/LeadReminder'), {
	resolveComponent: c => c.LeadReminder,
});

export const routeProps = createPages(routes, {
	account: {
		stateSelectorHook: useAccountState,
		header: 'Account Info',
		content: data => {
			/*
				State and params do not always match on first/second render.
				Since we are relying on state to render the right component for each type, we also need to rely on state for the id.
				Otherwise, we might end up rendering the corporate account form with a self-employed account id -STT.
			 */
			switch (data.state.type) {
				case AccountTypeEnum.CorporateAccount:
					return <CorporateAccountInfo accountInfoId={data.state.accountInfoId} />;
				case AccountTypeEnum.SelfEmployedAccount:
					return <SelfEmployedAccountInfo accountInfoId={data.state.accountInfoId} />;
				case AccountTypeEnum.PersonalAccount:
					return <PersonalAccountInfo accountInfoId={data.state.accountInfoId} />;
				case AccountTypeEnum.BasicAccount:
					return <BasicAccountInfo accountInfoId={data.state.accountInfoId} />;
				default:
					return <Loading />;
			}
		},
		sidebar: [
			{
				stateSelectorHook: [useAccountState],
				permissionSelectorHook: [
					data => {
						const [viewAdminPayrollAccount] = useCan([
							{permission: Permission.ViewAdminPayrollAccount, data: {accountInfoId: data?.state.accountInfoId}},
						]);
						return {viewAdminPayrollAccount};
					},
				],
				visible: {...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount])},
				render: data => {
					switch (data.state.type) {
						case AccountTypeEnum.CorporateAccount:
							return <CorporateAccountInfoSidebar accountInfoId={data.state.accountInfoId} />;
						case AccountTypeEnum.SelfEmployedAccount:
							return <SelfEmployedAccountInfoSidebar accountInfoId={data.state.accountInfoId} />;
						case AccountTypeEnum.PersonalAccount:
							return <PersonalAccountInfoSidebar accountInfoId={data.state.accountInfoId} />;
						case AccountTypeEnum.BasicAccount:
							return <BasicAccountInfoSidebar accountInfoId={data.state.accountInfoId} />;
						default:
							return <Loading />;
					}
				},
			},
		],
	},
	accountStatus: {
		header: 'Account Status',
		content: () => <AccountStatus />,
		sidebar: [{render: ExportFiles}],
	},
	createAccount: {
		header: 'Create Account',
		content: () => <AccountCreate />,
	},
	createAccountFromLead: {
		content: () => <AccountCreateFromLead />,
	},
	timeline: {
		header: <AccountEventTimelineHeader />,
		content: () => <AccountEventTimeline />,
		sidebar: [{render: AccountEventSidebar}],
	},
	employees: {
		header: 'Employees',
		content: () => <Employees />,
		sidebar: [{render: EmployeesSidebar, visible: {...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount])}}],
	},
	createEmployee: {
		header: 'Create New Employee',
		content: () => <EmployeeCreate />,
		sidebar: [{render: EmployeeAddSidebar}],
	},
	viewEmployee: {
		header: 'View Employee',
		content: () => <EmployeeView />,
		sidebar: [
			{
				visible: {...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount])},
				render: EmployeeViewSidebar,
			},
		],
	},
	editEmployee: {
		content: () => <EmployeeEdit />,
		sidebar: [
			{
				visible: {...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount])},
				render: EmployeeEditSidebar,
			},
		],
	},
	partners: {
		header: 'Partners',
		content: () => <Partners />,
		sidebar: [
			{
				to: dat => routes.to.createPartner({accountInfoId: dat.params.accountInfoId}),
				color: 'green',
				text: 'Add Partner',
				visible: {...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount])},
			},
		],
	},
	createPartner: {
		header: 'Create New Partner',
		content: () => <PartnerCreate />,
		sidebar: [{render: PartnerAddSidebar}],
	},
	viewPartner: {
		header: 'View Partner',
		content: () => <PartnerView />,
		sidebar: [{render: PartnerViewSidebar, visible: {...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount])}}],
	},
	editPartner: {
		header: 'Edit Partner',
		content: () => <PartnerEdit />,
		sidebar: [{render: PartnerEditSidebar, visible: {...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount])}}],
	},
	directors: {
		header: 'Directors',
		content: () => <DirectorTable />,
		sidebar: [
			{
				to: dat => routes.to.createDirector({accountInfoId: dat.params.accountInfoId}),
				color: 'green',
				text: 'Add Director',
				visible: {...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount])},
			},
		],
	},
	createDirector: {
		header: 'Create New Director',
		content: () => <DirectorCreate />,
		sidebar: [{render: DirectorAddSidebar}],
	},
	viewDirector: {
		header: 'View Director',
		content: () => <DirectorView />,
		sidebar: [{render: DirectorViewSidebar, visible: {...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount])}}],
	},
	editDirector: {
		header: 'Edit Director',
		content: () => <DirectorEdit />,
		sidebar: [{render: DirectorEditSidebar}],
	},
	authorizedRepresentatives: {
		header: 'Authorized Representatives',
		content: () => <AuthorizedRepresentatives />,
		sidebar: [
			{
				to: dat => routes.to.createAuthorizedRepresentative({accountInfoId: dat.params.accountInfoId}),
				color: 'green',
				text: 'Add Authorized Representative',
				visible: {...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount])},
			},
		],
	},
	createAuthorizedRepresentative: {
		header: 'Create New Authorized Representative',
		content: () => <AuthorizedRepresentativeCreate />,
		sidebar: [{render: AuthorizedRepresentativeAddSidebar}],
	},
	viewAuthorizedRepresentative: {
		header: 'View Authorized Representative',
		content: () => <AuthorizedRepresentativeView />,
		sidebar: [
			{
				visible: {...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount])},
				render: AuthorizedRepresentativeViewSidebar,
			},
		],
	},
	editAuthorizedRepresentative: {
		header: 'Edit Authorized Representative',
		content: () => <AuthorizedRepresentativeEdit />,
		sidebar: [{render: AuthorizedRepresentativeEditSidebar}],
	},
	managerAccountsTransfer: {
		header: 'Transfer Accounts from Manager',
		content: () => <ManagerAccountsTransfer />,
	},
	agentAccountsTransfer: {
		header: 'Transfer Accounts from Agent',
		content: () => <AgentAccountsTransfer />,
	},
	leads: {
		header: 'Leads',
		content: () => <LeadsTable />,
		sidebar: [
			{render: LeadsTableSidebar},
			{
				text: 'Add Lead',
				color: 'green',
				to: routes.to.createLead(),
			},
		],
	},
	createLead: {
		header: 'Create Lead',
		content: () => <CreateLeadForm />,
	},
	viewLead: {
		header: 'Lead',
		content: () => <ViewLeadForm />,
		sidebar: [
			{
				to: dat => routes.to.createAccountFromLead({leadId: dat.params.leadId}),
				color: 'green',
				text: 'Create Account',
			},
			{
				to: dat => routes.to.editLead({leadId: dat.params.leadId}),
				color: 'yellow',
				text: 'Edit Lead',
			},
			{
				to: dat => routes.to.leadReminder({leadId: dat.params.leadId}),
				color: 'blue',
				text: 'Set Reminder',
			},
			{
				render: DeleteLeadButton,
			},
		],
	},
	leadReminder: {
		header: 'Lead Reminder',
		content: () => <LeadReminder />,
	},
	editLead: {
		header: 'Edit Lead',
		content: () => <EditLeadForm />,
	},
	unmanagedAccounts: {
		header: 'Unmanaged Accounts',
		content: () => <UnmanagedAccountsTable />,
		sidebar: [{render: UnmanagedAccountsSidebar}],
	},
});
