import * as Apollo from '@apollo/client';

import type {LocalDate} from '@js-joda/core';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getSelfEmployedAccountInfoInformation.graphql';

const defaultOptions = {} as const;
export type GetSelfEmployedAccountInfoInformationQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
	currentlyLoggedInUserId: Types.Scalars['String'];
}>;

export type GetSelfEmployedAccountInfoInformationQuery = {
	getSelfEmployedAccountByAccountInfoId?: {
		id: string;
		version: number;
		yearEnd?: LocalDate | null;
		gstFilingFrequency: Types.GstFilingFrequencyEnum;
		communicationCode?: string | null;
		businessNumber?: string | null;
		accountInfo: {
			id: string;
			version: number;
			name: string;
			friendlyId: number;
			archived: boolean;
			address?: {
				id: string;
				version: number;
				lineOne?: string | null;
				lineTwo?: string | null;
				city?: string | null;
				province?: string | null;
				postalCode?: string | null;
				country?: string | null;
			} | null;
			manager?: {id: string; version: number; givenName: string; surname: string} | null;
			defaultAgent?: {id: string; version: number; givenName: string; surname: string} | null;
			organization?: {id: string; name: string} | null;
			office?: {id: string; name: string} | null;
		};
	} | null;
	getUserById?: {id: string; activeAgent?: {id: string; version: number} | null} | null;
};

export type GetSelfEmployedAccountInfoInformationType = {
	id: string;
	version: number;
	yearEnd?: LocalDate | null;
	gstFilingFrequency: Types.GstFilingFrequencyEnum;
	communicationCode?: string | null;
	businessNumber?: string | null;
	accountInfo: {
		id: string;
		version: number;
		name: string;
		friendlyId: number;
		archived: boolean;
		address?: {
			id: string;
			version: number;
			lineOne?: string | null;
			lineTwo?: string | null;
			city?: string | null;
			province?: string | null;
			postalCode?: string | null;
			country?: string | null;
		} | null;
		manager?: {id: string; version: number; givenName: string; surname: string} | null;
		defaultAgent?: {id: string; version: number; givenName: string; surname: string} | null;
		organization?: {id: string; name: string} | null;
		office?: {id: string; name: string} | null;
	};
};

/**
 * __useGetSelfEmployedAccountInfoInformationQuery__
 *
 * To run a query within a React component, call `useGetSelfEmployedAccountInfoInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfEmployedAccountInfoInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfEmployedAccountInfoInformationQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *      currentlyLoggedInUserId: // value for 'currentlyLoggedInUserId'
 *   },
 * });
 */
export function useGetSelfEmployedAccountInfoInformationQuery(
	baseOptions: Apollo.QueryHookOptions<GetSelfEmployedAccountInfoInformationQuery, GetSelfEmployedAccountInfoInformationQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetSelfEmployedAccountInfoInformationQuery, GetSelfEmployedAccountInfoInformationQueryVariables>(Operations, options);
}
export function useGetSelfEmployedAccountInfoInformationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetSelfEmployedAccountInfoInformationQuery, GetSelfEmployedAccountInfoInformationQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetSelfEmployedAccountInfoInformationQuery, GetSelfEmployedAccountInfoInformationQueryVariables>(Operations, options);
}
export type GetSelfEmployedAccountInfoInformationQueryHookResult = ReturnType<typeof useGetSelfEmployedAccountInfoInformationQuery>;
export type GetSelfEmployedAccountInfoInformationLazyQueryHookResult = ReturnType<typeof useGetSelfEmployedAccountInfoInformationLazyQuery>;
export type GetSelfEmployedAccountInfoInformationQueryResult = Apollo.QueryResult<
	GetSelfEmployedAccountInfoInformationQuery,
	GetSelfEmployedAccountInfoInformationQueryVariables
>;
