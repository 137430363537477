import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './editAccountManager.graphql';

const defaultOptions = {} as const;
export type EditAccountManagerMutationVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
	managerId: Types.Scalars['String'];
}>;

export type EditAccountManagerMutation = {
	editAccountInfoManager?: {id: string; version: number; manager?: {id: string; version: number; givenName: string; surname: string} | null} | null;
};

export type EditAccountManagerType = {
	id: string;
	version: number;
	manager?: {id: string; version: number; givenName: string; surname: string} | null;
};

export type EditAccountManagerMutationFn = Apollo.MutationFunction<EditAccountManagerMutation, EditAccountManagerMutationVariables>;

/**
 * __useEditAccountManagerMutation__
 *
 * To run a mutation, you first call `useEditAccountManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAccountManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAccountManagerMutation, { data, loading, error }] = useEditAccountManagerMutation({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *      managerId: // value for 'managerId'
 *   },
 * });
 */
export function useEditAccountManagerMutation(
	baseOptions?: Apollo.MutationHookOptions<EditAccountManagerMutation, EditAccountManagerMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<EditAccountManagerMutation, EditAccountManagerMutationVariables>(Operations, options);
}
export type EditAccountManagerMutationHookResult = ReturnType<typeof useEditAccountManagerMutation>;
export type EditAccountManagerMutationResult = Apollo.MutationResult<EditAccountManagerMutation>;
export type EditAccountManagerMutationOptions = Apollo.BaseMutationOptions<EditAccountManagerMutation, EditAccountManagerMutationVariables>;
