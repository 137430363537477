import React, {useMemo} from 'react';
import {Select, StrictSelectProps} from 'semantic-ui-react';
import {GeneralLedgerAccountTypeEnum, LinkedGlAccountType} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {selectLinkedAccount} from '../../util/selectLinkedAccount';
import {useGetChartOfAccountsByAccountInfoIdQuery} from '../generalJournal/graphql/getChartOfAccountsByAccountInfoId';

const PAYMENT_ACCOUNT_GL_ACCOUNT_RANGE = {
	MIN: 1000,
	MAX: 1099,
};

interface PaymentGlAccountDropdownProps {
	accountInfoId: string;
}

export function PaymentGlAccountDropdown(props: PaymentGlAccountDropdownProps & Omit<StrictSelectProps, 'options'>) {
	const {accountInfoId, ...extraProps} = props;
	const throwError = useAsyncError();
	const {
		loading: chartOfAccountsLoading,
		error: chartOfAccountsError,
		data: chartOfAccountsData,
	} = useGetChartOfAccountsByAccountInfoIdQuery({
		variables: {
			accountInfoId,
		},
	});
	if (chartOfAccountsError) throwError(chartOfAccountsError);

	const paymentAccountChartOfAccountsOptions = useMemo(() => {
		return (
			chartOfAccountsData?.getChartOfAccountsByAccountInfoId?.glAccounts
				?.filter(
					glAcc =>
						(glAcc.type === GeneralLedgerAccountTypeEnum.Account || glAcc.type === GeneralLedgerAccountTypeEnum.Subaccount) &&
						glAcc.code >= PAYMENT_ACCOUNT_GL_ACCOUNT_RANGE.MIN &&
						glAcc.code <= PAYMENT_ACCOUNT_GL_ACCOUNT_RANGE.MAX &&
						glAcc.id !==
							selectLinkedAccount(LinkedGlAccountType.Receivables, chartOfAccountsData.getChartOfAccountsByAccountInfoId?.linkedAccounts)?.id,
				)
				.sort((a, b) => (a.code > b.code ? 1 : -1))
				.map(glAcc => ({
					key: glAcc.id,
					value: glAcc.id,
					text: `${glAcc.code} - ${glAcc.name}`,
				})) || []
		);
	}, [chartOfAccountsData]);

	return <Select {...extraProps} loading={chartOfAccountsLoading} options={paymentAccountChartOfAccountsOptions} />;
}
