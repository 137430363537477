import {LocalDate} from '@js-joda/core';
import debug from 'debug';
import {groupBy} from 'lodash-es';
import {useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {List, Popup} from 'semantic-ui-react';
import {generateList, type GroupedPresetItems} from '~common/components/DateRangeSelector/tabs/generatePresets';
import styles from '~common/components/DateRangeSelector/tabs/style.module.css';
import {setDate, setDateNavigatingInterval, useCommonState} from '~common/state';
import {useAccountState} from '../../../../accounts/state';

const d = debug('tacs.web.common.components.DateRangeSelector.tabs.PresetTab');

function presetGroupSort(a: string, b: string) {
	if (a === 'undefined' && b === 'undefined') {
		return 0;
	}
	if (a === 'undefined') {
		return -1;
	}
	if (b === 'undefined') {
		return 1;
	}
	return a.localeCompare(b);
}

export function PresetTab({hidePanel}: {hidePanel: () => void}) {
	const dispatch = useDispatch();
	const {startDate, endDate, dateRangeSelection, lockRangeInFy, dateNavigatingInterval} = useCommonState();
	const {yearEnd} = useAccountState();

	const list: GroupedPresetItems = useMemo(() => {
		const l = generateList({
			startDate,
			endDate,
			yearEnd: yearEnd || LocalDate.now().withMonth(12).withDayOfMonth(31),
			dateRangeSelection,
			lockRangeInFy,
		});
		return groupBy(l, 'group');
	}, [dateRangeSelection, endDate, yearEnd, lockRangeInFy, startDate]);

	return (
		<div className={styles.tab} style={{whiteSpace: 'nowrap'}}>
			<List className={styles.presetList}>
				{Object.keys(list)
					.sort(presetGroupSort)
					.map(group => (
						<List.Item key={group}>
							<List.Icon name="folder" />
							<List.Header>{group === 'undefined' ? 'Preset' : group}</List.Header>
							<List.List className={styles.presetGroup}>
								{list[group].map(item => (
									<List.Item
										key={`${item.group}${item.title}`}
										onClick={() => {
											if (item.navInterval !== undefined && dateNavigatingInterval !== item.navInterval)
												dispatch(setDateNavigatingInterval(item.navInterval));
											dispatch(setDate({start: item.start, end: item.end}));
											hidePanel();
										}}
									>
										<List.Icon name="calendar alternate outline" />
										<List.Content>
											<List.Description>
												{item.hint ? <Popup content={item.hint} trigger={<span>{item.title}</span>} /> : item.title}
											</List.Description>
										</List.Content>
									</List.Item>
								))}
							</List.List>
						</List.Item>
					))}
			</List>
		</div>
	);
}

// {item.hint ? <Popup content={item.hint} trigger={<Icon size="small" name="question" color="orange" />} /> : null}
