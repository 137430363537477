import {useAuthenticatedState} from '@imperium/auth-client';
import {List, Divider, Placeholder} from 'semantic-ui-react';
import {CloneAccountButton} from './CloneAccountButton';
import {useGetCorporateAccountInformationQuery} from './CorporateAccountInfo/getCorporateAccountInformation';
import {DeleteAccountButton} from './DeleteAccountButton';
import {EditAccountManager} from './EditAccountManager';
import {EditAccountName} from './EditAccountName';
import {EditAccountOffice} from './EditAccountOffice/EditAccountOffice';
import {EditAccountOrganizationAndOffice} from './EditAccountOrganizationAndOffice';
import {EditAccountRating} from './EditAccountRating';
import {EditAccountStatus} from './EditAccountStatus/EditAccountStatus';
import {EditAddress} from './EditAddress';
import {EditBusinessNumber} from './EditBusinessNumber';
import {EditDefaultAgent} from './EditDefaultAgent';
import {EditFilingFrequency} from './EditFilingFrequency';
import {EditIncorporationDate} from './EditIncorporationDate';
import {EditYearEnd} from './EditYearEnd/EditYearEnd';
import {ResetAccountingDataButton} from './ResetAccountingDataButton';

export function CorporateAccountInfoSidebar({accountInfoId}: {accountInfoId: string}) {
	const {id} = useAuthenticatedState();
	const {data, error, loading, refetch} = useGetCorporateAccountInformationQuery({variables: {id: accountInfoId, currentlyLoggedInUserId: id || ''}});
	if (error) throw error;
	if (loading && !data?.getCorporateAccountByAccountInfoId) {
		return (
			<Placeholder>
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
			</Placeholder>
		);
	}

	return (
		<List>
			<List.Header as="h3">Actions</List.Header>
			<List.Item>
				<EditAccountName />
			</List.Item>
			<List.Item>
				<EditBusinessNumber onSave={() => refetch()} />
			</List.Item>
			<List.Item>
				<EditIncorporationDate onSave={() => refetch()} />
			</List.Item>
			<List.Item>
				<EditYearEnd onSave={() => refetch()} />
			</List.Item>
			<List.Item>
				<EditFilingFrequency onSave={() => refetch()} />
			</List.Item>
			<List.Item>
				<EditAddress onSave={() => refetch()} />
			</List.Item>
			<Divider />
			<List.Item>
				<EditAccountStatus />
			</List.Item>
			<List.Item>
				<EditAccountRating />
			</List.Item>
			<List.Item>
				<EditAccountOrganizationAndOffice />
			</List.Item>
			<List.Item>
				<EditAccountOffice />
			</List.Item>
			<List.Item>
				<EditAccountManager />
			</List.Item>
			<List.Item>
				<EditDefaultAgent />
			</List.Item>
			<Divider />
			<List.Item>
				<CloneAccountButton />
			</List.Item>
			<List.Item>
				<ResetAccountingDataButton />
			</List.Item>
			<List.Item>
				<DeleteAccountButton />
			</List.Item>
		</List>
	);
}
