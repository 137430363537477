import {env} from '@thx/env';
import {ChangeEvent, DragEvent, useRef, useState} from 'react';
import {Icon, Segment, SemanticCOLORS, SemanticICONS} from 'semantic-ui-react';
import {AppColors} from '~lib/AppColors';
import {defaults} from '../../defaults';

interface Content {
	icon?: SemanticICONS;
	iconColor?: SemanticCOLORS;
}
interface DropZoneProps {
	onFilesAdded: (array: File[]) => void;
	disabled?: boolean;
	content?: Content;
	multiple?: boolean;
}

export function DropZone(props: DropZoneProps) {
	const [highlight, setHighlight] = useState(false);
	const fileInputRef = useRef<HTMLInputElement>(null);
	const maxDocUploadSizeMb = env.getInt('maxDocUploadSizeMb', defaults.maxDocUploadSizeMb);

	let icon = <Icon size="big" color={props.content?.iconColor} name="cloud upload" />;

	if (props.content?.icon) {
		icon = <Icon size="large" color={props.content.iconColor} name={props.content.icon} />;
	}

	function fileListToArray(list: FileList) {
		const array: File[] = [];
		for (let i = 0; i < list.length; i++) {
			array.push(list[i]);
		}
		return array;
	}

	function onFilesAdded(event: ChangeEvent<HTMLInputElement>) {
		const {files} = event.target;
		if (props.onFilesAdded && files) {
			const array = fileListToArray(files);
			props.onFilesAdded(array);
		}
	}

	function onDragOver(event: DragEvent<HTMLDivElement>) {
		event.preventDefault();

		if (props.disabled) return;

		setHighlight(true);
	}

	function onDragLeave() {
		setHighlight(false);
	}

	function onDrop(event: DragEvent<HTMLDivElement>) {
		event.preventDefault();

		if (props.disabled) return;

		if (event.dataTransfer) {
			const {files} = event.dataTransfer;
			if (props.onFilesAdded) {
				const array = fileListToArray(files);
				props.onFilesAdded(array);
			}
			setHighlight(false);
		}
	}

	function openFileDialog() {
		if (!props.disabled) fileInputRef.current?.click();
	}

	return (
		<Segment onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop} style={{backgroundColor: highlight ? '#e0e1e2' : undefined}}>
			<div
				role="button"
				tabIndex={0}
				style={{
					height: 100,
					width: '100%',
					backgroundColor: props.disabled ? '#ddd' : '#fff',
					border: `2px dashed ${highlight ? AppColors.primaryColor : 'rgb(186, 186, 186)'}`,
					borderRadius: '5px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					fontSize: '16px',
					cursor: props.disabled ? 'default' : 'pointer',
				}}
				onDragOver={onDragOver}
				onDragLeave={onDragLeave}
				onDrop={onDrop}
				onClick={openFileDialog}
				onKeyDown={openFileDialog}
			>
				<input
					style={{display: 'none'}}
					ref={fileInputRef}
					type="file"
					multiple={props.multiple}
					onChange={onFilesAdded}
					accept=".pdf, .jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff, .heic, .avif"
				/>
				{icon}
				<span style={props.disabled ? {color: 'grey'} : {}}>Drag in a document or click here.</span>
				<i style={{color: 'gray'}}>File Size Limit: {maxDocUploadSizeMb}mbs.</i>
			</div>
		</Segment>
	);
}
