/* eslint-disable no-param-reassign */
import {createSliceHook} from '@imperium/state';
import {LocalDate} from '@js-joda/core';
import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {formatDate, toLocalDate} from '@thx/date';
import debug from 'debug';
import type {DateRangeOptions} from '~common/hooks/dateRangeSelection/dateRangeSelectionSingleton';
import {setDefaultDate} from '~common/hooks/setDefaultDate';
import {DateNavigatingInterval, DateRangeSelection, DefaultDateSetting} from '~common/types';
import {type FiscalYearRangesType, setAccount} from '../accounts/state';

const d = debug('tacs.web.common.state');

interface DatePayload<T extends number | LocalDate> {
	start: T;
	end: T;
}

interface DateRangeErrorPayload {
	error?: string;
}

export function tweakDateBySelection(start: LocalDate, end: LocalDate, selection: DateRangeSelection, allowFuture: boolean) {
	const endDate = end.isAfter(LocalDate.now()) && !allowFuture ? LocalDate.now() : end;
	const startDate = start.isAfter(LocalDate.now()) && !allowFuture ? LocalDate.now() : start;
	switch (selection) {
		case DateRangeSelection.DateRange:
			return {start: startDate, end: endDate};
		case DateRangeSelection.FullMonth:
			return {start: endDate.withDayOfMonth(1), end: endDate.withDayOfMonth(endDate.lengthOfMonth())};
		case DateRangeSelection.SingleDate:
			return {start: startDate, end: startDate};
		case DateRangeSelection.FullYear:
			return {start: startDate.withDayOfYear(1), end: startDate.withDayOfYear(startDate.lengthOfYear())};
		case DateRangeSelection.None:
			return {start: startDate.withDayOfYear(1), end: endDate};
		default:
			throw new Error('DateRangeSelection is outside allowed values.');
	}
}

export const state = createSlice({
	name: 'common',
	initialState: {
		startDate: LocalDate.now().withDayOfYear(1).toEpochDay(),
		endDate: LocalDate.now().withMonth(12).withDayOfMonth(31).toEpochDay(),
		dateRangeSelection: DateRangeSelection.DateRange,
		dateNavigatingInterval: DateNavigatingInterval.FisYear,
		defaultDateSetting: DefaultDateSetting.CurrentFy,
		allowFuture: false,
		lockRangeInFy: false,
		dateError: null as string | null,
		storedStartDate: LocalDate.now().withDayOfYear(1).toEpochDay(),
		storedEndDate: LocalDate.now().withMonth(12).withDayOfMonth(31).toEpochDay(),
		fyEnd: LocalDate.now().withMonth(12).withDayOfMonth(31).toEpochDay(),
		fyRanges: null as FiscalYearRangesType<number>[] | null,
	},
	reducers: {
		setDateError: (st, action: PayloadAction<DateRangeErrorPayload>) => {
			if (action.payload.error) {
				st.dateError = action.payload.error;
			} else {
				st.dateError = null;
			}
		},
		setDateNavigatingInterval: (st, action: PayloadAction<DateNavigatingInterval>) => {
			st.dateNavigatingInterval = action.payload;
		},
		setDateRangeOption: (st, action: PayloadAction<DateRangeOptions>) => {
			const fromSelection = st.dateRangeSelection;
			const {allowFuture, selection: toSelection, navigatingInterval, lockRangeInFy, defaultDateSetting} = action.payload;
			d(`From ${fromSelection} to ${toSelection}`);
			d(`Actual: ${formatDate(st.startDate)} - ${formatDate(st.endDate)}`);

			if (fromSelection === DateRangeSelection.DateRange) {
				d('Storing');
				st.storedStartDate = st.startDate;
				st.storedEndDate = st.endDate;
			}
			if (navigatingInterval !== undefined && st.dateNavigatingInterval !== navigatingInterval) st.dateNavigatingInterval = navigatingInterval;
			if (lockRangeInFy !== undefined && st.lockRangeInFy !== lockRangeInFy) st.lockRangeInFy = lockRangeInFy;
			st.allowFuture = !!allowFuture;
			st.dateRangeSelection = toSelection;
			if ((defaultDateSetting !== undefined && st.defaultDateSetting !== defaultDateSetting) || fromSelection === DateRangeSelection.None) {
				if (defaultDateSetting !== undefined && st.defaultDateSetting !== defaultDateSetting) st.defaultDateSetting = defaultDateSetting;
				const defaultDates = setDefaultDate(
					st.fyRanges?.map(e => {
						return {
							startDate: toLocalDate(e.startDate),
							endDate: toLocalDate(e.endDate),
						};
					}) || null,
					st.dateRangeSelection,
					st.allowFuture,
					st.defaultDateSetting,
					toLocalDate(st.fyEnd),
				);
				st.startDate = defaultDates.start.toEpochDay();
				st.endDate = defaultDates.end.toEpochDay();
			}

			const {start: tweakedStart, end: tweakedEnd} = tweakDateBySelection(
				LocalDate.ofEpochDay(st.startDate),
				LocalDate.ofEpochDay(st.endDate),
				toSelection,
				!!allowFuture,
			);
			d(`Tweaked: ${formatDate(tweakedStart)} - ${formatDate(tweakedEnd)}`);

			st.startDate = tweakedStart.toEpochDay();
			st.endDate = tweakedEnd.toEpochDay();
			st.allowFuture = !!allowFuture;
			if (toSelection === DateRangeSelection.DateRange) {
				d('Storing');
				st.storedStartDate = st.startDate;
				st.storedEndDate = st.endDate;
			}
		},
		setDate: {
			reducer: (st, action: PayloadAction<DatePayload<number>>) => {
				d(`From: ${formatDate(st.startDate)} to ${formatDate(st.endDate)}`);
				d(`To: ${formatDate(action.payload.start)} to ${formatDate(action.payload.end)}`);

				const {start, end} = tweakDateBySelection(
					LocalDate.ofEpochDay(action.payload.start),
					LocalDate.ofEpochDay(action.payload.end),
					st.dateRangeSelection,
					st.allowFuture,
				);
				d(`Tweaked: ${formatDate(start)} - ${formatDate(end)}`);

				st.startDate = start.toEpochDay();
				st.endDate = end.toEpochDay();
				if (st.dateRangeSelection === DateRangeSelection.DateRange) {
					d('Storing');
					st.storedStartDate = st.startDate;
					st.storedEndDate = st.endDate;
				}
			},
			prepare: (date: DatePayload<LocalDate>) => {
				return {payload: {start: date.start.toEpochDay(), end: date.end.toEpochDay()}};
			},
		},
	},
	extraReducers: builder => {
		builder.addCase(setAccount, (st, {payload}) => {
			if (payload) {
				d('Setting account');
				st.fyEnd = payload.yearEnd;
				st.fyRanges = payload.fiscalYearRanges;
				const defaultDates = setDefaultDate(
					st.fyRanges?.map(e => {
						return {
							startDate: toLocalDate(e.startDate),
							endDate: toLocalDate(e.endDate),
						};
					}) || null,
					st.dateRangeSelection,
					st.allowFuture,
					st.defaultDateSetting,
					toLocalDate(st.fyEnd),
				);
				const {start, end} = tweakDateBySelection(defaultDates.start, defaultDates.end, st.dateRangeSelection, st.allowFuture);
				d(`Tweaked: ${formatDate(start)} - ${formatDate(end)}`);

				st.startDate = start.toEpochDay();
				st.endDate = end.toEpochDay();
				if (st.dateRangeSelection === DateRangeSelection.DateRange) {
					d('Storing');
					st.storedStartDate = st.startDate;
					st.storedEndDate = st.endDate;
				}
			}
		});
	},
});

export const useCommonState = createSliceHook(state, {
	startDate: n => LocalDate.ofEpochDay(n),
	endDate: n => LocalDate.ofEpochDay(n),
	storedStartDate: n => LocalDate.ofEpochDay(n),
	storedEndDate: n => LocalDate.ofEpochDay(n),
	dateRangeSelection: n => n,
	dateNavigatingInterval: n => n,
	lockRangeInFy: n => n,
});

export const {setDateRangeOption, setDate, setDateNavigatingInterval, setDateError} = state.actions;
