import {useCan} from '@imperium/auth-client';
import {createPages} from '@imperium/layout';
import {LocalDate} from '@js-joda/core';
import loadable from '@loadable/component';
import {Permission} from '~core/graphql';
import {isLoggedIn, needsPermission} from '~lib/permissionHelpers';
import {useAccountState} from '../accounts/state';
import {AgentActivitySidebar} from './components/AgentActivitySidebar';
import {AgentHeader} from './components/AgentHeader';
import {AuthorizeOnOrganization} from './components/AuthorizeOnOrganization';
import {DeleteUserButton} from './components/DeleteUserButton';
import {Logout} from './components/Logout';
import {NotificationFeedSidebar} from './components/NotificationFeedSidebar';
import {ReminderFilterDropdown} from './components/ReminderFilterDropdown';
import {UserEventTimelineSidebar} from './components/UserEventTimeline/UserEventTimelineSidebar';
import {UserEventTimelineHeader} from './components/UserEventTimelineHeader';
import {UserHeader} from './components/UserHeader';
import {UserResetPasswordButton} from './components/UserResetPasswordButton';
import {routes} from './routes';
import {useUserState} from './state';

const UserProfile = loadable(() => import('./components/UserProfile'));
const Login = loadable(() => import('./components/login/Login'));
const PasswordReset = loadable(() => import('./components/login/PasswordReset'));
const AccountRegistration = loadable(() => import('./components/login/AccountRegistration'));
const UserEventTimeline = loadable(() => import('./components/UserEventTimeline/UserEventTimeline'));
const AgentActivityTable = loadable(() => import('./components/AgentActivityTable/AgentActivityTable'));
const UserEditForm = loadable(() => import('./components/UserEditForm'));
const NotificationFeed = loadable(() => import('./components/NotificationFeed'));
const AlertSettings = loadable(() => import('./components/AlertSettings'));
const RemindersTable = loadable(() => import('./components/RemindersTable'), {
	resolveComponent: c => c.RemindersTable,
});
const RemindersEdit = loadable(() => import('./components/RemindersEdit'), {
	resolveComponent: c => c.RemindersEdit,
});
const RemindersAdd = loadable(() => import('./components/RemindersAdd'), {
	resolveComponent: c => c.RemindersAdd,
});

export const routeProps = createPages(routes, {
	user: {
		header: data => <UserHeader userId={data.params.userId} title="Profile" />,
		content: () => <UserProfile />,
		sidebar: [
			{
				stateSelectorHook: useAccountState,
				permissionSelectorHook: [
					data => {
						const [writeOwnUser] = useCan([
							{
								permission: Permission.WriteOwnUser,
								data: {userId: data.route.path[1] || null},
							},
						]);
						return {writeOwnUser};
					},
				],
				visible: {...isLoggedIn(), $or: [needsPermission(Permission.WriteOwnUser), needsPermission(Permission.Sysadmin)]},
				to: dat => routes.to.userEdit({userId: dat.params.userId}),
				color: 'green',
				text: 'Edit User Information',
			},
			{
				stateSelectorHook: useAccountState,
				permissionSelectorHook: [
					data => {
						const [writeOwnUser] = useCan([
							{
								permission: Permission.WriteOwnUser,
								data: {userId: data.route.path[1] || null},
							},
						]);
						return {writeOwnUser};
					},
				],
				to: dat => routes.to.userAlertSettings({userId: dat.params.userId}),
				visible: {...isLoggedIn(), $or: [needsPermission(Permission.WriteOwnUser), needsPermission(Permission.Sysadmin)]},
				text: 'Alert Settings',
			},
			{
				stateSelectorHook: useAccountState,
				permissionSelectorHook: [
					data => {
						const [manageAssignedAgents] = useCan([
							{
								permission: Permission.ManageAssignedAgents,
								data: {userId: data.route.path[1] || null},
							},
						]);
						return {manageAssignedAgents};
					},
				],
				visible: {...isLoggedIn(), ...needsPermission(Permission.ManageAssignedAgents)},
				to: dat => routes.to.agentReminders({userId: dat.params.userId}),
				text: 'Reminders',
			},
			{
				visible: {...isLoggedIn(), ...needsPermission(Permission.Sysadmin)},
				to: dat => routes.to.timeline({userId: dat.params.userId}),
				color: 'blue',
				text: 'Timeline',
			},
			{
				stateSelectorHook: [useAccountState, useUserState],
				permissionSelectorHook: [
					data => {
						const [manageAssignedAgents] = useCan({
							permission: Permission.ManageAssignedAgents,
							data: {userId: data.route.path[1] || null},
						});
						return {manageAssignedAgents};
					},
				],
				visible: {...isLoggedIn(), ...needsPermission(Permission.ManageAssignedAgents)},
				color: 'blue',
				text: 'Agent Activity',
				to: dat =>
					routes.to.agentActivity({
						agentId: dat.state.activeAgentId,
						startDate: LocalDate.now().minusWeeks(2).toString(),
						endDate: LocalDate.now().toString(),
					}),
			},
			{
				visible: {...isLoggedIn(), $or: [needsPermission(Permission.Sysadmin)]},
				render: AuthorizeOnOrganization,
			},
			{
				stateSelectorHook: useAccountState,
				permissionSelectorHook: [
					data => {
						const [writeOwnUser] = useCan([
							{
								permission: Permission.WriteOwnUser,
								data: {userId: data.route.path[1] || null},
							},
						]);
						const [manageAssignedAgents] = useCan([
							{
								permission: Permission.ManageAssignedAgents,
								data: {userId: data.route.path[1] || null},
							},
						]);

						return {writeOwnUser, manageAssignedAgents};
					},
				],
				visible: {...isLoggedIn(), $or: [needsPermission(Permission.ManageAssignedAgents), needsPermission(Permission.WriteOwnUser)]},
				render: UserResetPasswordButton,
			},
			{
				visible: {...isLoggedIn(), ...needsPermission(Permission.Sysadmin)},
				render: DeleteUserButton,
			},
		],
	},
	userEdit: {
		header: 'User Profile Edit',
		content: () => <UserEditForm />,
	},
	userNotifications: {
		header: 'My Notifications',
		content: () => <NotificationFeed />,
		sidebar: [{render: NotificationFeedSidebar}],
	},
	userAlertSettings: {
		header: 'Alert Settings',
		content: () => <AlertSettings />,
	},
	agentReminders: {
		header: data => <UserHeader userId={data.params.userId} title="Reminders" />,
		content: () => <RemindersTable />,
		sidebar: [
			{
				render: ReminderFilterDropdown,
			},
			{
				to: dat => routes.to.agentReminderAdd({userId: dat.params.userId}),
				text: 'New Reminder',
			},
		],
	},
	agentReminderEdit: {
		header: 'Edit Reminder',
		content: () => <RemindersEdit />,
	},
	agentActivity: {
		header: data => <AgentHeader agentId={data.params.agentId} title="Agent Activity" />,
		content: () => <AgentActivityTable />,
		sidebar: [{render: AgentActivitySidebar}],
	},
	agentReminderAdd: {
		header: 'Add Reminder',
		content: () => <RemindersAdd />,
	},
	timeline: {
		header: <UserEventTimelineHeader />,
		content: () => <UserEventTimeline />,
		sidebar: [{render: UserEventTimelineSidebar}],
	},
	login: {
		content: () => <Login />,
		full: true,
	},
	passwordReset: {
		content: () => <PasswordReset />,
		full: true,
	},
	accountRegistration: {
		content: () => <AccountRegistration />,
		full: true,
	},
	logout: {
		content: () => <Logout />,
	},
});
