import debug from 'debug';
import {Link} from 'react-router-dom';
import {Card, CardContent, CardDescription, CardHeader, Divider, Icon, List} from 'semantic-ui-react';
import {accountTypeDisplayName} from '~lib/accountTypeDisplayName';
import {routes as accountRoutes} from '../../../routes';
import {useGetDisplayRelatedAccountsListAccountsQuery} from './getDisplayRelatedAccountsListAccounts';

const d = debug('tacs.web.accounts.components.accounts.RelatedAccountsCard');

interface DisplayRelatedAccountsListProps {
	accountInfoId: string;
}

export function RelatedAccountsCard(props: DisplayRelatedAccountsListProps) {
	const {accountInfoId} = props;
	const {data, loading, error} = useGetDisplayRelatedAccountsListAccountsQuery({
		variables: {accountInfoId},
	});

	if (error) throw error;

	if (!data?.getRelatedAccounts || data?.getRelatedAccounts.length === 0) return null;

	return (
		<Card>
			<CardContent>
				<CardHeader>
					<Icon name="list" />
					Related Accounts
				</CardHeader>
				<Divider />
				<CardDescription>
					<List bulleted>
						{data?.getRelatedAccounts?.map(account => {
							return (
								<List.Item key={account.id}>
									<Link style={{color: account.archived ? 'red' : undefined}} to={accountRoutes.to.account({accountInfoId: account.id})}>
										{account.friendlyId.toString().concat(': ', account.name, ' (', accountTypeDisplayName(account.type), ')')}
									</Link>
								</List.Item>
							);
						})}
					</List>
				</CardDescription>
			</CardContent>
		</Card>
	);
}
