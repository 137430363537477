import * as Apollo from '@apollo/client';

import Operations from '~common/components/OrganizationDropdown/getOrganizationDropdown.graphql';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

const defaultOptions = {} as const;
export type GetOrganizationDropdownQueryVariables = Types.Exact<{[key: string]: never}>;

export type GetOrganizationDropdownQuery = {getOrganizations?: Array<{id: string; version: number; name: string}> | null};

export type GetOrganizationDropdownType = {id: string; version: number; name: string};

/**
 * __useGetOrganizationDropdownQuery__
 *
 * To run a query within a React component, call `useGetOrganizationDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationDropdownQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationDropdownQuery(
	baseOptions?: Apollo.QueryHookOptions<GetOrganizationDropdownQuery, GetOrganizationDropdownQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetOrganizationDropdownQuery, GetOrganizationDropdownQueryVariables>(Operations, options);
}
export function useGetOrganizationDropdownLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationDropdownQuery, GetOrganizationDropdownQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetOrganizationDropdownQuery, GetOrganizationDropdownQueryVariables>(Operations, options);
}
export type GetOrganizationDropdownQueryHookResult = ReturnType<typeof useGetOrganizationDropdownQuery>;
export type GetOrganizationDropdownLazyQueryHookResult = ReturnType<typeof useGetOrganizationDropdownLazyQuery>;
export type GetOrganizationDropdownQueryResult = Apollo.QueryResult<GetOrganizationDropdownQuery, GetOrganizationDropdownQueryVariables>;
