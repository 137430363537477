import {TForm} from '@thx/controls';
import debug from 'debug';
import type {FormikHelpers} from 'formik/dist/types';
import {Button, Divider, Dropdown, Feed, Form, FormField, FormTextArea, Icon, Message, SemanticCOLORS, SemanticSIZES} from 'semantic-ui-react';
import {boolean, object, string} from 'yup';
import type {CommunicationTypeEnum} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import type {GetCommunicationFeedItemsType} from '../CommunicationFeed/getCommunicationFeedItems';
import {icon} from '../communicationDisplay';
import {useAddCommunicationFeedItemMutation} from './addCommunicationFeedItem';
import {useDeleteCommunicationFeedItemMutation} from './deleteCommunicationFeedItem';
import {useUpdateCommunicationFeedItemMutation} from './updateCommunicationFeedItem';

const d = debug('tacs.web.communications.components.CommunicationFeedItemForm');

const communicationFeedItemFormValidation = object().shape({
	text: string().required('Please add a note.'),
	color: string().notRequired(),
	pinned: boolean().required(),
});

interface CommunicationFeedItemFormType {
	text: string;
	color: SemanticCOLORS;
	pinned: boolean;
}

type BasicArguments = {
	type: CommunicationTypeEnum;
	contents?: string;
	label?: string;
	size?: SemanticSIZES;
	isEditForm?: () => void;
};

type AddArguments = {
	accountInfoId?: string;
	leadId?: string;
	entry?: GetCommunicationFeedItemsType;
};

type UpdateArguments = {
	accountInfoId?: string;
	entry: GetCommunicationFeedItemsType;
	leadId?: string;
};

type CommunicationFeedItemFormProps = (AddArguments & BasicArguments) | (UpdateArguments & BasicArguments);

export function CommunicationFeedItemForm(props: CommunicationFeedItemFormProps): JSX.Element {
	const throwError = useAsyncError();
	const {leadId, entry, type, contents, label, size, isEditForm, accountInfoId} = props;

	// mutations
	const [addCommunicationFeedItemMutation, {called: calledAdd, loading: mutationLoading}] = useAddCommunicationFeedItemMutation();
	const [updateCommunicationFeedItemMutation, {called: calledUpdate}] = useUpdateCommunicationFeedItemMutation();
	const [deleteCommunicationFeedItemMutation, {called: calledDelete}] = useDeleteCommunicationFeedItemMutation();

	// add item
	const addCommunicationFeedItemFun = (v: CommunicationFeedItemFormType, form: FormikHelpers<CommunicationFeedItemFormType>) => {
		addCommunicationFeedItemMutation({
			variables: {
				data: {
					type,
					accountInfoId,
					leadId,
					contents: v.text,
					color: v.color,
					pinned: v.pinned,
				},
			},
			refetchQueries: ['getCommunicationFeedItems'],
		})
			.then(() => {
				form.resetForm();
				if (isEditForm) isEditForm();
			})
			.catch(throwError);
	};

	// update item
	const updateCommunicationFeedItemFun = (v: CommunicationFeedItemFormType) => {
		if (!entry?.id) return;
		updateCommunicationFeedItemMutation({
			variables: {
				id: entry.id,
				contents: v.text,
				color: v.color,
				pinned: v.pinned,
				version: entry.version || 0,
			},
			refetchQueries: ['getCommunicationFeedItems'],
		})
			.then(() => {
				if (isEditForm) isEditForm();
			})
			.catch(throwError);
	};

	// remove
	const removeCommunicationFeedItemFun = () => {
		if (!entry?.id) return;
		deleteCommunicationFeedItemMutation({
			variables: {
				id: entry.id,
				version: entry.version || 0,
			},
			refetchQueries: ['getCommunicationFeedItems'],
		})
			.then(() => {
				if (isEditForm) isEditForm();
			})
			.catch(throwError);
	};

	const onTFormSubmit = (v: CommunicationFeedItemFormType, form: FormikHelpers<CommunicationFeedItemFormType>) => {
		if (entry) updateCommunicationFeedItemFun(v);
		else addCommunicationFeedItemFun(v, form);
	};

	// components
	return (
		<TForm
			initialValues={{
				text: contents || '',
				color: entry?.color || 'grey',
				pinned: entry?.pinned || false,
				data: {},
			}}
			onSubmit={onTFormSubmit}
			validationSchema={communicationFeedItemFormValidation}
		>
			{({handleSubmit, values, handleChange, setFieldValue, hasWarnings, hasErrors, fieldError, renderWarnings}) => {
				return (
					<Feed.Event size={size || 'large'}>
						<Feed.Label>
							<Icon color={values.color as SemanticCOLORS} name={icon(entry?.type)} />
						</Feed.Label>
						<Feed.Content>
							<Message color={values.color || 'grey'}>
								<Form onSubmit={handleSubmit} error={hasErrors} warning={hasWarnings}>
									<FormField>
										<label>
											{label || 'Text'}
											<br />
											<FormTextArea
												name="text"
												onChange={handleChange}
												rows="5"
												value={values.text}
												error={fieldError('text')}
												placeholder="Add a note about this client..."
												autoFocus
											/>
										</label>
									</FormField>
									{hasWarnings && renderWarnings()}
									<Dropdown
										id="color"
										onChange={(c, val) => setFieldValue(val.id, val.value)}
										size={size}
										value={values.color}
										selection={size !== 'tiny'}
										placeholder="Select color"
										options={[
											{key: 'grey', value: 'grey', text: 'Grey', label: {color: 'grey', empty: true, circular: true}},
											{key: 'red', value: 'red', text: 'Red', label: {color: 'red', empty: true, circular: true}},
											{key: 'orange', value: 'orange', text: 'Orange', label: {color: 'orange', empty: true, circular: true}},
											{key: 'yellow', value: 'yellow', text: 'Yellow', label: {color: 'yellow', empty: true, circular: true}},
											{key: 'olive', value: 'olive', text: 'Olive', label: {color: 'olive', empty: true, circular: true}},
											{key: 'green', value: 'green', text: 'Green', label: {color: 'green', empty: true, circular: true}},
											{key: 'teal', value: 'teal', text: 'Teal', label: {color: 'teal', empty: true, circular: true}},
											{key: 'blue', value: 'blue', text: 'Blue', label: {color: 'blue', empty: true, circular: true}},
											{key: 'violet', value: 'violet', text: 'Violet', label: {color: 'violet', empty: true, circular: true}},
											{key: 'purple', value: 'purple', text: 'Purple', label: {color: 'purple', empty: true, circular: true}},
											{key: 'pink', value: 'pink', text: 'Pink', label: {color: 'pink', empty: true, circular: true}},
											{key: 'brown', value: 'brown', text: 'Brown', label: {color: 'brown', empty: true, circular: true}},
										]}
									/>
									<FormField>
										<Divider />
										{isEditForm && (
											<Button
												disabled={calledDelete}
												size={size}
												type="button"
												negative
												onClick={() => {
													removeCommunicationFeedItemFun();
												}}
											>
												Delete
											</Button>
										)}
										<Button disabled={(calledAdd && mutationLoading) || calledUpdate} floated="right" type="submit" size={size} positive>
											Save
										</Button>
										{isEditForm && (
											<Button floated="right" onClick={isEditForm} size={size} type="button">
												Cancel
											</Button>
										)}
										<br />
										<br />
									</FormField>
								</Form>
							</Message>
						</Feed.Content>
						{entry?.id && (
							<Feed.Meta style={{float: 'right', paddingLeft: '10px'}}>
								<Icon
									color={values.color as SemanticCOLORS}
									name="pin"
									size="large"
									style={{cursor: 'pointer'}}
									rotated={!values.pinned ? 'clockwise' : undefined}
								/>
							</Feed.Meta>
						)}
					</Feed.Event>
				);
			}}
		</TForm>
	);
}
