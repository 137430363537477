import {useState} from 'react';
import {Button, Grid, GridColumn, GridRow, Icon, type IconProps, Modal} from 'semantic-ui-react';

interface ConfirmModalProps {
	onConfirm?: () => void;
	headerIcon?: IconProps | null;
	headerText: string; // Should be a short phrase like "Confirm Action" -STT
	text: string | JSX.Element; // Long phrase like "Are you sure you want to perform this action?" -STT
	trigger: JSX.Element;
}

export function ConfirmModal(props: ConfirmModalProps) {
	const [open, setOpen] = useState(false);
	const {trigger, onConfirm, headerText, headerIcon, text} = props;
	return (
		<Modal open={open} size="small" dimmer trigger={trigger} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
			<Modal.Content>
				<Grid>
					<GridRow>
						<GridColumn>
							<h3>
								<Icon color="green" name="checkmark" {...headerIcon} />
								{headerText}
							</h3>
						</GridColumn>
					</GridRow>
					<GridRow>
						<GridColumn>{text}</GridColumn>
					</GridRow>
					<GridRow>
						<GridColumn textAlign="right">
							{onConfirm && (
								<Button
									color="green"
									onClick={() => {
										setOpen(false);
										onConfirm();
									}}
								>
									<Icon name="checkmark" /> Confirm
								</Button>
							)}
							<Button
								color={onConfirm ? 'orange' : 'grey'}
								floated="right"
								onClick={() => {
									setOpen(false);
								}}
							>
								<Icon name="dont" /> Cancel
							</Button>
						</GridColumn>
					</GridRow>
				</Grid>
			</Modal.Content>
		</Modal>
	);
}
