import {LocalDate} from '@js-joda/core';
import {getFiscalYearRange} from '@thx/date';
import {getFyRangeForDate} from '~common/components/DateRangeSelector/DateRangeSelector';
import {tweakDateBySelection} from '~common/state';
import {type DateRangeSelection, DefaultDateSetting} from '~common/types';
import type {FiscalYearRangesType} from '../../accounts/state';

export function setDefaultDate(
	fiscalYearRanges: FiscalYearRangesType<LocalDate>[] | null,
	dateRangeSelection: DateRangeSelection,
	allowFuture: boolean,
	defaultDateSetting: DefaultDateSetting,
	yearEnd: LocalDate,
) {
	const getCurrentFy = (fiscalYearEnd: LocalDate) => {
		if (fiscalYearRanges && fiscalYearRanges.length > 0) {
			const lastFyRange = fiscalYearRanges[fiscalYearRanges.length - 1];
			return {
				start: lastFyRange.endDate.plusDays(1),
				end: lastFyRange.endDate.isBefore(fiscalYearEnd.withYear(lastFyRange.endDate.year()))
					? fiscalYearEnd.withYear(lastFyRange.endDate.year())
					: fiscalYearEnd.withYear(lastFyRange.endDate.year()).plusYears(1),
			};
		}
		return getFiscalYearRange(LocalDate.now(), fiscalYearEnd);
	};

	let startDate;
	let endDate;
	switch (defaultDateSetting) {
		case DefaultDateSetting.TodayFy: {
			const fy = getFyRangeForDate(LocalDate.now(), fiscalYearRanges, yearEnd);
			const {start: tStart, end: tEnd} = tweakDateBySelection(fy.startDate, fy.endDate, dateRangeSelection, allowFuture);
			startDate = tStart;
			endDate = tEnd;
			break;
		}
		case DefaultDateSetting.CurrentCalYear: {
			startDate = LocalDate.now().withDayOfYear(1);
			endDate = LocalDate.now().withMonth(12).withDayOfMonth(31);
			break;
		}
		case DefaultDateSetting.BalanceSheetDefault: {
			const currentFy = getCurrentFy(yearEnd);
			if (!LocalDate.now().isAfter(currentFy.end)) {
				startDate = LocalDate.now();
				endDate = LocalDate.now();
			} else {
				startDate = currentFy.end;
				endDate = currentFy.end;
			}
			break;
		}
		case DefaultDateSetting.LastQuarter: {
			startDate = LocalDate.now().minusMonths(3).withDayOfMonth(1);
			endDate = LocalDate.now().withDayOfMonth(LocalDate.now().lengthOfMonth());
			break;
		}
		case DefaultDateSetting.CurrentMonth: {
			startDate = LocalDate.now().withDayOfMonth(1);
			endDate = LocalDate.now().withDayOfMonth(LocalDate.now().lengthOfMonth());
			break;
		}
		default: {
			const {start, end} = getCurrentFy(yearEnd);
			const {start: tStart, end: tEnd} = tweakDateBySelection(start, end, dateRangeSelection, allowFuture);
			startDate = tStart;
			endDate = tEnd;
			break;
		}
	}
	return {start: startDate, end: endDate};
}
