import {LocalDate} from '@js-joda/core';
import {LocalDatePicker} from '@thx/controls';
import {toDate} from '@thx/date';
import debug from 'debug';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Dropdown} from 'semantic-ui-react';
import {getFyRangeForDate} from '~common/components/DateRangeSelector/DateRangeSelector';
import styles from '~common/components/DateRangeSelector/tabs/style.module.css';
import {setDate, setDateNavigatingInterval, useCommonState} from '~common/state';
import {DateNavigatingInterval, DateRangeSelection} from '~common/types';
import {useAccountState} from '../../../../accounts/state';

const d = debug('tacs.web.common.components.DateRangeSelector.tabs.CustomTab');

export function CustomTab({hidePanel}: {hidePanel: () => void}) {
	const dispatch = useDispatch();
	const [valChanged, setValChanged] = useState(false);
	const {startDate, endDate, dateRangeSelection, dateNavigatingInterval, lockRangeInFy, allowFuture} = useCommonState();
	const {fiscalYearRanges, yearEnd} = useAccountState();
	const fiscalYearEnd = yearEnd || LocalDate.now().withMonth(12).withDayOfMonth(31);
	const [fyLockedDates, setFyLockedDates] = useState<{startDate: LocalDate | undefined; endDate: LocalDate | undefined} | undefined>(
		lockRangeInFy ? getFyRangeForDate(startDate, fiscalYearRanges, fiscalYearEnd) : undefined,
	);

	function matchNavInterval(eDate: LocalDate) {
		let suggestedInt: DateNavigatingInterval | undefined;
		switch (true) {
			case startDate.equals(eDate):
				suggestedInt = DateNavigatingInterval.Day;
				break;
			case startDate.plusMonths(1).minusDays(1).equals(eDate):
				suggestedInt = DateNavigatingInterval.Month;
				break;
			case startDate.plusMonths(3).minusDays(1).equals(eDate):
				suggestedInt = DateNavigatingInterval.Quarter;
				break;
			case fyLockedDates && startDate.plusDays(1).plusYears(1).minusDays(2).equals(eDate):
				suggestedInt = DateNavigatingInterval.FisYear;
				break;
			case !fyLockedDates && startDate.plusDays(1).plusYears(1).minusDays(2).equals(eDate):
				suggestedInt = DateNavigatingInterval.CalYear;
				break;
			default:
				suggestedInt = undefined;
		}
		return suggestedInt;
	}

	const endPart =
		dateRangeSelection === DateRangeSelection.DateRange ? (
			<div style={{marginTop: '1rem'}}>
				<div>End</div>
				<LocalDatePicker
					highlightDates={
						fyLockedDates?.endDate && fyLockedDates.startDate
							? (() => {
									const dates: Date[] = [];
									let currentDate = fyLockedDates.startDate;
									while (!currentDate?.isAfter(fyLockedDates.endDate)) {
										dates.push(toDate(currentDate));
										currentDate = currentDate.plusDays(1);
									}
									return dates;
							  })()
							: undefined
					}
					value={endDate}
					maxDate={allowFuture ? undefined : LocalDate.now()}
					minDate={startDate}
					onChange={date => {
						if (date) {
							if (!date.isEqual(endDate) && !valChanged) setValChanged(true);
							const suggestedInterval = matchNavInterval(date);
							const rangeEndsToday = date.isEqual(LocalDate.now());
							if (lockRangeInFy) {
								const fyRange = getFyRangeForDate(date, fiscalYearRanges, fiscalYearEnd);
								setFyLockedDates(fyRange);
								if (startDate.isBefore(fyRange.startDate) || startDate.isAfter(fyRange.endDate))
									dispatch(setDate({start: fyRange.startDate, end: date}));
								else dispatch(setDate({start: startDate, end: date}));
							} else dispatch(setDate({start: startDate, end: date}));
							if ((!date.isEqual(endDate) || valChanged) && suggestedInterval !== undefined) {
								hidePanel();
							}
							if (suggestedInterval !== undefined && !rangeEndsToday && dateNavigatingInterval !== suggestedInterval)
								dispatch(setDateNavigatingInterval(suggestedInterval));
						}
					}}
				/>
			</div>
		) : null;

	const startPart = (
		<div>
			<div>{endPart ? 'Start' : 'Date'}</div>
			<LocalDatePicker
				highlightDates={
					fyLockedDates?.endDate && fyLockedDates.startDate
						? (() => {
								const dates: Date[] = [];
								let currentDate = fyLockedDates.startDate;
								while (!currentDate?.isAfter(fyLockedDates.endDate)) {
									dates.push(toDate(currentDate));
									currentDate = currentDate.plusDays(1);
								}
								return dates;
						  })()
						: undefined
				}
				value={startDate}
				startSelected
				maxDate={LocalDate.now()}
				onChange={date => {
					if (date && !date.equals(startDate)) {
						setValChanged(true);
						if (lockRangeInFy) {
							const fyRange = getFyRangeForDate(date, fiscalYearRanges, fiscalYearEnd);
							setFyLockedDates(fyRange);
							if (endDate.isAfter(fyRange.endDate) || endDate.isBefore(fyRange.startDate)) dispatch(setDate({start: date, end: fyRange.endDate}));
							else dispatch(setDate({start: date, end: endDate}));
						} else {
							dispatch(setDate({start: date, end: endDate}));
						}
						if (!endPart) hidePanel();
					}
				}}
			/>
		</div>
	);

	const options = [
		{key: DateNavigatingInterval.Day, text: 'Day', value: DateNavigatingInterval.Day},
		{key: DateNavigatingInterval.Month, text: 'Month', value: DateNavigatingInterval.Month},
		...(!lockRangeInFy ? [{key: DateNavigatingInterval.CalYear, text: 'Calendar Year', value: DateNavigatingInterval.CalYear}] : []),
		{key: DateNavigatingInterval.Quarter, text: 'Quarter', value: DateNavigatingInterval.Quarter},
		{key: DateNavigatingInterval.FisYear, text: 'Fiscal Year', value: DateNavigatingInterval.FisYear},
	];

	return (
		<div className={styles.tab}>
			{startPart}
			{endPart}
			{startPart && (
				<div style={{paddingTop: 10}}>
					Navigate by{' '}
					<Dropdown
						openOnFocus={!valChanged || (!!endPart && matchNavInterval(endDate) === undefined)}
						options={options}
						value={dateNavigatingInterval}
						inline
						onClose={() => {
							if (valChanged) {
								hidePanel();
							}
						}}
						onChange={(e, v) => {
							dispatch(setDateNavigatingInterval(v.value as number));
						}}
					/>
				</div>
			)}
		</div>
	);
}
