/* eslint-disable */
import type {LocalDate, LocalTime} from '@js-joda/core';
import type Money from 'js-money';

import {localDateTypePolicy, moneyTypePolicy, dateTimeTypePolicy, localTimeTypePolicy} from '~core/scalarPolicies';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type NotMaybe<T> = T extends null | undefined ? never : T;
export type UnArray<T> = T extends Array<infer U> ? U : T;
export type ExtractArray<A> = NonNullable<UnArray<NonNullable<A>>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: Date;
  LocalDate: LocalDate;
  LocalTime: LocalTime;
  Money: Money;
};

export type AcFiscalYear = {
  __typename?: 'AcFiscalYear';
  accountInfo: AccountInfo;
  closeDate: Scalars['LocalDate'];
  endDate: Scalars['LocalDate'];
  id: Scalars['String'];
  isClosed: Scalars['Boolean'];
  startDate: Scalars['LocalDate'];
  version: Scalars['Int'];
};

export type AcFiscalYearRange = {
  __typename?: 'AcFiscalYearRange';
  endDate: Scalars['LocalDate'];
  startDate: Scalars['LocalDate'];
};

export type AcPayable = {
  __typename?: 'AcPayable';
  accountInfo: AccountInfo;
  amountOwing: Scalars['Money'];
  chequeNumber?: Maybe<Scalars['String']>;
  date: Scalars['LocalDate'];
  documentSource?: Maybe<Document>;
  id: Scalars['String'];
  invoiceNumber: Scalars['String'];
  isHistorical: Scalars['Boolean'];
  journalEntry?: Maybe<JournalEntry>;
  payableLines?: Maybe<Array<AcPayableLine>>;
  paymentGlAccount?: Maybe<GeneralLedgerAccount>;
  paymentMethod: AcPaymentMethodEnum;
  vendor: Vendor;
  version: Scalars['Int'];
};

export type AcPayableFilterInput = {
  endDate?: InputMaybe<Scalars['LocalDate']>;
  fromAmount?: InputMaybe<Scalars['Money']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  paymentGlAccount?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<AcPaymentMethodEnum>;
  showPaid?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
  toAmount?: InputMaybe<Scalars['Money']>;
  vendor?: InputMaybe<Scalars['String']>;
};

export type AcPayableLine = {
  __typename?: 'AcPayableLine';
  description?: Maybe<Scalars['String']>;
  glAccount: GeneralLedgerAccount;
  gst: Scalars['Money'];
  id: Scalars['String'];
  lineNumber: Scalars['Int'];
  payable: AcPayable;
  subTotal: Scalars['Money'];
  total: Scalars['Money'];
  version: Scalars['Int'];
};

export type AcPayableLineInput = {
  description?: InputMaybe<Scalars['String']>;
  glAccount: Scalars['String'];
  gst: Scalars['Money'];
  lineNumber?: InputMaybe<Scalars['Int']>;
  subTotal: Scalars['Money'];
};

export type AcPayableTemplate = {
  __typename?: 'AcPayableTemplate';
  accountInfo: AccountInfo;
  currentQuantityCount?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  endDate?: Maybe<Scalars['LocalDate']>;
  id: Scalars['String'];
  lastScheduledDate?: Maybe<Scalars['LocalDate']>;
  nextDueEndOfTheMonth?: Maybe<Scalars['Boolean']>;
  nextScheduledDate: Scalars['LocalDate'];
  payableTemplateLines?: Maybe<Array<AcPayableTemplateLine>>;
  paymentGlAccount?: Maybe<GeneralLedgerAccount>;
  paymentMethod: AcPaymentMethodEnum;
  quantity?: Maybe<Scalars['Int']>;
  recurrencePattern: RecurrencePatternEnum;
  vendor: Vendor;
  version: Scalars['Int'];
};

export type AcPayableTemplateFilterInput = {
  description?: InputMaybe<Scalars['String']>;
  fromAmount?: InputMaybe<Scalars['Money']>;
  paymentGlAccount?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<AcPaymentMethodEnum>;
  toAmount?: InputMaybe<Scalars['Money']>;
  vendor?: InputMaybe<Scalars['String']>;
};

export type AcPayableTemplateLine = {
  __typename?: 'AcPayableTemplateLine';
  description?: Maybe<Scalars['String']>;
  glAccount: GeneralLedgerAccount;
  gst?: Maybe<Scalars['Money']>;
  id: Scalars['String'];
  lineNumber: Scalars['Int'];
  payableTemplate: AcPayableTemplate;
  subTotal?: Maybe<Scalars['Money']>;
  version: Scalars['Int'];
};

export type AcPayableTemplateLineInput = {
  description?: InputMaybe<Scalars['String']>;
  glAccount: Scalars['String'];
  gst?: InputMaybe<Scalars['Money']>;
  lineNumber?: InputMaybe<Scalars['Int']>;
  subTotal?: InputMaybe<Scalars['Money']>;
};

export type AcPayment = {
  __typename?: 'AcPayment';
  accountInfo: AccountInfo;
  chequeNumber?: Maybe<Scalars['String']>;
  date: Scalars['LocalDate'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isHistorical: Scalars['Boolean'];
  journalEntry?: Maybe<JournalEntry>;
  paymentGlAccount?: Maybe<GeneralLedgerAccount>;
  paymentLines?: Maybe<Array<AcPaymentLine>>;
  paymentMethod: AcPaymentMethodEnum;
  total: Scalars['Money'];
  vendor: Vendor;
  version: Scalars['Int'];
};

export type AcPaymentFilterInput = {
  endDate?: InputMaybe<Scalars['LocalDate']>;
  fromAmount?: InputMaybe<Scalars['Money']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  paymentGlAccount?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<AcPaymentMethodEnum>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
  toAmount?: InputMaybe<Scalars['Money']>;
  vendor?: InputMaybe<Scalars['String']>;
};

export type AcPaymentLine = {
  __typename?: 'AcPaymentLine';
  amount: Scalars['Money'];
  id: Scalars['String'];
  lineNumber: Scalars['Int'];
  payable: AcPayable;
  payment: AcPayment;
  version: Scalars['Int'];
};

export type AcPaymentLineInput = {
  amount: Scalars['Money'];
  amountOwing?: InputMaybe<Scalars['Money']>;
  lineNumber?: InputMaybe<Scalars['Int']>;
  payable: Scalars['String'];
};

export enum AcPaymentMethodEnum {
  Cash = 'Cash',
  Cheque = 'Cheque',
  PayLater = 'PayLater'
}

export type AcReceipt = {
  __typename?: 'AcReceipt';
  accountInfo: AccountInfo;
  chequeNumber?: Maybe<Scalars['String']>;
  date: Scalars['LocalDate'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isHistorical: Scalars['Boolean'];
  journalEntry?: Maybe<JournalEntry>;
  paymentGlAccount?: Maybe<GeneralLedgerAccount>;
  paymentMethod: AcPaymentMethodEnum;
  receiptLines?: Maybe<Array<AcReceiptLine>>;
  total: Scalars['Money'];
  vendor: Vendor;
  version: Scalars['Int'];
};

export type AcReceiptFilterInput = {
  endDate?: InputMaybe<Scalars['LocalDate']>;
  fromAmount?: InputMaybe<Scalars['Money']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  paymentGlAccount?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<AcPaymentMethodEnum>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
  toAmount?: InputMaybe<Scalars['Money']>;
  vendor?: InputMaybe<Scalars['String']>;
};

export type AcReceiptLine = {
  __typename?: 'AcReceiptLine';
  amount: Scalars['Money'];
  id: Scalars['String'];
  lineNumber: Scalars['Int'];
  receipt: AcReceipt;
  receivable: AcReceivable;
  version: Scalars['Int'];
};

export type AcReceiptLineInput = {
  amount: Scalars['Money'];
  amountOwing?: InputMaybe<Scalars['Money']>;
  lineNumber?: InputMaybe<Scalars['Int']>;
  receivable: Scalars['String'];
};

export type AcReceivable = {
  __typename?: 'AcReceivable';
  accountInfo: AccountInfo;
  amountOwing: Scalars['Money'];
  chequeNumber?: Maybe<Scalars['String']>;
  date: Scalars['LocalDate'];
  documentSource?: Maybe<Document>;
  id: Scalars['String'];
  invoiceNumber: Scalars['String'];
  isHistorical: Scalars['Boolean'];
  journalEntry?: Maybe<JournalEntry>;
  paymentGlAccount?: Maybe<GeneralLedgerAccount>;
  paymentMethod: AcPaymentMethodEnum;
  receivableLines?: Maybe<Array<AcReceivableLine>>;
  vendor: Vendor;
  version: Scalars['Int'];
};

export type AcReceivableFilterInput = {
  endDate?: InputMaybe<Scalars['LocalDate']>;
  fromAmount?: InputMaybe<Scalars['Money']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  paymentGlAccount?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<AcPaymentMethodEnum>;
  showPaid?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
  toAmount?: InputMaybe<Scalars['Money']>;
  vendor?: InputMaybe<Scalars['String']>;
};

export type AcReceivableLine = {
  __typename?: 'AcReceivableLine';
  description?: Maybe<Scalars['String']>;
  glAccount: GeneralLedgerAccount;
  id: Scalars['String'];
  lineNumber: Scalars['Int'];
  receivable: AcReceivable;
  subTotal: Scalars['Money'];
  total: Scalars['Money'];
  version: Scalars['Int'];
};

export type AcReceivableLineInput = {
  description?: InputMaybe<Scalars['String']>;
  glAccount: Scalars['String'];
  lineNumber?: InputMaybe<Scalars['Int']>;
  subTotal: Scalars['Money'];
};

export type AcReceivableTemplate = {
  __typename?: 'AcReceivableTemplate';
  accountInfo: AccountInfo;
  currentQuantityCount?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  endDate?: Maybe<Scalars['LocalDate']>;
  id: Scalars['String'];
  lastScheduledDate?: Maybe<Scalars['LocalDate']>;
  nextDueEndOfTheMonth?: Maybe<Scalars['Boolean']>;
  nextScheduledDate: Scalars['LocalDate'];
  paymentGlAccount?: Maybe<GeneralLedgerAccount>;
  paymentMethod: AcPaymentMethodEnum;
  quantity?: Maybe<Scalars['Int']>;
  receivableTemplateLines?: Maybe<Array<AcReceivableTemplateLine>>;
  recurrencePattern: RecurrencePatternEnum;
  vendor: Vendor;
  version: Scalars['Int'];
};

export type AcReceivableTemplateFilterInput = {
  description?: InputMaybe<Scalars['String']>;
  fromAmount?: InputMaybe<Scalars['Money']>;
  paymentGlAccount?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<AcPaymentMethodEnum>;
  toAmount?: InputMaybe<Scalars['Money']>;
  vendor?: InputMaybe<Scalars['String']>;
};

export type AcReceivableTemplateLine = {
  __typename?: 'AcReceivableTemplateLine';
  description?: Maybe<Scalars['String']>;
  glAccount: GeneralLedgerAccount;
  id: Scalars['String'];
  lineNumber: Scalars['Int'];
  receivableTemplate: AcReceivableTemplate;
  subTotal?: Maybe<Scalars['Money']>;
  version: Scalars['Int'];
};

export type AcReceivableTemplateLineInput = {
  description?: InputMaybe<Scalars['String']>;
  glAccount: Scalars['String'];
  lineNumber?: InputMaybe<Scalars['Int']>;
  subTotal?: InputMaybe<Scalars['Money']>;
};

export type AccommodationAmount = {
  __typename?: 'AccommodationAmount';
  amount: Scalars['Money'];
  effectiveDate: Scalars['LocalDate'];
  id: Scalars['String'];
  version: Scalars['Int'];
};

export type Account = {
  __typename?: 'Account';
  account?: Maybe<SpecificAccount>;
  accountInfo?: Maybe<AccountInfo>;
};

export type AccountDetailsInput = {
  authorizedRepresentatives?: InputMaybe<Array<CreateContactInput>>;
  gstFilingFrequency?: InputMaybe<GstFilingFrequencyEnum>;
  type: AccountTypeEnum;
  yearEnd?: InputMaybe<Scalars['LocalDate']>;
};

export enum AccountEventFeatureEnum {
  Accounting = 'accounting',
  Accounts = 'accounts',
  Administration = 'administration',
  Assets = 'assets',
  Calendar = 'calendar',
  Communications = 'communications',
  CorporateTaxes = 'corporateTaxes',
  Documents = 'documents',
  Gst = 'gst',
  Notifications = 'notifications',
  Payroll = 'payroll',
  PersonalTaxes = 'personalTaxes',
  Rates = 'rates',
  Receivables = 'receivables',
  Search = 'search',
  Support = 'support',
  System = 'system',
  T5 = 't5',
  Tasks = 'tasks',
  Users = 'users'
}

export type AccountInfo = {
  __typename?: 'AccountInfo';
  address?: Maybe<Address>;
  archived: Scalars['Boolean'];
  authorizedRepresentatives?: Maybe<Array<AuthorizedRepresentative>>;
  contacts?: Maybe<Array<Contact>>;
  defaultAgent?: Maybe<Agent>;
  employees?: Maybe<Array<Employee>>;
  friendlyId: Scalars['Int'];
  id: Scalars['String'];
  managed: Scalars['Boolean'];
  manager?: Maybe<Agent>;
  name: Scalars['String'];
  office?: Maybe<Office>;
  organization?: Maybe<Organization>;
  type: AccountTypeEnum;
  version: Scalars['Int'];
};

export type AccountInfoWithFees = {
  __typename?: 'AccountInfoWithFees';
  accountRating?: Maybe<AccountRating>;
  accountWarning?: Maybe<Scalars['String']>;
  contractsInfos: ContractsInfos;
  fiscalYear?: Maybe<Scalars['LocalDate']>;
  friendlyId: Scalars['Int'];
  hasUser: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  usesApp: Scalars['Boolean'];
};

export type AccountNotice = {
  __typename?: 'AccountNotice';
  accountInfo: AccountInfo;
  count: Scalars['Int'];
  dateTimeRead?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  message: Scalars['String'];
  type: AccountNoticeTypeEnum;
  version: Scalars['Int'];
};

export enum AccountNoticeTypeEnum {
  Account = 'Account',
  SortedDocument = 'SortedDocument',
  UnsortedDocument = 'UnsortedDocument'
}

export type AccountRating = {
  __typename?: 'AccountRating';
  description: Scalars['String'];
  details: Scalars['String'];
  id: Scalars['String'];
  letter: Scalars['String'];
  version: Scalars['Int'];
};

export type AccountStorage = {
  __typename?: 'AccountStorage';
  bytesUsed?: Maybe<Scalars['Float']>;
  fileCount: Scalars['Float'];
};

export type AccountType = {
  __typename?: 'AccountType';
  active: Scalars['Int'];
  total: Scalars['Int'];
};

export enum AccountTypeEnum {
  BasicAccount = 'BasicAccount',
  CorporateAccount = 'CorporateAccount',
  PersonalAccount = 'PersonalAccount',
  SelfEmployedAccount = 'SelfEmployedAccount'
}

export type AccountingImportApTransaction = {
  __typename?: 'AccountingImportApTransaction';
  amount: Scalars['Money'];
  chequeId: Scalars['Int'];
  date: Scalars['LocalDate'];
  glAccount: GeneralLedgerAccount;
  glCode: Scalars['Int'];
  group: Scalars['Int'];
  source: Scalars['String'];
  srcDate: Scalars['LocalDate'];
  srcSource: Scalars['String'];
  vendor: Vendor;
};

export type AccountingImportCheckResult = {
  __typename?: 'AccountingImportCheckResult';
  error?: Maybe<Scalars['String']>;
  numGeneral: Scalars['Int'];
  numPayables: Scalars['Int'];
  numPayments: Scalars['Int'];
  numReceipts: Scalars['Int'];
  numReceivables: Scalars['Int'];
  prevYearPayments: Array<AccountingImportPreviousYearTransaction>;
  prevYearReceivables: Array<AccountingImportPreviousYearTransaction>;
};

export type AccountingImportDestGl = {
  __typename?: 'AccountingImportDestGl';
  code: Scalars['Int'];
  id: Scalars['String'];
  mappedAccounts?: Maybe<Array<AccountingImportGl>>;
  name: Scalars['String'];
  type: GeneralLedgerAccountTypeEnum;
};

export type AccountingImportDestGlInput = {
  code: Scalars['Int'];
  id: Scalars['String'];
  mappedAccounts?: InputMaybe<Array<AccountingImportGlInput>>;
  name: Scalars['String'];
  type: GeneralLedgerAccountTypeEnum;
};

export type AccountingImportGl = {
  __typename?: 'AccountingImportGl';
  code: Scalars['Int'];
  id: Scalars['String'];
  name: Scalars['String'];
  type: GeneralLedgerAccountTypeEnum;
};

export type AccountingImportGlInput = {
  code: Scalars['Int'];
  id: Scalars['String'];
  name: Scalars['String'];
  type: GeneralLedgerAccountTypeEnum;
};

export type AccountingImportJob = {
  __typename?: 'AccountingImportJob';
  dateSubmitted: Scalars['DateTime'];
  glAccounts?: Maybe<Array<AccountingImportGl>>;
  id: Scalars['String'];
  importResults?: Maybe<AccountingImportJobResult>;
  isActive: Scalars['Boolean'];
  isImported: Scalars['Boolean'];
  sai?: Maybe<Sai>;
  state?: Maybe<AccountingImportJobState>;
  submittedBy: Agent;
  version: Scalars['Int'];
};

export type AccountingImportJobResult = {
  __typename?: 'AccountingImportJobResult';
  numGeneral: Scalars['Int'];
  numPayables: Scalars['Int'];
  numPayments: Scalars['Int'];
  numReceipts: Scalars['Int'];
  numReceivables: Scalars['Int'];
};

export type AccountingImportJobState = {
  __typename?: 'AccountingImportJobState';
  destData: Array<AccountingImportDestGl>;
  importData: Array<AccountingImportGl>;
};

export type AccountingImportJobStateInput = {
  destData: Array<AccountingImportDestGlInput>;
  importData: Array<AccountingImportGlInput>;
};

export type AccountingImportPostHistoricalResult = {
  __typename?: 'AccountingImportPostHistoricalResult';
  payables: Array<AcPayable>;
  receivables: Array<AcReceivable>;
};

export type AccountingImportPreviousYearTransaction = {
  __typename?: 'AccountingImportPreviousYearTransaction';
  amount: Scalars['Money'];
  date: Scalars['LocalDate'];
  invoice: AccountingImportApTransaction;
  vendor: Vendor;
};

export type AccountingReport = {
  __typename?: 'AccountingReport';
  id: Scalars['String'];
  report: Array<AccountingReportDetails>;
  total: Scalars['Money'];
};

export type AccountingReportDetails = {
  __typename?: 'AccountingReportDetails';
  amount: Scalars['Money'];
  id: Scalars['String'];
  journalEntryLines: Array<JournalEntryLine>;
  type: Scalars['String'];
};

export type AddCommunicationFeedItemNoteInput = {
  accountInfoId?: InputMaybe<Scalars['String']>;
  color: Scalars['String'];
  contents: Scalars['String'];
  date?: InputMaybe<Scalars['DateTime']>;
  leadId?: InputMaybe<Scalars['String']>;
  pinned: Scalars['Boolean'];
  type: CommunicationTypeEnum;
};

export type AddContractInput = {
  accountInfoId: Scalars['String'];
  caftAmount?: InputMaybe<Scalars['Money']>;
  description: Scalars['String'];
  fee?: InputMaybe<CreateFeeInput>;
  name: Scalars['String'];
  preAuthorizedPayment?: InputMaybe<CreatePreAuthorizedPaymentInput>;
  startDate: Scalars['LocalDate'];
  template: Scalars['String'];
};

export type AddContractTemplateInput = {
  description: Scalars['String'];
  feeTemplate?: InputMaybe<CreateFeeTemplateInput>;
  name: Scalars['String'];
  organizationId: Scalars['String'];
};

export type AddDocumentInput = {
  accountInfoId: Scalars['String'];
  amount?: InputMaybe<Scalars['Money']>;
  cash: Scalars['Boolean'];
  corporateAsset?: InputMaybe<Scalars['String']>;
  date: Scalars['LocalDate'];
  employee?: InputMaybe<Scalars['String']>;
  fileInfoId: Scalars['String'];
  pages: Array<Scalars['Int']>;
  status: DocumentStatusEnum;
  type: Scalars['String'];
  vendor: Scalars['String'];
};

export type AddDocumentSortingClassInput = {
  active: Scalars['Boolean'];
  amountRequired: Scalars['Boolean'];
  className: Scalars['String'];
  partialSortingRequired: Scalars['Boolean'];
  type: Scalars['String'];
  vendor: Scalars['String'];
};

export type AddDraftDocumentInput = {
  accountInfoId: Scalars['String'];
  amount?: InputMaybe<Scalars['Money']>;
  cash: Scalars['Boolean'];
  corporateAsset?: InputMaybe<Scalars['String']>;
  createdDocument: Scalars['Boolean'];
  date: Scalars['LocalDate'];
  displayName: Scalars['String'];
  draftDocumentSourceId: Scalars['String'];
  employee?: InputMaybe<Scalars['String']>;
  pages: Array<Scalars['Int']>;
  status: DocumentStatusEnum;
  type?: InputMaybe<Scalars['String']>;
  vendor?: InputMaybe<Scalars['String']>;
};

export type AddExpectedDocumentExceptionInput = {
  date: Scalars['LocalDate'];
  expectedDocumentId: Scalars['String'];
  quantity: Scalars['Int'];
  reason: Scalars['String'];
};

export type AddExpectedDocumentInput = {
  accountInfoId: Scalars['String'];
  endDate?: InputMaybe<Scalars['LocalDate']>;
  frequency: ExpectedDocumentFrequencyEnum;
  quantity: Scalars['Int'];
  startDate: Scalars['LocalDate'];
  typeId: Scalars['String'];
  vendorId?: InputMaybe<Scalars['String']>;
};

export type AddInvoiceInput = {
  accountInfoId: Scalars['String'];
  contract: Scalars['String'];
  date: Scalars['LocalDate'];
  invoiceLineItems: Array<AddInvoiceLineItemsInput>;
};

export type AddInvoiceLineItemsInput = {
  amount: Scalars['Money'];
  description: Scalars['String'];
  gst: Scalars['Money'];
  hst: Scalars['Money'];
  pst: Scalars['Money'];
  qst: Scalars['Money'];
};

export type AddPaymentInput = {
  accountInfoId: Scalars['String'];
  date: Scalars['LocalDate'];
  paymentMethod: PaymentMethodEnum;
  total: Scalars['Money'];
};

export type AddPodcastInput = {
  description: Scalars['String'];
  image: Scalars['String'];
  title: Scalars['String'];
  youtubeId: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lineOne?: Maybe<Scalars['String']>;
  lineTwo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type Agent = {
  __typename?: 'Agent';
  authorizedUser?: Maybe<User>;
  bankedHours: Scalars['Float'];
  bankedVacationPay: Scalars['Money'];
  employee: Employee;
  givenName: Scalars['String'];
  id: Scalars['String'];
  office: Office;
  organization: Organization;
  roles: Array<AgentRole>;
  supervisor?: Maybe<Agent>;
  surname: Scalars['String'];
  timezone: Scalars['String'];
  version: Scalars['Int'];
};

export type AgentInvitationInput = {
  email: Scalars['String'];
  officeId: Scalars['String'];
  organizationId: Scalars['String'];
  registrationPageEndpoint: Scalars['String'];
};

export enum AgentRole {
  Admin = 'Admin',
  Director = 'Director',
  Manager = 'Manager',
  Marketing = 'Marketing',
  Recruiter = 'Recruiter',
  Sorter = 'Sorter',
  Sysadmin = 'Sysadmin',
  Workstation = 'Workstation'
}

export type AlertSettings = {
  __typename?: 'AlertSettings';
  app: Scalars['Boolean'];
  email: Scalars['Boolean'];
  id: Scalars['String'];
  notificationType?: Maybe<NotificationType>;
  sms: Scalars['Boolean'];
  version: Scalars['Int'];
};

export type Announcement = {
  __typename?: 'Announcement';
  announcementGroup: AnnouncementGroup;
  dateTimeCreated: Scalars['DateTime'];
  dateTimeSent: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  sender: Agent;
  title: Scalars['String'];
  version: Scalars['Int'];
};

export enum AnnouncementGroup {
  ActiveAccounts = 'ActiveAccounts',
  Agents = 'Agents',
  Everyone = 'Everyone'
}

export type AnnualSavingsReport = {
  __typename?: 'AnnualSavingsReport';
  days: Scalars['Int'];
  perDiem: Scalars['Money'];
  pvl: Scalars['Money'];
  tax: Scalars['Money'];
  total: Scalars['Money'];
  year: Scalars['Int'];
};

export type AssetControllerFilterType = {
  archived?: InputMaybe<Scalars['Boolean']>;
  capitalCostAllowanceClass?: InputMaybe<Scalars['String']>;
};

export type AuthorizedRepresentative = {
  __typename?: 'AuthorizedRepresentative';
  accountInfo: AccountInfo;
  contact: Contact;
  id: Scalars['String'];
  version: Scalars['Int'];
};

export enum BackupFileStatusEnum {
  Failure = 'failure',
  Pending = 'pending',
  Retrying = 'retrying',
  Success = 'success'
}

export type Balance = {
  __typename?: 'Balance';
  creditBalance: Scalars['Money'];
  debitBalance: Scalars['Money'];
  glAccount: GeneralLedgerAccount;
  id: Scalars['String'];
};

export type BalanceComparisonResult = {
  __typename?: 'BalanceComparisonResult';
  actualCredit: Scalars['Money'];
  actualDebit: Scalars['Money'];
  equal: Scalars['Boolean'];
  expectedCredit: Scalars['Money'];
  expectedDebit: Scalars['Money'];
  glAccountId: Scalars['String'];
};

export type BalanceFilterInput = {
  endDate: Scalars['LocalDate'];
  startDate: Scalars['LocalDate'];
};

export type BalanceSheet = {
  __typename?: 'BalanceSheet';
  balances: Array<Balance>;
};

export type BalanceSheetFilterInput = {
  date: Scalars['LocalDate'];
};

export type BasicAccount = {
  __typename?: 'BasicAccount';
  accountInfo: AccountInfo;
  id: Scalars['String'];
  version: Scalars['Int'];
};

export type BusinessReport = {
  __typename?: 'BusinessReport';
  id: Scalars['String'];
  report: Array<BusinessReportDetails>;
  total: Scalars['Money'];
};

export type BusinessReportDetails = {
  __typename?: 'BusinessReportDetails';
  amount: Scalars['Money'];
  id: Scalars['String'];
  journalEntryLines: Array<JournalEntryLine>;
  month: Scalars['String'];
  year: Scalars['Int'];
};

export type CaftBatch = {
  __typename?: 'CaftBatch';
  accountInfo: AccountInfo;
  bankAccountNumber: Scalars['String'];
  caftItems: Array<CaftItem>;
  caftRelease: CaftRelease;
  contract: Contract;
  createdOn: Scalars['DateTime'];
  crossReferenceNumber?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  institutionNumber: Scalars['String'];
  sequenceNumber?: Maybe<Scalars['Int']>;
  total: Scalars['Money'];
  transitNumber: Scalars['String'];
  version: Scalars['Int'];
};

export type CaftItem = {
  __typename?: 'CaftItem';
  amount: Scalars['Money'];
  caftBatch: CaftBatch;
  createdOn: Scalars['DateTime'];
  id: Scalars['String'];
  invoice?: Maybe<Invoice>;
  scheduledPayment?: Maybe<ScheduledPayment>;
  version: Scalars['Int'];
};

export type CaftOptions = {
  __typename?: 'CaftOptions';
  fileCreationNum: Scalars['Int'];
  filter: Array<AccountInfo>;
  paymentDaysAhead: Scalars['Int'];
  schedPayDaysAhead: Scalars['Int'];
};

export type CaftPending = {
  __typename?: 'CaftPending';
  amount: Scalars['Money'];
  bankAccountNumber: Scalars['String'];
  createdOn: Scalars['DateTime'];
  id: Scalars['String'];
  institutionNumber: Scalars['String'];
  transitNumber: Scalars['String'];
};

export type CaftRelease = {
  __typename?: 'CaftRelease';
  caftBatch: Array<CaftBatch>;
  createdBy: Agent;
  createdOn: Scalars['DateTime'];
  dueDate: Scalars['DateTime'];
  fileCreationNum: Scalars['Int'];
  id: Scalars['String'];
  releasedBy?: Maybe<Agent>;
  releasedOn?: Maybe<Scalars['DateTime']>;
  standard005: Scalars['String'];
  status?: Maybe<CaftReleaseStatus>;
  total: Scalars['Money'];
  version: Scalars['Int'];
};

export enum CaftReleaseStatus {
  Downloaded = 'Downloaded',
  Pending = 'Pending'
}

export type CalendarEvent = {
  __typename?: 'CalendarEvent';
  data: CalendarEventData;
  date: Scalars['LocalDate'];
  employee: Employee;
  id: Scalars['String'];
  type: CalendarEventTypeEnum;
  version: Scalars['Int'];
};

export type CalendarEventData = MaintenanceLogEvent | PerDiemEvent | PerDiemPaymentEvent | PvlEvent | PvlPaymentEvent;

export type CalendarEventFilterInput = {
  endDate?: InputMaybe<Scalars['LocalDate']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CalendarOrderBy>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
  types?: InputMaybe<Array<InputMaybe<CalendarEventTypeEnum>>>;
};

export enum CalendarEventTypeEnum {
  MaintenanceLogEvent = 'MaintenanceLogEvent',
  PerDiemEvent = 'PerDiemEvent',
  PerDiemPaymentEvent = 'PerDiemPaymentEvent',
  PvlEvent = 'PvlEvent',
  PvlPaymentEvent = 'PvlPaymentEvent'
}

export type CalendarOrderBy = {
  direction: CalendarOrderByDirection;
  field: Scalars['String'];
};

export enum CalendarOrderByDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Carrier = {
  __typename?: 'Carrier';
  vendor: Vendor;
  year: Scalars['Int'];
};

export type CarrierReport = {
  __typename?: 'CarrierReport';
  accountInfo?: Maybe<AccountInfo>;
  vendor: Vendor;
};

export type ChangeAssignedToInput = {
  assignedTo: Scalars['String'];
  ticketId: Scalars['String'];
};

export type ChangePriorityInput = {
  ticketId: Scalars['String'];
  ticketPriority: TicketPriorityEnum;
};

export type ChangeStatusInput = {
  ticketId: Scalars['String'];
  ticketStatus: TicketStatusEnum;
  userId: Scalars['String'];
};

export type ChartOfAccounts = {
  __typename?: 'ChartOfAccounts';
  accountInfo: AccountInfo;
  glAccounts?: Maybe<Array<GeneralLedgerAccount>>;
  historicClosingDate?: Maybe<Scalars['LocalDate']>;
  id: Scalars['String'];
  linkedAccounts: Array<LinkedGlAccount>;
  version: Scalars['Int'];
};


export type ChartOfAccountsGlAccountsArgs = {
  filter?: InputMaybe<ChartOfAccountsGeneralLedgerAccountsFilterInput>;
};

export type ChartOfAccountsGeneralLedgerAccountsFilterInput = {
  accountGroups?: InputMaybe<Array<GeneralLedgerAccountGroupEnum>>;
  accountTypes?: InputMaybe<Array<GeneralLedgerAccountTypeEnum>>;
};

export type ChartOfAccountsTemplate = {
  __typename?: 'ChartOfAccountsTemplate';
  currentEarningsAccount?: Maybe<GeneralLedgerAccountTemplate>;
  glAccountsTemplates?: Maybe<Array<GeneralLedgerAccountTemplate>>;
  gstCollectedAccount?: Maybe<GeneralLedgerAccountTemplate>;
  gstPaidAccount?: Maybe<GeneralLedgerAccountTemplate>;
  id: Scalars['String'];
  name: Scalars['String'];
  payablesAccount?: Maybe<GeneralLedgerAccountTemplate>;
  receivablesAccount?: Maybe<GeneralLedgerAccountTemplate>;
  retainedEarningsAccount?: Maybe<GeneralLedgerAccountTemplate>;
  version: Scalars['Int'];
};

export type ChartOfAccountsTemplateEditInput = {
  currentEarningsAccountId?: InputMaybe<Scalars['String']>;
  gstCollectedAccountId?: InputMaybe<Scalars['String']>;
  gstPaidAccountId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  payablesAccountId?: InputMaybe<Scalars['String']>;
  receivablesAccountId?: InputMaybe<Scalars['String']>;
  retainedEarningsAccountId?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type ClientEmailedData = {
  __typename?: 'ClientEmailedData';
  accountInfo: AccountInfo;
  id: Scalars['String'];
  user: User;
};

export type CommunicationFeedItem = {
  __typename?: 'CommunicationFeedItem';
  accountInfo?: Maybe<AccountInfo>;
  color?: Maybe<Scalars['String']>;
  contents: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Agent;
  data?: Maybe<CommunicationFeedItemData>;
  id: Scalars['String'];
  lead?: Maybe<Lead>;
  pinned: Scalars['Boolean'];
  type: CommunicationTypeEnum;
  version?: Maybe<Scalars['Int']>;
};

export type CommunicationFeedItemData = {
  __typename?: 'CommunicationFeedItemData';
  cc?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  from: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  to: Scalars['String'];
};

export enum CommunicationStatusEnum {
  Active = 'Active',
  Deleted = 'Deleted',
  Disabled = 'Disabled'
}

export enum CommunicationTypeEnum {
  Call = 'Call',
  CorporateTaxes = 'CorporateTaxes',
  Email = 'Email',
  Note = 'Note'
}

export type Contact = {
  __typename?: 'Contact';
  accountInfo: AccountInfo;
  authorizedUser?: Maybe<User>;
  dateOfBirth?: Maybe<Scalars['LocalDate']>;
  givenName: Scalars['String'];
  id: Scalars['String'];
  mailingAddress?: Maybe<Address>;
  physicalAddress?: Maybe<Address>;
  preferredName?: Maybe<Scalars['String']>;
  primaryEmail?: Maybe<Scalars['String']>;
  primaryPhone?: Maybe<Scalars['String']>;
  secondaryEmail?: Maybe<Scalars['String']>;
  secondaryPhone?: Maybe<Scalars['String']>;
  sin?: Maybe<Scalars['String']>;
  surname: Scalars['String'];
  version: Scalars['Int'];
};

export type Contract = {
  __typename?: 'Contract';
  accountInfo: AccountInfo;
  authorized: Scalars['Boolean'];
  authorizedBy?: Maybe<Agent>;
  caftAmount?: Maybe<Scalars['Money']>;
  description: Scalars['String'];
  endDate?: Maybe<Scalars['LocalDate']>;
  fee?: Maybe<Fee>;
  id: Scalars['String'];
  invoices?: Maybe<Array<Invoice>>;
  name: Scalars['String'];
  preAuthorizedPayment?: Maybe<PreAuthorizedPayment>;
  startDate: Scalars['LocalDate'];
  template: ContractTemplate;
  version: Scalars['Int'];
};

export type ContractData = {
  __typename?: 'ContractData';
  endDate?: Maybe<Scalars['LocalDate']>;
  name: Scalars['String'];
};

export type ContractTemplate = {
  __typename?: 'ContractTemplate';
  description: Scalars['String'];
  feeTemplate?: Maybe<FeeTemplate>;
  id: Scalars['String'];
  name: Scalars['String'];
  organization: Organization;
  version: Scalars['Int'];
};

export type ContractsInfos = {
  __typename?: 'ContractsInfos';
  caftAmount: Scalars['Money'];
  contractName?: Maybe<Scalars['String']>;
  contracts?: Maybe<Array<ContractData>>;
  feeAmount: Scalars['Money'];
};

export type Coordinates = {
  h: Scalars['Int'];
  scaleX: Scalars['Int'];
  scaleY: Scalars['Int'];
  w: Scalars['Int'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type CorporateAccount = {
  __typename?: 'CorporateAccount';
  accountInfo: AccountInfo;
  accountRating?: Maybe<AccountRating>;
  businessNumber?: Maybe<Scalars['String']>;
  chartOfAccounts: ChartOfAccounts;
  communicationCode?: Maybe<Scalars['String']>;
  directors?: Maybe<Array<Director>>;
  gstFilingFrequency: GstFilingFrequencyEnum;
  id: Scalars['String'];
  incorporationDate?: Maybe<Scalars['LocalDate']>;
  version: Scalars['Int'];
  yearEnd?: Maybe<Scalars['LocalDate']>;
};

export type CorporateAsset = {
  __typename?: 'CorporateAsset';
  accountInfo: AccountInfo;
  archived: Scalars['Boolean'];
  capitalCostAllowanceClass: Scalars['String'];
  financed: Scalars['Boolean'];
  id: Scalars['String'];
  leased: Scalars['Boolean'];
  linkToGlAccounts?: Maybe<Array<CorporateAssetToGeneralLedgerAccount>>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  truckNumber?: Maybe<Scalars['String']>;
  type: CorporateAssetTypeEnum;
  version: Scalars['Int'];
  year?: Maybe<Scalars['Int']>;
};

export type CorporateAssetGlAccountDataReturn = {
  __typename?: 'CorporateAssetGlAccountDataReturn';
  code: Scalars['Int'];
  name: Scalars['String'];
  originalGlCode?: Maybe<Scalars['Int']>;
  type: GeneralLedgerAccountTypeEnum;
};

export type CorporateAssetToGeneralLedgerAccount = {
  __typename?: 'CorporateAssetToGeneralLedgerAccount';
  corporateAsset: CorporateAsset;
  currentBalance: Scalars['Money'];
  generalLedgerAccount: GeneralLedgerAccount;
  id: Scalars['String'];
};

export enum CorporateAssetTypeEnum {
  Building = 'Building',
  Equipment = 'Equipment',
  IncorporationCost = 'IncorporationCost',
  ParkingRoad = 'ParkingRoad',
  PersonalVehicle = 'PersonalVehicle',
  Trailer = 'Trailer',
  Truck = 'Truck'
}

export type CorporateOverview = {
  __typename?: 'CorporateOverview';
  accountNotices?: Maybe<Array<AccountNotice>>;
  arrears: Scalars['Money'];
  fiscalEnd?: Maybe<Scalars['LocalDate']>;
  fiscalStart?: Maybe<Scalars['LocalDate']>;
  gst?: Maybe<Scalars['LocalDate']>;
  id?: Maybe<Scalars['String']>;
  payroll: Scalars['Money'];
  signedT183?: Maybe<Scalars['Boolean']>;
  sourceDeductions: Scalars['Money'];
  t2?: Maybe<Scalars['Int']>;
  t4?: Maybe<Scalars['Int']>;
  unprocessedDocuments?: Maybe<Scalars['Int']>;
  unsortedFiles: Scalars['Int'];
};

export enum CorporateTaxEventsEnum {
  Filed = 'Filed',
  ManagementBonus = 'ManagementBonus',
  Paid = 'Paid',
  T183 = 'T183'
}

export type CorporateTaxesEvent = {
  __typename?: 'CorporateTaxesEvent';
  date: Scalars['LocalDate'];
  details: Scalars['String'];
  id: Scalars['String'];
  taxYear: Scalars['Int'];
  type: CorporateTaxEventsEnum;
};

export type CorporateTaxesPaid = {
  __typename?: 'CorporateTaxesPaid';
  accountInfo: AccountInfo;
  amount: Scalars['Money'];
  chequeNumber?: Maybe<Scalars['Int']>;
  datePaid: Scalars['LocalDate'];
  id: Scalars['String'];
  paymentType: CorporateTaxesPaymentTypeEnum;
  taxYear: Scalars['Int'];
  version: Scalars['Int'];
};

export enum CorporateTaxesPaymentTypeEnum {
  Cheque = 'cheque',
  PreAuthorizedDebit = 'preAuthorizedDebit'
}

export type CountUserEventsFilterInput = {
  endDate?: InputMaybe<Scalars['LocalDate']>;
  feature?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
};

export type CreateAcPayableInput = {
  accountInfo: Scalars['String'];
  chequeNumber?: InputMaybe<Scalars['String']>;
  date: Scalars['LocalDate'];
  documentSource?: InputMaybe<Scalars['String']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  payableLines: Array<AcPayableLineInput>;
  payableTemplateId?: InputMaybe<Scalars['String']>;
  paymentGlAccount?: InputMaybe<Scalars['String']>;
  paymentMethod: AcPaymentMethodEnum;
  vendor: Scalars['String'];
};

export type CreateAcPayableTemplateInput = {
  accountInfo: Scalars['String'];
  description: Scalars['String'];
  endDate?: InputMaybe<Scalars['LocalDate']>;
  nextDueEndOfTheMonth?: InputMaybe<Scalars['Boolean']>;
  nextScheduledDate?: InputMaybe<Scalars['LocalDate']>;
  payableTemplateLines: Array<AcPayableTemplateLineInput>;
  paymentGlAccount?: InputMaybe<Scalars['String']>;
  paymentMethod: AcPaymentMethodEnum;
  quantity?: InputMaybe<Scalars['Int']>;
  recurrencePattern: RecurrencePatternEnum;
  vendor: Scalars['String'];
};

export type CreateAcPaymentInput = {
  accountInfo: Scalars['String'];
  chequeNumber?: InputMaybe<Scalars['String']>;
  date: Scalars['LocalDate'];
  description?: InputMaybe<Scalars['String']>;
  paymentGlAccount: Scalars['String'];
  paymentLines: Array<AcPaymentLineInput>;
  paymentMethod: AcPaymentMethodEnum;
  vendor: Scalars['String'];
};

export type CreateAcReceiptInput = {
  accountInfo: Scalars['String'];
  chequeNumber?: InputMaybe<Scalars['String']>;
  date: Scalars['LocalDate'];
  description?: InputMaybe<Scalars['String']>;
  paymentGlAccount: Scalars['String'];
  paymentMethod: AcPaymentMethodEnum;
  receiptLines: Array<AcReceiptLineInput>;
  vendor: Scalars['String'];
};

export type CreateAcReceivableInput = {
  accountInfo: Scalars['String'];
  chequeNumber?: InputMaybe<Scalars['String']>;
  date: Scalars['LocalDate'];
  documentSource?: InputMaybe<Scalars['String']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  paymentGlAccount?: InputMaybe<Scalars['String']>;
  paymentMethod: AcPaymentMethodEnum;
  receivableLines: Array<AcReceivableLineInput>;
  receivableTemplateId?: InputMaybe<Scalars['String']>;
  vendor: Scalars['String'];
};

export type CreateAcReceivableTemplateInput = {
  accountInfo: Scalars['String'];
  description: Scalars['String'];
  endDate?: InputMaybe<Scalars['LocalDate']>;
  nextDueEndOfTheMonth?: InputMaybe<Scalars['Boolean']>;
  nextScheduledDate?: InputMaybe<Scalars['LocalDate']>;
  paymentGlAccount?: InputMaybe<Scalars['String']>;
  paymentMethod: AcPaymentMethodEnum;
  quantity?: InputMaybe<Scalars['Int']>;
  receivableTemplateLines: Array<AcReceivableTemplateLineInput>;
  recurrencePattern: RecurrencePatternEnum;
  vendor: Scalars['String'];
};

export type CreateAccommodationAmountInput = {
  amount: Scalars['Money'];
  effectiveDate: Scalars['LocalDate'];
};

export type CreateAccountInfoInput = {
  authorizedRepresentatives?: InputMaybe<Array<EditContactInput>>;
  contacts?: InputMaybe<Array<EditContactInput>>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AccountTypeEnum>;
};

export type CreateAccountInput = {
  authorizedRepresentatives?: InputMaybe<Array<EditContactInput>>;
  managerId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AccountTypeEnum>;
};

export type CreateAccountsInput = {
  accounts: Array<AccountDetailsInput>;
  leadId?: InputMaybe<Scalars['String']>;
  office: Scalars['String'];
  registrationPageURL: Scalars['String'];
};

export type CreateAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  lineOne?: InputMaybe<Scalars['String']>;
  lineTwo?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
};

export type CreateAnnouncementInput = {
  announcementGroup: AnnouncementGroup;
  description: Scalars['String'];
  mobileRoute?: InputMaybe<Scalars['String']>;
  notificationTypeId: Scalars['String'];
  title: Scalars['String'];
  webRoute?: InputMaybe<Scalars['String']>;
};

export type CreateAuthorizedRepresentativeAndContactInput = {
  accountInfoId: Scalars['String'];
  contactId?: InputMaybe<Scalars['String']>;
  createContact?: InputMaybe<CreateContactInput>;
};

export type CreateCalendarEventInput = {
  date: Scalars['LocalDate'];
  employeeId: Scalars['String'];
  type: CalendarEventTypeEnum;
};

export type CreateContactInput = {
  accountInfo?: InputMaybe<CreateAccountInfoInput>;
  createUser?: InputMaybe<Scalars['Boolean']>;
  dateOfBirth?: InputMaybe<Scalars['LocalDate']>;
  givenName: Scalars['String'];
  linkExistingUser?: InputMaybe<Scalars['Boolean']>;
  mailingAddress?: InputMaybe<CreateAddressInput>;
  physicalAddress?: InputMaybe<CreateAddressInput>;
  preferredName?: InputMaybe<Scalars['String']>;
  primaryEmail?: InputMaybe<Scalars['String']>;
  primaryPhone?: InputMaybe<Scalars['String']>;
  sameMailingAddress?: InputMaybe<Scalars['Boolean']>;
  secondaryEmail?: InputMaybe<Scalars['String']>;
  secondaryPhone?: InputMaybe<Scalars['String']>;
  sin?: InputMaybe<Scalars['String']>;
  surname: Scalars['String'];
};

export type CreateCorporateAssetInput = {
  accountInfoId: Scalars['String'];
  capitalCostAllowanceClass: Scalars['String'];
  financed: Scalars['Boolean'];
  leased: Scalars['Boolean'];
  make?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  truckNumber?: InputMaybe<Scalars['String']>;
  type: CorporateAssetTypeEnum;
  year?: InputMaybe<Scalars['Int']>;
};

export type CreateCorporateTaxesPaidInput = {
  accountInfoId: Scalars['String'];
  amount: Scalars['Money'];
  chequeNumber?: InputMaybe<Scalars['Int']>;
  datePaid: Scalars['LocalDate'];
  paymentType: CorporateTaxesPaymentTypeEnum;
  taxYear: Scalars['Int'];
};

export type CreateDirectorDataInput = {
  accountInfoId: Scalars['String'];
  contact?: InputMaybe<CreateContactInput>;
  contactId?: InputMaybe<Scalars['String']>;
  director: CreateDirectorInput;
};

export type CreateDirectorInput = {
  dateElected: Scalars['LocalDate'];
  dateSteppedDown?: InputMaybe<Scalars['LocalDate']>;
  office: DirectorOfficeEnum;
};

export type CreateDocumentTypeInput = {
  amount: Scalars['Boolean'];
  expected: Scalars['Boolean'];
  group: DocumentGroupEnum;
  name: Scalars['String'];
  statement: Scalars['Boolean'];
};

export type CreateDomesticReductionRateInput = {
  effectiveDate: Scalars['LocalDate'];
  rate: Scalars['Float'];
};

export type CreateEiRateInput = {
  effectiveDate: Scalars['LocalDate'];
  rate: Scalars['Float'];
};

export type CreateEmployeeAndContactInput = {
  accountInfoId: Scalars['String'];
  contactId?: InputMaybe<Scalars['String']>;
  employeeData: CreateEmployeeDataInput;
};

export type CreateEmployeeDataInput = {
  contact?: InputMaybe<CreateContactInput>;
  endDate?: InputMaybe<Scalars['LocalDate']>;
  homeTerminal: ProvinceEnum;
  paymentInterval: EmployeePaymentIntervalEnum;
  startDate?: InputMaybe<Scalars['LocalDate']>;
};

export type CreateExpenseInput = {
  accountInfoId: Scalars['String'];
  comment?: InputMaybe<Scalars['String']>;
  date: Scalars['LocalDate'];
  glAccountId: Scalars['String'];
  gst?: InputMaybe<Scalars['Money']>;
  id: Scalars['String'];
  percentClaimed: Scalars['Int'];
  uiTotal: Scalars['Money'];
};

export type CreateFeeInput = {
  date: Scalars['LocalDate'];
  feeLineItems: Array<FeeLineItemInput>;
  frequency: FrequencyEnum;
};

export type CreateFeeTemplateInput = {
  date: Scalars['LocalDate'];
  feeTemplateLineItems: Array<FeeLineItemInput>;
  frequency: FrequencyEnum;
};

export type CreateFinancialAccountInput = {
  accountCurrency: CurrencyCountryEnum;
  accountInfoId: Scalars['String'];
  accountNickname: Scalars['String'];
  accountNumber: Scalars['String'];
  institutionId: Scalars['String'];
  type: FinancialAccountTypeEnum;
};

export type CreateFuelTaxRateInput = {
  displayName: Scalars['String'];
  fuelTax: Scalars['Money'];
};

export type CreateGeneralLedgerAccountInput = {
  accountInfoId: Scalars['String'];
  code: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  type: GeneralLedgerAccountTypeEnum;
};

export type CreateGeneralLedgerAccountTemplateInput = {
  chartOfAccountsTemplate: Scalars['String'];
  code: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  type: GeneralLedgerAccountTypeEnum;
};

export type CreateGstDepositedInput = {
  accountInfoId: Scalars['String'];
  amount: Scalars['Money'];
  dateDeposited: Scalars['LocalDate'];
  gstFiled: Scalars['String'];
};

export type CreateGstFiledInput = {
  accountInfoId: Scalars['String'];
  amount: Scalars['Money'];
  confirmationNumber: Scalars['String'];
  filedOnDate: Scalars['LocalDate'];
  filingMethod: FilingMethodEnum;
  fromDate: Scalars['LocalDate'];
  toDate: Scalars['LocalDate'];
};

export type CreateGstHeldInput = {
  accountInfoId: Scalars['String'];
  amount: Scalars['Money'];
  dateHeld: Scalars['LocalDate'];
  gstFiled: Scalars['String'];
  reason: GstHeldReasonEnum;
};

export type CreateGstPaidInput = {
  accountInfoId: Scalars['String'];
  amount: Scalars['Money'];
  chequeNumber?: InputMaybe<Scalars['Int']>;
  datePaid: Scalars['LocalDate'];
  filedGsts: Array<Scalars['String']>;
  paymentType: GstPaymentTypeEnum;
};

export type CreateJournalEntryInput = {
  accountInfo: Scalars['String'];
  comment?: InputMaybe<Scalars['String']>;
  date: Scalars['LocalDate'];
  documentSource?: InputMaybe<Scalars['String']>;
  journalEntryLines: Array<JournalEntryLineInput>;
  journalEntryTemplateId?: InputMaybe<Scalars['String']>;
  posted: Scalars['Boolean'];
  type: JournalEntryTypeEnum;
};

export type CreateJournalEntryTemplateInput = {
  accountInfo: Scalars['String'];
  comment?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  endDate?: InputMaybe<Scalars['LocalDate']>;
  journalEntryTemplateLines: Array<JournalEntryTemplateLineInput>;
  nextDueEndOfTheMonth?: InputMaybe<Scalars['Boolean']>;
  nextScheduledDate?: InputMaybe<Scalars['LocalDate']>;
  quantity?: InputMaybe<Scalars['Int']>;
  recurrencePattern: RecurrencePatternEnum;
};

export type CreateLeadInput = {
  details?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  givenName: Scalars['String'];
  mailingAddress?: InputMaybe<CreateAddressInput>;
  primaryPhone?: InputMaybe<Scalars['String']>;
  referral?: InputMaybe<ReferralEnum>;
  secondaryPhone?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
};

export type CreateMaintenanceLogEventDataInput = {
  amount: Scalars['Money'];
  corporateAssetId: Scalars['String'];
  odometer?: InputMaybe<Scalars['Int']>;
  serviceDescription: Scalars['String'];
  vendor: Scalars['String'];
};

export type CreateManagementBonusInput = {
  accountInfoId: Scalars['String'];
  amount: Scalars['Money'];
  directorId: Scalars['String'];
  taxYear: Scalars['Int'];
};

export type CreateMinimumWageInput = {
  effectiveDate: Scalars['LocalDate'];
  hourlyRate: Scalars['Money'];
  province: ProvinceEnum;
};

export type CreateOfficeInput = {
  mailingAddress: CreateAddressInput;
  name: Scalars['String'];
  organizationId: Scalars['String'];
  physicalAddress: CreateAddressInput;
  timezone: Scalars['String'];
};

export type CreateOrganizationInput = {
  name: Scalars['String'];
  payrollFrequency: PayrollFrequencyEnum;
  payrollStartDate: Scalars['LocalDate'];
};

export type CreatePartnerDataInput = {
  accountInfoId: Scalars['String'];
  contact?: InputMaybe<CreateContactInput>;
  contactId?: InputMaybe<Scalars['String']>;
  partner: CreatePartnerInput;
};

export type CreatePartnerInput = {
  ownershipPercentage: Scalars['Int'];
};

export type CreatePayStatementInput = {
  accountInfoId: Scalars['String'];
  comment?: InputMaybe<Scalars['String']>;
  date: Scalars['LocalDate'];
  payStatementLines: Array<CreatePayStatementLineInput>;
};

export type CreatePayStatementLineInput = {
  glAccountId: Scalars['String'];
  gst?: InputMaybe<Scalars['Money']>;
  lineNumber: Scalars['Int'];
  uiTotal: Scalars['Money'];
};

export type CreatePerDiemEventsInput = {
  date: Scalars['LocalDate'];
  eliminateShowerAndAmenities: Scalars['Boolean'];
  location: LocationEnum;
  overnight: Scalars['Boolean'];
  paid?: InputMaybe<Scalars['Boolean']>;
};

export type CreatePersonalVehicleInput = {
  accountInfoId: Scalars['String'];
  color?: InputMaybe<Scalars['String']>;
  make: Scalars['String'];
  model?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  year?: InputMaybe<Scalars['Int']>;
};

export type CreatePreAuthorizedPaymentInput = {
  authorizedLimit: Scalars['Money'];
  bankAccountNumber: Scalars['String'];
  date: Scalars['LocalDate'];
  frequency: FrequencyEnum;
  institutionNumber: Scalars['String'];
  transitNumber: Scalars['String'];
};

export type CreatePvlEventDataInput = {
  endingKM: Scalars['Float'];
  options?: InputMaybe<Array<Scalars['String']>>;
  otherReason?: InputMaybe<Scalars['String']>;
  paid?: InputMaybe<Scalars['Boolean']>;
  personalVehicleId: Scalars['String'];
  startingKM: Scalars['Float'];
};

export type CreatePvlRateInput = {
  after5000Rate: Scalars['Float'];
  effectiveDate: Scalars['LocalDate'];
  first5000Rate: Scalars['Float'];
  province: ProvinceEnum;
  sourceImage?: InputMaybe<Scalars['String']>;
  sourceUrl: Scalars['String'];
};

export type CreateReminderInput = {
  message: Scalars['String'];
  scheduledDeliveryDay: Scalars['LocalDate'];
  sendToId: Scalars['String'];
};

export type CreateSourceDeductionOpeningBalanceInput = {
  accountInfoId: Scalars['String'];
  amount: Scalars['Money'];
  description?: InputMaybe<Scalars['String']>;
  year: Scalars['Int'];
};

export type CreateSourceDeductionPayableInput = {
  accountInfoId: Scalars['String'];
  amount: Scalars['Money'];
  date: Scalars['LocalDate'];
  description?: InputMaybe<Scalars['String']>;
  period: Scalars['LocalDate'];
};

export type CreateSourceDeductionPaymentInput = {
  accountInfoId: Scalars['String'];
  chequeNumber?: InputMaybe<Scalars['Int']>;
  date: Scalars['LocalDate'];
  lineItems: Array<SourceDeductionPaymentLineItemInput>;
  paymentType: SourceDeductionPaymentTypeEnum;
  period: Scalars['LocalDate'];
};

export type CreateSourceDeductionStatementBalanceInput = {
  accountInfoId: Scalars['String'];
  amount: Scalars['Money'];
  period: Scalars['LocalDate'];
};

export type CreateStatementInput = {
  closingBalance: Scalars['Money'];
  document: Scalars['String'];
  financialAccountId: Scalars['String'];
  openingBalance: Scalars['Money'];
  statementLines: Array<CreateStatementLinesInput>;
};

export type CreateStatementLinesInput = {
  credit?: InputMaybe<Scalars['Money']>;
  date: Scalars['LocalDate'];
  debit?: InputMaybe<Scalars['Money']>;
  description: Scalars['String'];
  document?: InputMaybe<Scalars['String']>;
  reconciled: Scalars['Boolean'];
  status?: InputMaybe<StatementStatusEnum>;
};

export type CreateT1Input = {
  accountInfoId: Scalars['String'];
  confirmationNumber: Scalars['String'];
  filedOn: Scalars['LocalDate'];
  taxYear: Scalars['Int'];
  taxes: Scalars['Money'];
};

export type CreateT2FiledInput = {
  accountInfoId: Scalars['String'];
  confirmationNumber: Scalars['String'];
  filedOnDate: Scalars['LocalDate'];
  filingMethod: FilingMethodEnum;
  taxAmount: Scalars['Money'];
  taxYear: Scalars['Int'];
};

export type CreateT4Input = {
  accountInfoId: Scalars['String'];
  confirmationNumber: Scalars['String'];
  filedOn: Scalars['LocalDate'];
  taxYear: Scalars['Int'];
};

export type CreateT5Input = {
  accountInfoId: Scalars['String'];
  confirmationNumber: Scalars['String'];
  dividendAmount: Scalars['Money'];
  filedOn: Scalars['LocalDate'];
  taxYear: Scalars['Int'];
};

export type CreateTaxRateInput = {
  effectiveDate: Scalars['LocalDate'];
  gstHst?: InputMaybe<Scalars['Float']>;
  province: ProvinceEnum;
  pst?: InputMaybe<Scalars['Float']>;
};

export type CreateTicketCategoryInput = {
  defaultAgentId: Scalars['String'];
  name: Scalars['String'];
};

export type CreateTicketInput = {
  attachment?: InputMaybe<Scalars['String']>;
  categoryId: Scalars['String'];
  createdByUserId: Scalars['String'];
  description: Scalars['String'];
};

export type CreateTicketReplyInput = {
  attachment?: InputMaybe<Scalars['String']>;
  createdByUserId: Scalars['String'];
  message: Scalars['String'];
  resolved: Scalars['Boolean'];
  ticketId: Scalars['String'];
};

export type CreateTreasuryAmountInput = {
  effectiveDate: Scalars['LocalDate'];
  incidentalExpenseAllowance: Scalars['Money'];
  location: LocationEnum;
  mealAllowance: Scalars['Money'];
};

export type CreateUserInput = {
  email: Scalars['String'];
  givenName: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<ServiceInput>;
  surname: Scalars['String'];
};

export type CreateVendorInput = {
  accountInfoId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type Crop = {
  coordinates: Coordinates;
  draftDocumentSourceId: Scalars['String'];
  page: Scalars['Int'];
};

export enum CurrencyCountryEnum {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ars = 'ARS',
  Aud = 'AUD',
  Azn = 'AZN',
  Bam = 'BAM',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bwp = 'BWP',
  Byr = 'BYR',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Eek = 'EEK',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Khr = 'KHR',
  Kmf = 'KMF',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kzt = 'KZT',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Ltl = 'LTL',
  Lvl = 'LVL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mop = 'MOP',
  Mur = 'MUR',
  Mxn = 'MXN',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Sos = 'SOS',
  Syp = 'SYP',
  Thb = 'THB',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Vef = 'VEF',
  Vnd = 'VND',
  Xaf = 'XAF',
  Xof = 'XOF',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmk = 'ZMK'
}

export type DateRangeBalances = {
  __typename?: 'DateRangeBalances';
  closingBalance: Balance;
  openingBalance: Balance;
};

export type DecodedAgentRegistrationToken = {
  __typename?: 'DecodedAgentRegistrationToken';
  User?: Maybe<User>;
  email: Scalars['String'];
  managerId: Scalars['String'];
};

export type DecodedEmployeeRegistrationToken = {
  __typename?: 'DecodedEmployeeRegistrationToken';
  User?: Maybe<User>;
  account: AccountInfo;
  contact: Contact;
};

export type DeleteExpenseInput = {
  id: Scalars['String'];
  version: Scalars['Int'];
};

export type Device = {
  __typename?: 'Device';
  active: Scalars['Boolean'];
  deviceId: Scalars['String'];
  firebaseId: Scalars['String'];
  id: Scalars['String'];
  manufacturer: Scalars['String'];
  model: Scalars['String'];
  registeredDate: Scalars['DateTime'];
  secureToken: Scalars['String'];
  system: Scalars['String'];
  systemVersion: Scalars['String'];
  uniqueId: Scalars['String'];
  user: User;
};

export type Director = {
  __typename?: 'Director';
  contact: Contact;
  dateElected: Scalars['LocalDate'];
  dateSteppedDown?: Maybe<Scalars['LocalDate']>;
  id: Scalars['String'];
  office: DirectorOfficeEnum;
  version: Scalars['Int'];
};

export enum DirectorOfficeEnum {
  President = 'president',
  VicePresident = 'vicePresident'
}

export type Document = {
  __typename?: 'Document';
  account: AccountInfo;
  amount?: Maybe<Scalars['Money']>;
  cash: Scalars['Boolean'];
  corporateAsset?: Maybe<CorporateAsset>;
  date: Scalars['LocalDate'];
  employee?: Maybe<Employee>;
  fileInfo: FileInfo;
  id: Scalars['String'];
  pages: Array<Scalars['Int']>;
  sortedBy: Agent;
  sortedOn: Scalars['LocalDate'];
  statement?: Maybe<Statement>;
  status: DocumentStatusEnum;
  type: DocumentType;
  vendor: Vendor;
  version: Scalars['Int'];
};

export enum DocumentGroupEnum {
  Accounting = 'Accounting',
  CorporateDissolution = 'CorporateDissolution',
  Cra = 'Cra',
  Incorporation = 'Incorporation'
}

export type DocumentSorterReport = {
  __typename?: 'DocumentSorterReport';
  endDate: Scalars['LocalDate'];
  id: Scalars['String'];
  startDate: Scalars['LocalDate'];
  systemSorted: Scalars['Int'];
  totalDocuments: Scalars['Int'];
  userSorted: Scalars['Int'];
};

export type DocumentSortingClass = {
  __typename?: 'DocumentSortingClass';
  active: Scalars['Boolean'];
  amountRequired: Scalars['Boolean'];
  changedDocumentAmountCount: Scalars['Int'];
  changedDocumentDateCount: Scalars['Int'];
  changedDocumentTypeCount: Scalars['Int'];
  changedDocumentVendorCount: Scalars['Int'];
  className: Scalars['String'];
  count: Scalars['Int'];
  id: Scalars['String'];
  partialCount: Scalars['Int'];
  partialSortingRequired: Scalars['Boolean'];
  type: DocumentType;
  vendor: Vendor;
  version: Scalars['Int'];
};

export enum DocumentStatusEnum {
  Entered = 'Entered',
  Pending = 'Pending',
  Reconciled = 'Reconciled',
  Reviewed = 'Reviewed'
}

export type DocumentType = {
  __typename?: 'DocumentType';
  amount: Scalars['Boolean'];
  archived: Scalars['Boolean'];
  changedSystemCount: Scalars['Int'];
  changedUserCount?: Maybe<Scalars['Int']>;
  expected: Scalars['Boolean'];
  group: DocumentGroupEnum;
  id: Scalars['String'];
  name: Scalars['String'];
  sortedBySystemCount: Scalars['Int'];
  sortedByUserCount: Scalars['Int'];
  statement: Scalars['Boolean'];
  version: Scalars['Int'];
};

export type DocumentTypeBreakdown = {
  __typename?: 'DocumentTypeBreakdown';
  count: Scalars['Int'];
  documentType: DocumentType;
  vendor: Vendor;
};

export type DocumentUploadSourceReport = {
  __typename?: 'DocumentUploadSourceReport';
  app: Scalars['Int'];
  email: Scalars['Int'];
  endDate: Scalars['LocalDate'];
  id: Scalars['String'];
  startDate: Scalars['LocalDate'];
  website: Scalars['Int'];
};

export type DocumentsFilterInput = {
  cash?: InputMaybe<Scalars['Boolean']>;
  corporateAsset?: InputMaybe<Scalars['String']>;
  employee?: InputMaybe<Scalars['String']>;
  endDate: Scalars['LocalDate'];
  group?: InputMaybe<DocumentGroupEnum>;
  startDate: Scalars['LocalDate'];
  status?: InputMaybe<Array<DocumentStatusEnum>>;
  type?: InputMaybe<Scalars['String']>;
  vendor?: InputMaybe<Scalars['String']>;
};

export type DomesticReductionRate = {
  __typename?: 'DomesticReductionRate';
  effectiveDate: Scalars['LocalDate'];
  id: Scalars['String'];
  rate: Scalars['Float'];
  version: Scalars['Int'];
};

export type DraftDocument = {
  __typename?: 'DraftDocument';
  account: AccountInfo;
  amount?: Maybe<Scalars['Money']>;
  cash: Scalars['Boolean'];
  corporateAsset?: Maybe<CorporateAsset>;
  createdDocument: Scalars['Boolean'];
  date: Scalars['LocalDate'];
  displayName: Scalars['String'];
  employee?: Maybe<Employee>;
  fileInfo: FileInfo;
  id: Scalars['String'];
  pages: Array<Scalars['Int']>;
  sortedBy: Agent;
  sortedOn: Scalars['LocalDate'];
  statement?: Maybe<Statement>;
  status: DocumentStatusEnum;
  type?: Maybe<DocumentType>;
  vendor?: Maybe<Vendor>;
  version: Scalars['Int'];
};

export type DraftDocumentSource = {
  __typename?: 'DraftDocumentSource';
  accountInfo: AccountInfo;
  baseFileInfo: FileInfo;
  id: Scalars['String'];
  version: Scalars['Int'];
};

export type DropdownAccountTypes = {
  __typename?: 'DropdownAccountTypes';
  id: Scalars['String'];
  text: Scalars['String'];
  value: AccountTypeEnum;
};

export type DropdownAgentRoles = {
  __typename?: 'DropdownAgentRoles';
  id: Scalars['String'];
  text: Scalars['String'];
  value: AgentRole;
};

export type DropdownGstFilingFrequencyTypes = {
  __typename?: 'DropdownGSTFilingFrequencyTypes';
  id: Scalars['String'];
  text: Scalars['String'];
  value: GstFilingFrequencyEnum;
};

export type EiRate = {
  __typename?: 'EIRate';
  effectiveDate: Scalars['LocalDate'];
  id: Scalars['String'];
  rate: Scalars['Float'];
  version: Scalars['Int'];
};

export type EditAcPayableInput = {
  accountInfo: Scalars['String'];
  chequeNumber?: InputMaybe<Scalars['String']>;
  date: Scalars['LocalDate'];
  documentSource?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  invoiceNumber?: InputMaybe<Scalars['String']>;
  payableLines?: InputMaybe<Array<AcPayableLineInput>>;
  paymentGlAccount?: InputMaybe<Scalars['String']>;
  paymentMethod: AcPaymentMethodEnum;
  vendor: Scalars['String'];
  version: Scalars['Int'];
};

export type EditAcPayableTemplateInput = {
  accountInfo: Scalars['String'];
  description: Scalars['String'];
  endDate?: InputMaybe<Scalars['LocalDate']>;
  id: Scalars['String'];
  nextDueEndOfTheMonth?: InputMaybe<Scalars['Boolean']>;
  nextScheduledDate?: InputMaybe<Scalars['LocalDate']>;
  payableTemplateLines?: InputMaybe<Array<AcPayableTemplateLineInput>>;
  paymentGlAccount?: InputMaybe<Scalars['String']>;
  paymentMethod: AcPaymentMethodEnum;
  quantity?: InputMaybe<Scalars['Int']>;
  recurrencePattern: RecurrencePatternEnum;
  vendor: Scalars['String'];
  version: Scalars['Int'];
};

export type EditAcPaymentInput = {
  accountInfo: Scalars['String'];
  chequeNumber?: InputMaybe<Scalars['String']>;
  date: Scalars['LocalDate'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  paymentGlAccount: Scalars['String'];
  paymentLines: Array<AcPaymentLineInput>;
  paymentMethod: AcPaymentMethodEnum;
  vendor: Scalars['String'];
  version: Scalars['Int'];
};

export type EditAcReceiptInput = {
  accountInfo: Scalars['String'];
  chequeNumber?: InputMaybe<Scalars['String']>;
  date: Scalars['LocalDate'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  paymentGlAccount: Scalars['String'];
  paymentMethod: AcPaymentMethodEnum;
  receiptLines: Array<AcReceiptLineInput>;
  vendor: Scalars['String'];
  version: Scalars['Int'];
};

export type EditAcReceivableInput = {
  accountInfo: Scalars['String'];
  chequeNumber?: InputMaybe<Scalars['String']>;
  date: Scalars['LocalDate'];
  documentSource?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  invoiceNumber?: InputMaybe<Scalars['String']>;
  paymentGlAccount?: InputMaybe<Scalars['String']>;
  paymentMethod: AcPaymentMethodEnum;
  receivableLines: Array<AcReceivableLineInput>;
  vendor: Scalars['String'];
  version: Scalars['Int'];
};

export type EditAcReceivableTemplateInput = {
  accountInfo: Scalars['String'];
  description: Scalars['String'];
  endDate?: InputMaybe<Scalars['LocalDate']>;
  id: Scalars['String'];
  nextDueEndOfTheMonth?: InputMaybe<Scalars['Boolean']>;
  nextScheduledDate?: InputMaybe<Scalars['LocalDate']>;
  paymentGlAccount?: InputMaybe<Scalars['String']>;
  paymentMethod: AcPaymentMethodEnum;
  quantity?: InputMaybe<Scalars['Int']>;
  receivableTemplateLines?: InputMaybe<Array<AcReceivableTemplateLineInput>>;
  recurrencePattern: RecurrencePatternEnum;
  vendor: Scalars['String'];
  version: Scalars['Int'];
};

export type EditAccommodationAmountInput = {
  amount: Scalars['Money'];
  effectiveDate: Scalars['LocalDate'];
  id: Scalars['String'];
  version: Scalars['Int'];
};

export type EditAccountInfoInput = {
  authorizedRepresentatives?: InputMaybe<Array<EditContactInput>>;
  contacts?: InputMaybe<Array<EditContactInput>>;
  createAddress?: InputMaybe<CreateAddressInput>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type EditAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lineOne?: InputMaybe<Scalars['String']>;
  lineTwo?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
};

export type EditAgentInput = {
  employeeId?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  location?: InputMaybe<Scalars['String']>;
  officeId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<AgentRole>>;
  supervisorId?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type EditAlertSettingsInput = {
  app: Scalars['Boolean'];
  email: Scalars['Boolean'];
  id: Scalars['String'];
  sms: Scalars['Boolean'];
  version: Scalars['Int'];
};

export type EditBasicInput = {
  id: Scalars['String'];
  version: Scalars['Int'];
};

export type EditContactDataInput = {
  contact: EditContactInput;
  createMailingAddress?: InputMaybe<CreateAddressInput>;
  createPhysicalAddress?: InputMaybe<CreateAddressInput>;
};

export type EditContactInput = {
  accountInfo?: InputMaybe<EditAccountInfoInput>;
  authorizedUser?: InputMaybe<EditUserInput>;
  dateOfBirth?: InputMaybe<Scalars['LocalDate']>;
  givenName: Scalars['String'];
  id: Scalars['String'];
  mailingAddress?: InputMaybe<EditAddressInput>;
  physicalAddress?: InputMaybe<EditAddressInput>;
  preferredName?: InputMaybe<Scalars['String']>;
  primaryEmail?: InputMaybe<Scalars['String']>;
  primaryPhone?: InputMaybe<Scalars['String']>;
  secondaryEmail?: InputMaybe<Scalars['String']>;
  secondaryPhone?: InputMaybe<Scalars['String']>;
  sin?: InputMaybe<Scalars['String']>;
  surname: Scalars['String'];
  version: Scalars['Int'];
};

export type EditContractInput = {
  caftAmount?: InputMaybe<Scalars['Money']>;
  description: Scalars['String'];
  fee?: InputMaybe<EditFeeInput>;
  id: Scalars['String'];
  name: Scalars['String'];
  preAuthorizedPayment?: InputMaybe<EditPreAuthorizedPaymentInput>;
  startDate: Scalars['LocalDate'];
  version: Scalars['Int'];
};

export type EditContractTemplateInput = {
  description: Scalars['String'];
  feeTemplate?: InputMaybe<EditFeeTemplateInput>;
  id: Scalars['String'];
  name: Scalars['String'];
  organizationId: Scalars['String'];
  version: Scalars['Int'];
};

export type EditCorporateAssetInput = {
  capitalCostAllowanceClass: Scalars['String'];
  financed: Scalars['Boolean'];
  id: Scalars['String'];
  leased: Scalars['Boolean'];
  make?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  truckNumber?: InputMaybe<Scalars['String']>;
  type: CorporateAssetTypeEnum;
  version: Scalars['Int'];
  year?: InputMaybe<Scalars['Int']>;
};

export type EditCorporateTaxesPaidInput = {
  amount: Scalars['Money'];
  chequeNumber?: InputMaybe<Scalars['Int']>;
  datePaid: Scalars['LocalDate'];
  id: Scalars['String'];
  paymentType: CorporateTaxesPaymentTypeEnum;
  taxYear: Scalars['Int'];
};

export type EditDirectorDataInput = {
  contact: EditContactInput;
  createMailingAddress?: InputMaybe<CreateAddressInput>;
  createPhysicalAddress?: InputMaybe<CreateAddressInput>;
  director: EditDirectorInput;
};

export type EditDirectorInput = {
  dateElected: Scalars['LocalDate'];
  dateSteppedDown?: InputMaybe<Scalars['LocalDate']>;
  id: Scalars['String'];
  office: DirectorOfficeEnum;
  version: Scalars['Int'];
};

export type EditDocumentTypeInput = {
  amount: Scalars['Boolean'];
  archived: Scalars['Boolean'];
  expected: Scalars['Boolean'];
  group: DocumentGroupEnum;
  id: Scalars['String'];
  name: Scalars['String'];
  statement: Scalars['Boolean'];
  version: Scalars['Int'];
};

export type EditDomesticReductionRateInput = {
  effectiveDate: Scalars['LocalDate'];
  id: Scalars['String'];
  rate: Scalars['Float'];
  version: Scalars['Int'];
};

export type EditEiRateInput = {
  effectiveDate: Scalars['LocalDate'];
  id: Scalars['String'];
  rate: Scalars['Float'];
  version: Scalars['Int'];
};

export type EditEmployeeAndContactInput = {
  contact: EditContactInput;
  employee: EditEmployeeInputData;
  mailingAddress?: InputMaybe<EditAddressInput>;
  physicalAddress?: InputMaybe<EditAddressInput>;
};

export type EditEmployeeInputData = {
  endDate?: InputMaybe<Scalars['LocalDate']>;
  homeTerminal: ProvinceEnum;
  id: Scalars['String'];
  paymentInterval?: InputMaybe<EmployeePaymentIntervalEnum>;
  roeConfirmationNumber?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
  version: Scalars['Int'];
};

export type EditExpectedDocumentExceptionInput = {
  id: Scalars['String'];
  quantity: Scalars['Int'];
  reason: Scalars['String'];
  version: Scalars['Int'];
};

export type EditExpectedDocumentInput = {
  accountId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['LocalDate']>;
  frequency: ExpectedDocumentFrequencyEnum;
  id: Scalars['String'];
  quantity: Scalars['Int'];
  startDate: Scalars['LocalDate'];
  typeId: Scalars['String'];
  vendorId?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type EditExpenseInput = {
  comment?: InputMaybe<Scalars['String']>;
  date: Scalars['LocalDate'];
  glAccountId: Scalars['String'];
  gst?: InputMaybe<Scalars['Money']>;
  id: Scalars['String'];
  percentClaimed: Scalars['Int'];
  uiTotal: Scalars['Money'];
  version: Scalars['Int'];
};

export type EditFeeInput = {
  date: Scalars['LocalDate'];
  feeLineItems: Array<FeeLineItemInput>;
  frequency: FrequencyEnum;
  id?: InputMaybe<Scalars['String']>;
  lastGeneration?: InputMaybe<Scalars['DateTime']>;
  version?: InputMaybe<Scalars['Int']>;
};

export type EditFeeTemplateInput = {
  date: Scalars['LocalDate'];
  feeTemplateLineItems: Array<FeeLineItemInput>;
  frequency: FrequencyEnum;
  id?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
};

export type EditFinancialAccountInput = {
  accountCurrency: CurrencyCountryEnum;
  accountNickname: Scalars['String'];
  accountNumber: Scalars['String'];
  id: Scalars['String'];
  institutionId: Scalars['String'];
  version: Scalars['Int'];
};

export type EditFuelTaxRateInput = {
  displayName: Scalars['String'];
  fuelTax: Scalars['Money'];
  id: Scalars['String'];
  version: Scalars['Int'];
};

export type EditGeneralLedgerAccountInput = {
  code: Scalars['Int'];
  id: Scalars['String'];
  name: Scalars['String'];
  openingBalance?: InputMaybe<Scalars['Money']>;
  openingDate?: InputMaybe<Scalars['LocalDate']>;
  type: GeneralLedgerAccountTypeEnum;
  version: Scalars['Int'];
};

export type EditGeneralLedgerAccountTemplateInput = {
  code: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  type: GeneralLedgerAccountTypeEnum;
  version: Scalars['Int'];
};

export type EditGstDepositedInput = {
  amount: Scalars['Money'];
  dateDeposited: Scalars['LocalDate'];
  gstFiled: Scalars['String'];
  id: Scalars['String'];
  version: Scalars['Int'];
};

export type EditGstFiledInput = {
  amount: Scalars['Money'];
  confirmationNumber: Scalars['String'];
  filedOnDate: Scalars['LocalDate'];
  filingMethod: FilingMethodEnum;
  fromDate: Scalars['LocalDate'];
  id: Scalars['String'];
  toDate: Scalars['LocalDate'];
  version: Scalars['Int'];
};

export type EditGstHeldInput = {
  amount: Scalars['Money'];
  dateHeld: Scalars['LocalDate'];
  gstFiled: Scalars['String'];
  id: Scalars['String'];
  reason: GstHeldReasonEnum;
  version: Scalars['Int'];
};

export type EditGstPaidInput = {
  amount: Scalars['Money'];
  chequeNumber?: InputMaybe<Scalars['Int']>;
  datePaid: Scalars['LocalDate'];
  filedGsts: Array<Scalars['String']>;
  id: Scalars['String'];
  paymentType: GstPaymentTypeEnum;
  version: Scalars['Int'];
};

export type EditJournalEntryInput = {
  comment?: InputMaybe<Scalars['String']>;
  date: Scalars['LocalDate'];
  documentSource?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  journalEntryLines: Array<JournalEntryLineInput>;
  posted: Scalars['Boolean'];
  type: JournalEntryTypeEnum;
  version: Scalars['Int'];
};

export type EditJournalEntryTemplateInput = {
  accountInfo: Scalars['String'];
  comment?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  endDate?: InputMaybe<Scalars['LocalDate']>;
  id: Scalars['String'];
  journalEntryTemplateLines?: InputMaybe<Array<JournalEntryTemplateLineInput>>;
  nextDueEndOfTheMonth?: InputMaybe<Scalars['Boolean']>;
  nextScheduledDate?: InputMaybe<Scalars['LocalDate']>;
  quantity?: InputMaybe<Scalars['Int']>;
  recurrencePattern: RecurrencePatternEnum;
  version: Scalars['Int'];
};

export type EditLeadInput = {
  details?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  givenName: Scalars['String'];
  id: Scalars['String'];
  mailingAddress?: InputMaybe<EditAddressInput>;
  primaryPhone?: InputMaybe<Scalars['String']>;
  referral?: InputMaybe<ReferralEnum>;
  secondaryPhone?: InputMaybe<Scalars['String']>;
  status: LeadStatusEnum;
  surname?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type EditManagementBonusInput = {
  amount: Scalars['Money'];
  directorId: Scalars['String'];
  id: Scalars['String'];
  taxYear: Scalars['Int'];
  version: Scalars['Int'];
};

export type EditMeta = {
  id: Scalars['String'];
  version: Scalars['Int'];
};

export type EditMinimumWageInput = {
  effectiveDate: Scalars['LocalDate'];
  hourlyRate: Scalars['Money'];
  id: Scalars['String'];
  province: ProvinceEnum;
  version: Scalars['Int'];
};

export type EditOfficeInput = {
  id: Scalars['String'];
  mailingAddress: EditAddressInput;
  name: Scalars['String'];
  organizationId: Scalars['String'];
  physicalAddress: EditAddressInput;
  timezone: Scalars['String'];
  version: Scalars['Int'];
};

export type EditOrganizationInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  payrollFrequency: PayrollFrequencyEnum;
  payrollStartDate: Scalars['LocalDate'];
  version: Scalars['Int'];
};

export type EditPartnerDataInput = {
  contact: EditContactInput;
  createMailingAddress?: InputMaybe<CreateAddressInput>;
  createPhysicalAddress?: InputMaybe<CreateAddressInput>;
  partner: EditPartnerInput;
};

export type EditPartnerInput = {
  id: Scalars['String'];
  ownershipPercentage: Scalars['Int'];
  version: Scalars['Int'];
};

export type EditPayStatementInput = {
  comment?: InputMaybe<Scalars['String']>;
  date: Scalars['LocalDate'];
  id: Scalars['String'];
  linesToAdd?: InputMaybe<Array<CreatePayStatementLineInput>>;
  linesToDelete?: InputMaybe<Array<EditMeta>>;
  linesToEdit?: InputMaybe<Array<EditPayStatementLineInput>>;
  version: Scalars['Int'];
};

export type EditPayStatementLineInput = {
  glAccountId: Scalars['String'];
  gst?: InputMaybe<Scalars['Money']>;
  id?: InputMaybe<Scalars['String']>;
  lineNumber: Scalars['Int'];
  uiTotal: Scalars['Money'];
  version?: InputMaybe<Scalars['Int']>;
};

export type EditPersonalInput = {
  id: Scalars['String'];
  version: Scalars['Int'];
};

export type EditPersonalVehicleInput = {
  color?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  make: Scalars['String'];
  model?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  version: Scalars['Int'];
  year?: InputMaybe<Scalars['Int']>;
};

export type EditPodcastInput = {
  description: Scalars['String'];
  id: Scalars['String'];
  image: Scalars['String'];
  title: Scalars['String'];
  version: Scalars['Int'];
  youtubeId: Scalars['String'];
};

export type EditPreAuthorizedPaymentInput = {
  authorizedLimit: Scalars['Money'];
  bankAccountNumber: Scalars['String'];
  date: Scalars['LocalDate'];
  frequency: FrequencyEnum;
  id?: InputMaybe<Scalars['String']>;
  institutionNumber: Scalars['String'];
  lastGeneration?: InputMaybe<Scalars['DateTime']>;
  transitNumber: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};

export type EditPvlRateInput = {
  after5000Rate: Scalars['Float'];
  effectiveDate?: InputMaybe<Scalars['LocalDate']>;
  first5000Rate: Scalars['Float'];
  id: Scalars['String'];
  province?: InputMaybe<ProvinceEnum>;
  sourceImage?: InputMaybe<Scalars['String']>;
  sourceUrl?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type EditReminderInput = {
  id: Scalars['String'];
  message: Scalars['String'];
  scheduledDeliveryDay: Scalars['LocalDate'];
  sendToId: Scalars['String'];
  version: Scalars['Int'];
};

export type EditSelfEmployedInput = {
  businessNumber?: InputMaybe<Scalars['String']>;
  communicationCode?: InputMaybe<Scalars['String']>;
  gstFilingFrequency?: InputMaybe<GstFilingFrequencyEnum>;
  id: Scalars['String'];
  version: Scalars['Int'];
  yearEnd?: InputMaybe<Scalars['LocalDate']>;
};

export type EditSourceDeductionOpeningBalanceInput = {
  amount: Scalars['Money'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  version: Scalars['Int'];
  year: Scalars['Int'];
};

export type EditSourceDeductionPayableInput = {
  amount: Scalars['Money'];
  date: Scalars['LocalDate'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  period: Scalars['LocalDate'];
  version: Scalars['Int'];
};

export type EditSourceDeductionPaymentInput = {
  chequeNumber?: InputMaybe<Scalars['Int']>;
  date: Scalars['LocalDate'];
  id: Scalars['String'];
  lineItems: Array<SourceDeductionPaymentLineItemInput>;
  paymentType: SourceDeductionPaymentTypeEnum;
  period: Scalars['LocalDate'];
  version: Scalars['Int'];
};

export type EditSourceDeductionStatementBalanceInput = {
  amount: Scalars['Money'];
  id: Scalars['String'];
  period: Scalars['LocalDate'];
  version: Scalars['Int'];
};

export type EditStatementInput = {
  closingBalance: Scalars['Money'];
  document: Scalars['String'];
  financialAccountId: Scalars['String'];
  id: Scalars['String'];
  openingBalance: Scalars['Money'];
  statementLines: Array<EditStatementLinesInput>;
  version: Scalars['Int'];
};

export type EditStatementLinesInput = {
  credit?: InputMaybe<Scalars['Money']>;
  date: Scalars['LocalDate'];
  debit?: InputMaybe<Scalars['Money']>;
  description: Scalars['String'];
  document?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  reconciled: Scalars['Boolean'];
  status?: InputMaybe<StatementStatusEnum>;
  version?: InputMaybe<Scalars['Int']>;
};

export type EditT1Input = {
  confirmationNumber: Scalars['String'];
  filedOn: Scalars['LocalDate'];
  id: Scalars['String'];
  taxYear: Scalars['Int'];
  taxes: Scalars['Money'];
  version: Scalars['Int'];
};

export type EditT2FiledInput = {
  confirmationNumber: Scalars['String'];
  filedOnDate: Scalars['LocalDate'];
  filingMethod: FilingMethodEnum;
  id: Scalars['String'];
  taxAmount: Scalars['Money'];
  taxYear: Scalars['Int'];
  version: Scalars['Int'];
};

export type EditT4Input = {
  confirmationNumber: Scalars['String'];
  filedOn: Scalars['LocalDate'];
  id: Scalars['String'];
  taxYear: Scalars['Int'];
  version: Scalars['Int'];
};

export type EditT5Input = {
  confirmationNumber: Scalars['String'];
  dividendAmount: Scalars['Money'];
  filedOn: Scalars['LocalDate'];
  id: Scalars['String'];
  taxYear: Scalars['Int'];
  version: Scalars['Int'];
};

export type EditTaxRateInput = {
  effectiveDate?: InputMaybe<Scalars['LocalDate']>;
  gstHst?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  province: ProvinceEnum;
  pst?: InputMaybe<Scalars['Float']>;
  version: Scalars['Int'];
};

export type EditTicketCategoryInput = {
  defaultAgentId: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  version: Scalars['Int'];
};

export type EditTimeCardInput = {
  bankExtraHours: Scalars['Boolean'];
  cpp: Scalars['Money'];
  ei: Scalars['Money'];
  id: Scalars['String'];
  incomeTax: Scalars['Money'];
  paid: Scalars['Boolean'];
  payOutVacation: Scalars['Boolean'];
  timeCardLines: Array<EditTimeCardLinesInput>;
  useBankedHours?: InputMaybe<Scalars['Float']>;
  useBankedVacationPay?: InputMaybe<Scalars['Money']>;
  version: Scalars['Int'];
};

export type EditTimeCardLinesInput = {
  afternoonIn: Scalars['Float'];
  afternoonOut: Scalars['Float'];
  date: Scalars['LocalDate'];
  hourlyPay: Scalars['Money'];
  id: Scalars['String'];
  morningIn: Scalars['Float'];
  morningOut: Scalars['Float'];
  publicHolidayPay?: InputMaybe<Scalars['Money']>;
  version: Scalars['Int'];
};

export type EditTreasuryAmountInput = {
  effectiveDate: Scalars['LocalDate'];
  id: Scalars['String'];
  incidentalExpenseAllowance: Scalars['Money'];
  location: LocationEnum;
  mealAllowance: Scalars['Money'];
  version: Scalars['Int'];
};

export type EditUserInput = {
  email: Scalars['String'];
  givenName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<ServiceInput>;
  surname?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type EditVendorInput = {
  archived: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  version: Scalars['Int'];
};

export type Employee = {
  __typename?: 'Employee';
  accountInfo: AccountInfo;
  contact: Contact;
  endDate?: Maybe<Scalars['LocalDate']>;
  homeTerminal: ProvinceEnum;
  id: Scalars['String'];
  paymentInterval: EmployeePaymentIntervalEnum;
  roeConfirmationNumber?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['LocalDate']>;
  version: Scalars['Int'];
};

export type EmployeeFilterInput = {
  status?: InputMaybe<EmployeeStatusFilterEnum>;
};

export enum EmployeePaymentIntervalEnum {
  Biweekly26 = 'Biweekly26',
  Biweekly27 = 'Biweekly27',
  Monthly12 = 'Monthly12',
  SemiMonthly24 = 'SemiMonthly24',
  Weekly52 = 'Weekly52',
  Weekly53 = 'Weekly53'
}

export type EmployeeReport = {
  __typename?: 'EmployeeReport';
  corporateAccounts?: Maybe<Array<AccountInfoWithFees>>;
  id: Scalars['String'];
  name: Scalars['String'];
  personalAccounts?: Maybe<Array<AccountInfoWithFees>>;
  selfEmployedAccounts?: Maybe<Array<AccountInfoWithFees>>;
};

export enum EmployeeStatusFilterEnum {
  All = 'All',
  Employed = 'Employed',
  PreviouslyEmployed = 'PreviouslyEmployed'
}

export type EndEmployeeEmploymentInput = {
  date: Scalars['LocalDate'];
  id: Scalars['String'];
  roeConfirmationNumber?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type ExpectedDocument = {
  __typename?: 'ExpectedDocument';
  account: AccountInfo;
  endDate?: Maybe<Scalars['LocalDate']>;
  frequency: ExpectedDocumentFrequencyEnum;
  id: Scalars['String'];
  quantity: Scalars['Int'];
  startDate: Scalars['LocalDate'];
  type: DocumentType;
  vendor?: Maybe<Vendor>;
  version: Scalars['Int'];
};

export type ExpectedDocumentEvent = {
  __typename?: 'ExpectedDocumentEvent';
  accountInfo: AccountInfo;
  document?: Maybe<Document>;
  endDate?: Maybe<Scalars['LocalDate']>;
  expectedDate: Scalars['LocalDate'];
  expectedDocumentException?: Maybe<ExpectedDocumentException>;
  expectedDocumentId: Scalars['String'];
  frequency: ExpectedDocumentFrequencyEnum;
  id: Scalars['String'];
  quantity: Scalars['Int'];
  startDate: Scalars['LocalDate'];
  type: DocumentType;
  vendor?: Maybe<Vendor>;
  version: Scalars['Int'];
};

export type ExpectedDocumentException = {
  __typename?: 'ExpectedDocumentException';
  account?: Maybe<AccountInfo>;
  date: Scalars['LocalDate'];
  expectedDocument?: Maybe<ExpectedDocument>;
  id: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  type?: Maybe<DocumentType>;
  vendor?: Maybe<Vendor>;
  version: Scalars['Int'];
};

export enum ExpectedDocumentFrequencyEnum {
  Annually = 'annually',
  Monthly = 'monthly',
  Quarterly = 'quarterly'
}

export type Expense = {
  __typename?: 'Expense';
  accountInfo: AccountInfo;
  comment?: Maybe<Scalars['String']>;
  date: Scalars['LocalDate'];
  glAccount: GeneralLedgerAccount;
  gst?: Maybe<Scalars['Money']>;
  id: Scalars['String'];
  journalEntry: JournalEntry;
  percentClaimed: Scalars['Int'];
  total: Scalars['Money'];
  uiTotal: Scalars['Money'];
  version: Scalars['Int'];
};

export type ExpenseFilterInput = {
  endDate: Scalars['LocalDate'];
  showDelete?: InputMaybe<Scalars['Boolean']>;
  startDate: Scalars['LocalDate'];
};

export type ExportArchivesFilterInput = {
  accountInfoId: Scalars['String'];
  endDate: Scalars['LocalDate'];
  startDate: Scalars['LocalDate'];
  userId: Scalars['String'];
};

export type Fee = {
  __typename?: 'Fee';
  date: Scalars['LocalDate'];
  feeLineItems: Array<FeeLineItem>;
  frequency: FrequencyEnum;
  id: Scalars['String'];
  lastGeneration?: Maybe<Scalars['DateTime']>;
  subTotal: Scalars['Money'];
  taxTotal: Scalars['Money'];
  total: Scalars['Money'];
  version: Scalars['Int'];
};

export type FeeLineItem = {
  __typename?: 'FeeLineItem';
  amount: Scalars['Money'];
  description: Scalars['String'];
  fee: Fee;
  gst: Scalars['Money'];
  hst: Scalars['Money'];
  id: Scalars['String'];
  pst: Scalars['Money'];
  qst: Scalars['Money'];
  version: Scalars['Int'];
};

export type FeeLineItemInput = {
  amount: Scalars['Money'];
  description: Scalars['String'];
  gst: Scalars['Money'];
  hst: Scalars['Money'];
  pst: Scalars['Money'];
  qst: Scalars['Money'];
};

export type FeeTemplate = {
  __typename?: 'FeeTemplate';
  date: Scalars['LocalDate'];
  feeTemplateLineItems: Array<FeeLineItem>;
  frequency: FrequencyEnum;
  id?: Maybe<Scalars['String']>;
  subTotal: Scalars['Money'];
  taxTotal: Scalars['Money'];
  total: Scalars['Money'];
  version?: Maybe<Scalars['Int']>;
};

export type FeeTemplateLineItem = {
  __typename?: 'FeeTemplateLineItem';
  amount: Scalars['Money'];
  description: Scalars['String'];
  fee: Fee;
  gst: Scalars['Money'];
  hst: Scalars['Money'];
  id: Scalars['String'];
  pst: Scalars['Money'];
  qst: Scalars['Money'];
  version: Scalars['Int'];
};

export type FeeTemplateLineItemInput = {
  amount: Scalars['Money'];
  description: Scalars['String'];
  gst: Scalars['Money'];
  hst: Scalars['Money'];
  pst: Scalars['Money'];
  qst: Scalars['Money'];
};

export type FileArchive = {
  __typename?: 'FileArchive';
  accountInfo: AccountInfo;
  archivePath?: Maybe<Scalars['String']>;
  creationDate: Scalars['LocalDate'];
  description: Scalars['String'];
  expiryDate: Scalars['LocalDate'];
  id: Scalars['String'];
  status: FileArchiveStatusEnum;
  version: Scalars['Int'];
};

export enum FileArchiveStatusEnum {
  Complete = 'complete',
  Expired = 'expired',
  Failed = 'failed',
  InProgress = 'inProgress'
}

export type FileInfo = {
  __typename?: 'FileInfo';
  accountInfo: AccountInfo;
  deleted: Scalars['Boolean'];
  filepath?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  originalFiles: Array<OriginalFile>;
  receiveMethod: ReceiveMethodEnum;
  receivedOn: Scalars['LocalDate'];
  submittedBy?: Maybe<User>;
  version?: Maybe<Scalars['Int']>;
};

export type FileInfosFilterInput = {
  endDate: Scalars['LocalDate'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  startDate: Scalars['LocalDate'];
};

export enum FilingMethodEnum {
  EFiled = 'eFiled',
  Faxed = 'faxed',
  HandDelivered = 'handDelivered',
  Mailed = 'mailed'
}

export type FinancialAccount = {
  __typename?: 'FinancialAccount';
  accountCurrency: CurrencyCountryEnum;
  accountInfo: AccountInfo;
  accountNickname: Scalars['String'];
  accountNumber: Scalars['String'];
  archived: Scalars['Boolean'];
  glAccount?: Maybe<GeneralLedgerAccount>;
  id: Scalars['String'];
  institution: Vendor;
  type: FinancialAccountTypeEnum;
  version: Scalars['Int'];
};

export type FinancialAccountDetails = {
  __typename?: 'FinancialAccountDetails';
  currentBalance?: Maybe<Scalars['Money']>;
  financialAccount: FinancialAccount;
};

export type FinancialAccountFilterType = {
  archived?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<FinancialAccountTypeEnum>;
};

export type FinancialAccountPreviewDataReturn = {
  __typename?: 'FinancialAccountPreviewDataReturn';
  code: Scalars['Int'];
  name: Scalars['String'];
  type: GeneralLedgerAccountTypeEnum;
};

export enum FinancialAccountTypeEnum {
  BankAccount = 'BankAccount',
  CreditCard = 'CreditCard',
  LongTermLoan = 'LongTermLoan',
  ShortTermLoan = 'ShortTermLoan'
}

export enum FrequencyEnum {
  Annually = 'Annually',
  Monthly = 'Monthly',
  OneTime = 'OneTime',
  Quarterly = 'Quarterly'
}

export type FuelTaxRate = {
  __typename?: 'FuelTaxRate';
  displayName: Scalars['String'];
  fuelTax: Scalars['Money'];
  id: Scalars['String'];
  lastUpdatedOn: Scalars['LocalDate'];
  version: Scalars['Int'];
};

export enum GstFilingFrequencyEnum {
  Annually = 'Annually',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly'
}

export type GeneralLedgerAccount = {
  __typename?: 'GeneralLedgerAccount';
  chartOfAccounts: ChartOfAccounts;
  closedDate?: Maybe<Scalars['LocalDate']>;
  code: Scalars['Int'];
  generalLedgerAccountTemplate?: Maybe<GeneralLedgerAccountTemplate>;
  group: GeneralLedgerAccountGroupEnum;
  id: Scalars['String'];
  name: Scalars['String'];
  openingBalance?: Maybe<Scalars['Money']>;
  openingDate?: Maybe<Scalars['LocalDate']>;
  type: GeneralLedgerAccountTypeEnum;
  version: Scalars['Int'];
};

export enum GeneralLedgerAccountGroupEnum {
  Asset = 'asset',
  Equity = 'equity',
  Expense = 'expense',
  Liability = 'liability',
  Revenue = 'revenue'
}

export type GeneralLedgerAccountTemplate = {
  __typename?: 'GeneralLedgerAccountTemplate';
  chartOfAccountsTemplate?: Maybe<ChartOfAccountsTemplate>;
  code: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  type: GeneralLedgerAccountTypeEnum;
  version: Scalars['Int'];
};

export enum GeneralLedgerAccountTypeEnum {
  Account = 'account',
  Heading = 'heading',
  Subaccount = 'subaccount',
  Subtotal = 'subtotal',
  Total = 'total'
}

export type GeneralLedgerAccountsInUse = {
  __typename?: 'GeneralLedgerAccountsInUse';
  inUse: Array<Scalars['String']>;
  notInUse: Array<Scalars['String']>;
};

export type GeneralLedgerReport = {
  __typename?: 'GeneralLedgerReport';
  glAccount: GeneralLedgerAccount;
  journalEntries?: Maybe<Array<JournalEntry>>;
};

export type GetAccountsPreviewInput = {
  accountInfoId: Scalars['String'];
  accountNickname: Scalars['String'];
  type: FinancialAccountTypeEnum;
};

export type GetAdjacentPerDiemEventsLengthsInput = {
  employeeId: Scalars['String'];
  endDate?: InputMaybe<Scalars['LocalDate']>;
  limit?: InputMaybe<Scalars['Int']>;
  startDate: Scalars['LocalDate'];
};

export type GetAdjacentPerDiemEventsLengthsReturn = {
  __typename?: 'GetAdjacentPerDiemEventsLengthsReturn';
  after: Scalars['Int'];
  before: Scalars['Int'];
};

export type GetAssetPreviewGlDataInput = {
  accountInfoId: Scalars['String'];
  financed: Scalars['Boolean'];
  leased: Scalars['Boolean'];
  name: Scalars['String'];
  type: CorporateAssetTypeEnum;
};

export type GetFilteredTicketsInput = {
  assignedTo?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Array<TicketPriorityEnum>>;
  status: TicketStatusEnum;
};

export type GetPersonalVehiclesFilterInput = {
  archived: Scalars['Boolean'];
};

export type GetPreviewAcPayableJournalEntryLinesInput = {
  accountInfoId: Scalars['String'];
  date: Scalars['LocalDate'];
  payableLines: Array<AcPayableLineInput>;
  paymentGlAccountId?: InputMaybe<Scalars['String']>;
};

export type GetPreviewAcPaymentJournalEntryLinesInput = {
  accountInfoId: Scalars['String'];
  date: Scalars['LocalDate'];
  paymentGlAccountId: Scalars['String'];
  paymentLines: Array<AcPaymentLineInput>;
};

export type GetPreviewAcReceiptJournalEntryLinesInput = {
  accountInfoId: Scalars['String'];
  date: Scalars['LocalDate'];
  paymentGlAccountId: Scalars['String'];
  receiptLines: Array<AcReceiptLineInput>;
};

export type GetPreviewAcReceivableJournalEntryLinesInput = {
  accountInfoId: Scalars['String'];
  date: Scalars['LocalDate'];
  paymentGlAccountId?: InputMaybe<Scalars['String']>;
  receivableLines: Array<AcReceivableLineInput>;
};

export type GetPreviewJournalEntryLinesReturn = {
  __typename?: 'GetPreviewJournalEntryLinesReturn';
  credit: Scalars['Money'];
  debit: Scalars['Money'];
  glAccount: GeneralLedgerAccount;
  lineNumber: Scalars['Int'];
};

export type GrossIncomeLineItem = {
  __typename?: 'GrossIncomeLineItem';
  amount: Scalars['Money'];
  description: Scalars['String'];
  id: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  rates?: Maybe<Scalars['Float']>;
  version: Scalars['Int'];
};

export type GrossIncomeLineItemInput = {
  amount: Scalars['Money'];
  description: Scalars['String'];
  quantity?: InputMaybe<Scalars['Float']>;
  rates?: InputMaybe<Scalars['Float']>;
};

export type GrossIncomeLineItemInputEdit = {
  amount: Scalars['Money'];
  description: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Float']>;
  rates?: InputMaybe<Scalars['Float']>;
};

export type GroupedAccounts = {
  __typename?: 'GroupedAccounts';
  accounts?: Maybe<Array<AccountInfo>>;
  year: Scalars['String'];
};

export type GstDeposited = {
  __typename?: 'GstDeposited';
  accountInfo: AccountInfo;
  amount: Scalars['Money'];
  dateDeposited: Scalars['LocalDate'];
  gstFiled: GstFiled;
  id: Scalars['String'];
  version: Scalars['Int'];
};

export type GstFiled = {
  __typename?: 'GstFiled';
  accountInfo: AccountInfo;
  amount: Scalars['Money'];
  confirmationNumber?: Maybe<Scalars['String']>;
  filedOnDate: Scalars['LocalDate'];
  filingMethod: FilingMethodEnum;
  fromDate: Scalars['LocalDate'];
  gstDeposited?: Maybe<GstDeposited>;
  gstHeld?: Maybe<Array<GstHeld>>;
  gstPaid?: Maybe<Array<GstPaid>>;
  id: Scalars['String'];
  toDate: Scalars['LocalDate'];
  version: Scalars['Int'];
};

export enum GstFiledDropdownTypeEnum {
  GstDeposited = 'gstDeposited',
  GstHeld = 'gstHeld',
  GstPaid = 'gstPaid'
}

export type GstHeld = {
  __typename?: 'GstHeld';
  accountInfo: AccountInfo;
  amount: Scalars['Money'];
  dateHeld: Scalars['LocalDate'];
  gstFiled: GstFiled;
  id: Scalars['String'];
  reason: GstHeldReasonEnum;
  version: Scalars['Int'];
};

export enum GstHeldReasonEnum {
  NonCompliant = 'nonCompliant',
  TransferredToCorporateTaxReturn = 'transferredToCorporateTaxReturn',
  TransferredToSourceDeduction = 'transferredToSourceDeduction',
  Unknown = 'unknown'
}

export type GstPaid = {
  __typename?: 'GstPaid';
  accountInfo: AccountInfo;
  amount: Scalars['Money'];
  chequeNumber?: Maybe<Scalars['Int']>;
  datePaid: Scalars['LocalDate'];
  gstFiled?: Maybe<Array<GstFiled>>;
  id: Scalars['String'];
  paymentType: GstPaymentTypeEnum;
  version: Scalars['Int'];
};

export enum GstPaymentTypeEnum {
  Cheque = 'cheque',
  PreAuthorizedDebit = 'preAuthorizedDebit'
}

export type HistoricalDataEntryInput = {
  accountInfoId: Scalars['String'];
  openingDate: Scalars['LocalDate'];
};

export type Hits = {
  __typename?: 'Hits';
  hits: Array<Maybe<SearchResultItem>>;
  max_score?: Maybe<Scalars['Float']>;
  total: HitsTotal;
};

export type HitsTotal = {
  __typename?: 'HitsTotal';
  relation: Scalars['String'];
  value: Scalars['Int'];
};

export type Image = {
  __typename?: 'Image';
  data: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  timestamp: Scalars['DateTime'];
  type?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type ImageInput = {
  data: Scalars['String'];
  height?: InputMaybe<Scalars['Int']>;
  timestamp: Scalars['DateTime'];
  type?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type IncomeStatement = {
  __typename?: 'IncomeStatement';
  balances: Array<Balance>;
};

export type IncomeStatementFilterInput = {
  endDate: Scalars['LocalDate'];
  startDate: Scalars['LocalDate'];
};

export type Invoice = {
  __typename?: 'Invoice';
  accountInfo: AccountInfo;
  amountOwing: Scalars['Money'];
  date: Scalars['LocalDate'];
  id: Scalars['String'];
  invoiceLineItems: Array<InvoiceLineItems>;
  invoiceNumber: Scalars['Int'];
  invoicePayment?: Maybe<Array<Maybe<InvoicePayment>>>;
  scheduledPayment?: Maybe<ScheduledPayment>;
  total: Scalars['Money'];
  version: Scalars['Int'];
};

export type InvoiceLineItems = {
  __typename?: 'InvoiceLineItems';
  amount: Scalars['Money'];
  description: Scalars['String'];
  gst: Scalars['Money'];
  hst: Scalars['Money'];
  id: Scalars['String'];
  pst: Scalars['Money'];
  qst: Scalars['Money'];
};

export type InvoicePayment = {
  __typename?: 'InvoicePayment';
  amount: Scalars['Money'];
  id: Scalars['String'];
  invoice: Invoice;
  payment: Payment;
  version: Scalars['Int'];
};

export type JournalEntry = {
  __typename?: 'JournalEntry';
  accountInfo: AccountInfo;
  comment?: Maybe<Scalars['String']>;
  correctedBy?: Maybe<JournalEntry>;
  date: Scalars['LocalDate'];
  documentSource?: Maybe<Document>;
  fiscalYear: Scalars['Int'];
  id: Scalars['String'];
  journalEntryLines: Array<JournalEntryLine>;
  journalNumber: Scalars['Int'];
  originalAccountingFormId: Scalars['String'];
  posted: Scalars['Boolean'];
  reversalOf?: Maybe<JournalEntry>;
  reversedBy?: Maybe<JournalEntry>;
  type: JournalEntryTypeEnum;
  version: Scalars['Int'];
};

export type JournalEntryFilterInput = {
  comment?: InputMaybe<Scalars['String']>;
  documentSource?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['LocalDate']>;
  fromAmount?: InputMaybe<Scalars['Money']>;
  glAccounts?: InputMaybe<Array<Scalars['String']>>;
  posted?: InputMaybe<Scalars['Boolean']>;
  showDetailed?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
  toAmount?: InputMaybe<Scalars['Money']>;
  type?: InputMaybe<JournalEntryTypeEnum>;
};

export type JournalEntryLine = {
  __typename?: 'JournalEntryLine';
  credit: Scalars['Money'];
  debit: Scalars['Money'];
  glAccount: GeneralLedgerAccount;
  id: Scalars['String'];
  journalEntry: JournalEntry;
  lineNumber: Scalars['Int'];
  version: Scalars['Int'];
};

export type JournalEntryLineInput = {
  credit: Scalars['Money'];
  debit: Scalars['Money'];
  glAccount: Scalars['String'];
};

export type JournalEntryTemplate = {
  __typename?: 'JournalEntryTemplate';
  accountInfo: AccountInfo;
  comment?: Maybe<Scalars['String']>;
  currentQuantityCount?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  endDate?: Maybe<Scalars['LocalDate']>;
  id: Scalars['String'];
  journalEntryTemplateLines?: Maybe<Array<JournalEntryTemplateLine>>;
  lastScheduledDate?: Maybe<Scalars['LocalDate']>;
  nextDueEndOfTheMonth?: Maybe<Scalars['Boolean']>;
  nextScheduledDate: Scalars['LocalDate'];
  quantity?: Maybe<Scalars['Int']>;
  recurrencePattern: RecurrencePatternEnum;
  version: Scalars['Int'];
};

export type JournalEntryTemplateFilterInput = {
  comment?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  fromAmount?: InputMaybe<Scalars['Money']>;
  toAmount?: InputMaybe<Scalars['Money']>;
};

export type JournalEntryTemplateLine = {
  __typename?: 'JournalEntryTemplateLine';
  credit?: Maybe<Scalars['Money']>;
  debit?: Maybe<Scalars['Money']>;
  glAccount: GeneralLedgerAccount;
  id: Scalars['String'];
  journalEntryTemplate: JournalEntryTemplate;
  lineNumber: Scalars['Int'];
  version: Scalars['Int'];
};

export type JournalEntryTemplateLineInput = {
  credit?: InputMaybe<Scalars['Money']>;
  debit?: InputMaybe<Scalars['Money']>;
  glAccount: Scalars['String'];
  lineNumber?: InputMaybe<Scalars['Int']>;
};

export enum JournalEntryTypeEnum {
  Asset = 'asset',
  Expense = 'expense',
  FiscalYear = 'fiscalYear',
  General = 'general',
  Historical = 'historical',
  PayStatement = 'payStatement',
  Payable = 'payable',
  Payment = 'payment',
  Receipt = 'receipt',
  Receivable = 'receivable'
}

export type Lead = {
  __typename?: 'Lead';
  createdAt: Scalars['LocalDate'];
  createdBy: Agent;
  details?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  givenName: Scalars['String'];
  id: Scalars['String'];
  mailingAddress?: Maybe<Address>;
  organization: Organization;
  primaryPhone?: Maybe<Scalars['String']>;
  referral?: Maybe<ReferralEnum>;
  secondaryPhone?: Maybe<Scalars['String']>;
  status: LeadStatusEnum;
  surname?: Maybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type LeadReport = {
  __typename?: 'LeadReport';
  communicationDate?: Maybe<Scalars['DateTime']>;
  createdBy: Agent;
  details?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  givenName: Scalars['String'];
  id: Scalars['String'];
  primaryPhone?: Maybe<Scalars['String']>;
  source?: Maybe<ReferralEnum>;
  status: LeadStatusEnum;
  surname?: Maybe<Scalars['String']>;
};

export type LeadSource = {
  __typename?: 'LeadSource';
  id: Scalars['Int'];
  label: Scalars['String'];
  value: Scalars['Int'];
};

export enum LeadStatusEnum {
  Converted = 'Converted',
  Disqualified = 'Disqualified',
  FollowUp = 'FollowUp',
  NotInterested = 'NotInterested'
}

export type LikeLeadInput = {
  contacts: Array<LikeLeadParams>;
};

export type LikeLeadParams = {
  email?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  primaryPhone?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
};

export type LinkedGlAccount = {
  __typename?: 'LinkedGlAccount';
  account?: Maybe<GeneralLedgerAccount>;
  type: LinkedGlAccountType;
};

export type LinkedGlAccountEditInput = {
  accountInfoId: Scalars['String'];
  id: Scalars['String'];
  type: LinkedGlAccountType;
};

export enum LinkedGlAccountType {
  CurrentEarnings = 'CurrentEarnings',
  Deposit = 'Deposit',
  GstCollected = 'GstCollected',
  GstPaid = 'GstPaid',
  GstPayable = 'GstPayable',
  Payables = 'Payables',
  Receivables = 'Receivables',
  RetainedEarnings = 'RetainedEarnings'
}

export type Location = {
  __typename?: 'Location';
  key: LocationEnum;
  text: Scalars['String'];
  value: LocationEnum;
};

export enum LocationEnum {
  Ab = 'AB',
  Bc = 'BC',
  Ca = 'CA',
  Mb = 'MB',
  Nb = 'NB',
  Nl = 'NL',
  Ns = 'NS',
  Nt = 'NT',
  Nu = 'NU',
  On = 'ON',
  Pe = 'PE',
  Qc = 'QC',
  Sk = 'SK',
  Us = 'US',
  Yt = 'YT'
}

export type MaintenanceLogEvent = {
  __typename?: 'MaintenanceLogEvent';
  amount: Scalars['Money'];
  corporateAsset: CorporateAsset;
  corporateAssetId: Scalars['String'];
  odometer?: Maybe<Scalars['Int']>;
  serviceDescription: Scalars['String'];
  vendor: Scalars['String'];
};

export type ManagementBonus = {
  __typename?: 'ManagementBonus';
  accountInfo: AccountInfo;
  amount: Scalars['Money'];
  director: Director;
  id: Scalars['String'];
  taxYear: Scalars['Int'];
  version: Scalars['Int'];
};

export type MinimumWage = {
  __typename?: 'MinimumWage';
  effectiveDate: Scalars['LocalDate'];
  hourlyRate: Scalars['Money'];
  id: Scalars['String'];
  province: ProvinceEnum;
  version: Scalars['Int'];
};

export type MissingFilesReport = {
  __typename?: 'MissingFilesReport';
  filePaths?: Maybe<Array<Scalars['String']>>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type MissingStatementItems = {
  __typename?: 'MissingStatementItems';
  expectedDocuments?: Maybe<Array<ExpectedDocumentEvent>>;
  missingStatementItems?: Maybe<Array<StatementLine>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addCommunicationFeedItemNote: CommunicationFeedItem;
  addContract: Contract;
  addContractTemplate?: Maybe<ContractTemplate>;
  addDocument?: Maybe<Document>;
  addDocumentSortingClass: DocumentSortingClass;
  addDraftDocument: DraftDocument;
  addExpectedDocument: ExpectedDocument;
  addExpectedDocumentException?: Maybe<ExpectedDocumentException>;
  addInvoice?: Maybe<Invoice>;
  addPayment: Payment;
  addPodcast: Podcast;
  archiveCorporateAsset?: Maybe<Scalars['String']>;
  archiveFinancialAccountById: Scalars['String'];
  archivePersonalVehicle?: Maybe<Scalars['String']>;
  authorizeContract: Contract;
  authorizeUserOnOrganization?: Maybe<Organization>;
  changeAssignedTo: Scalars['String'];
  changeHourlyPayByAgentId: Scalars['Money'];
  changePriority: Scalars['String'];
  changeStatus: Scalars['String'];
  changeVacationPayRateByAgentId: Scalars['Int'];
  cloneAccount: AccountInfo;
  closeAcFiscalYear: AcFiscalYear;
  copyPayStub?: Maybe<PayStub>;
  createAcPayable: AcPayable;
  createAcPayableTemplate: AcPayableTemplate;
  createAcPayment: AcPayment;
  createAcReceipt: AcReceipt;
  createAcReceivable: AcReceivable;
  createAcReceivableTemplate: AcReceivableTemplate;
  createAccommodationAmount?: Maybe<AccommodationAmount>;
  createAccounts?: Maybe<Array<AccountInfo>>;
  createAnnouncement: Announcement;
  createAuthorizedRepresentativeAndContact?: Maybe<AuthorizedRepresentative>;
  createCaftRelease: CaftRelease;
  createCorporateAsset?: Maybe<CorporateAsset>;
  createCorporateTaxesPaid?: Maybe<CorporateTaxesPaid>;
  createDirector: Director;
  createDocumentType?: Maybe<DocumentType>;
  createDomesticReductionRate?: Maybe<DomesticReductionRate>;
  createEIRate?: Maybe<EiRate>;
  createEmployeeAndContact?: Maybe<Employee>;
  createExpense?: Maybe<Expense>;
  createFinancialAccount: FinancialAccount;
  createFuelTaxRate?: Maybe<FuelTaxRate>;
  createGeneralLedgerAccountTemplate?: Maybe<GeneralLedgerAccountTemplate>;
  createGstDeposited?: Maybe<GstDeposited>;
  createGstFiled?: Maybe<GstFiled>;
  createGstHeld?: Maybe<GstHeld>;
  createGstPaid?: Maybe<GstPaid>;
  createJournalEntry: JournalEntry;
  createJournalEntryTemplate: JournalEntryTemplate;
  createLead?: Maybe<Lead>;
  createMaintenanceLogEvent?: Maybe<CalendarEvent>;
  createManagementBonus?: Maybe<ManagementBonus>;
  createMinimumWage: MinimumWage;
  createOffice?: Maybe<Office>;
  createOrganization: Organization;
  createPartner: Partner;
  createPayStatement?: Maybe<PayStatement>;
  createPayStub?: Maybe<PayStub>;
  createPerDiemEvent?: Maybe<Array<Maybe<CalendarEvent>>>;
  createPersonalVehicle?: Maybe<PersonalVehicle>;
  createPvlEvent?: Maybe<CalendarEvent>;
  createPvlRate?: Maybe<PvlRate>;
  createReminder: Reminder;
  createSourceDeductionOpeningBalance?: Maybe<SourceDeductionOpeningBalance>;
  createSourceDeductionPayable?: Maybe<SourceDeductionPayable>;
  createSourceDeductionPayment?: Maybe<SourceDeductionPayment>;
  createSourceDeductionStatementBalance?: Maybe<SourceDeductionStatementBalance>;
  createStatement?: Maybe<Statement>;
  createT1?: Maybe<T1>;
  createT2Filed?: Maybe<T2Filed>;
  createT4?: Maybe<T4>;
  createT5?: Maybe<T5>;
  createTaxRate?: Maybe<TaxRate>;
  createTicket: Ticket;
  createTicketCategory: Scalars['String'];
  createTicketReply: TicketReply;
  createTimeCard: TimeCard;
  createTreasuryAmount?: Maybe<TreasuryAmount>;
  createVendor?: Maybe<Vendor>;
  cropFile?: Maybe<FileInfo>;
  deactivateAgentById: Scalars['String'];
  deleteAcPayableById: Scalars['String'];
  deleteAcPayableTemplateById: Scalars['String'];
  deleteAcPaymentById: Scalars['String'];
  deleteAcReceiptById: Scalars['String'];
  deleteAcReceivableById: Scalars['String'];
  deleteAcReceivableTemplateById: Scalars['String'];
  deleteAccommodationAmount?: Maybe<AccommodationAmount>;
  deleteAccountCascade?: Maybe<Scalars['String']>;
  deleteAccountingDataCascade?: Maybe<Scalars['String']>;
  deleteAccountingImportJob: Scalars['String'];
  deleteAllProcessorErrorItems?: Maybe<Array<ProcessorError>>;
  deleteCalendarEvent?: Maybe<Scalars['String']>;
  deleteCommunicationFeedItem?: Maybe<CommunicationFeedItem>;
  deleteContract: Contract;
  deleteContractTemplateById?: Maybe<ContractTemplate>;
  deleteCorporateTaxesPaid: Scalars['String'];
  deleteDocumentById?: Maybe<Document>;
  deleteDocumentTypeById?: Maybe<Scalars['String']>;
  deleteDomesticReductionRate?: Maybe<DomesticReductionRate>;
  deleteDraftDocumentById: Scalars['String'];
  deleteEIRate?: Maybe<EiRate>;
  deleteExpectedDocument?: Maybe<Scalars['String']>;
  deleteExpectedDocumentException?: Maybe<Scalars['String']>;
  deleteExpense?: Maybe<Scalars['String']>;
  deleteFileByPath?: Maybe<Scalars['String']>;
  deleteFileInfoById?: Maybe<FileInfo>;
  deleteFuelTaxRate?: Maybe<FuelTaxRate>;
  deleteGeneralLedgerAccountTemplateById?: Maybe<Scalars['String']>;
  deleteGstDeposited: Scalars['String'];
  deleteGstFiled: Scalars['String'];
  deleteGstHeld: Scalars['String'];
  deleteGstPaid: Scalars['String'];
  deleteImage?: Maybe<Scalars['Boolean']>;
  deleteIndex?: Maybe<Scalars['Boolean']>;
  deleteJournalEntry: Scalars['String'];
  deleteJournalEntryTemplateById: Scalars['String'];
  deleteLeadCascade?: Maybe<Scalars['String']>;
  deleteManagementBonus: Scalars['String'];
  deleteMinimumWageById: Scalars['String'];
  deletePayStatement?: Maybe<Scalars['String']>;
  deletePayStub?: Maybe<Scalars['String']>;
  deletePodcastById: Podcast;
  deleteProcessorErrorById?: Maybe<ProcessorError>;
  deleteProcessorQueueItem?: Maybe<Scalars['Boolean']>;
  deletePvlRate?: Maybe<PvlRate>;
  deleteSourceDeductionOpeningBalance?: Maybe<Scalars['String']>;
  deleteSourceDeductionPayable?: Maybe<Scalars['String']>;
  deleteSourceDeductionPayment?: Maybe<Scalars['String']>;
  deleteSourceDeductionStatementBalance?: Maybe<Scalars['String']>;
  deleteStatement?: Maybe<Scalars['String']>;
  deleteT1: Scalars['String'];
  deleteT2Filed: Scalars['String'];
  deleteT4?: Maybe<Scalars['String']>;
  deleteT5: Scalars['String'];
  deleteTaxRate?: Maybe<TaxRate>;
  deleteTimeCardById: TimeCard;
  deleteTreasuryAmount?: Maybe<TreasuryAmount>;
  deleteUserById?: Maybe<User>;
  deleteVendorById?: Maybe<Scalars['String']>;
  dismissAccountNotice: AccountNotice;
  dismissAllAccountNotices?: Maybe<Array<AccountNotice>>;
  editAcPayable: AcPayable;
  editAcPayableTemplate: AcPayableTemplate;
  editAcPayment: AcPayment;
  editAcReceipt: AcReceipt;
  editAcReceivable: AcReceivable;
  editAcReceivableTemplate: AcReceivableTemplate;
  editAccommodationAmount?: Maybe<AccommodationAmount>;
  editAccountInfoAddress?: Maybe<AccountInfo>;
  editAccountInfoDefaultAgent?: Maybe<AccountInfo>;
  editAccountInfoManager?: Maybe<AccountInfo>;
  editAccountInfoName?: Maybe<AccountInfo>;
  editAccountInfoOffice?: Maybe<AccountInfo>;
  editAccountInfoOrganizationAndOffice?: Maybe<AccountInfo>;
  editAccountInfoStatus?: Maybe<AccountInfo>;
  editAgent?: Maybe<Agent>;
  editAlertSettings: AlertSettings;
  editBusinessNumber?: Maybe<AccountInfo>;
  editChartOfAccountsTemplate?: Maybe<ChartOfAccountsTemplate>;
  editContact?: Maybe<Contact>;
  editContract: Contract;
  editContractTemplate?: Maybe<ContractTemplate>;
  editCorporateAsset?: Maybe<CorporateAsset>;
  editCorporateTaxesPaid?: Maybe<CorporateTaxesPaid>;
  editDirector: Director;
  editDocumentType?: Maybe<DocumentType>;
  editDomesticReductionRate?: Maybe<DomesticReductionRate>;
  editEIRate?: Maybe<EiRate>;
  editEmployeeAndContact?: Maybe<Employee>;
  editExpectedDocument?: Maybe<ExpectedDocument>;
  editExpectedDocumentException?: Maybe<ExpectedDocumentException>;
  editExpense?: Maybe<Expense>;
  editFinancialAccount: FinancialAccount;
  editFuelTaxRate?: Maybe<FuelTaxRate>;
  editGSTFilingFrequency?: Maybe<AccountInfo>;
  editGeneralLedgerAccount?: Maybe<GeneralLedgerAccount>;
  editGeneralLedgerAccountTemplate?: Maybe<GeneralLedgerAccountTemplate>;
  editGstDeposited?: Maybe<GstDeposited>;
  editGstFiled?: Maybe<GstFiled>;
  editGstHeld?: Maybe<GstHeld>;
  editGstPaid?: Maybe<GstPaid>;
  editIncorporationDate?: Maybe<AccountInfo>;
  editJournalEntry: JournalEntry;
  editJournalEntryTemplate: JournalEntryTemplate;
  editLead?: Maybe<Lead>;
  editLinkedGlAccount?: Maybe<ChartOfAccounts>;
  editManagementBonus?: Maybe<ManagementBonus>;
  editMinimumWage: MinimumWage;
  editMultipleAlertSettings?: Maybe<Array<AlertSettings>>;
  editOffice?: Maybe<Office>;
  editOrganization: Organization;
  editPartner: Partner;
  editPayStatement?: Maybe<PayStatement>;
  editPayStub?: Maybe<PayStub>;
  editPersonalVehicle?: Maybe<PersonalVehicle>;
  editPodcast: Podcast;
  editPvlRate?: Maybe<PvlRate>;
  editReminder: Reminder;
  editSelfEmployedAccount?: Maybe<SelfEmployedAccount>;
  editSourceDeductionOpeningBalance?: Maybe<SourceDeductionOpeningBalance>;
  editSourceDeductionPayable?: Maybe<SourceDeductionPayable>;
  editSourceDeductionPayment?: Maybe<SourceDeductionPayment>;
  editSourceDeductionStatementBalance?: Maybe<SourceDeductionStatementBalance>;
  editStatement?: Maybe<Statement>;
  editT1?: Maybe<T1>;
  editT2Filed?: Maybe<T2Filed>;
  editT4?: Maybe<T4>;
  editT5?: Maybe<T5>;
  editTaxRate?: Maybe<TaxRate>;
  editTicketCategory: Scalars['String'];
  editTimeCard: TimeCard;
  editTreasuryAmount?: Maybe<TreasuryAmount>;
  editUser?: Maybe<User>;
  editVendor?: Maybe<Vendor>;
  editYearEnd?: Maybe<AccountInfo>;
  endContract: Contract;
  endEmployeeEmployment?: Maybe<Employee>;
  finalizeDraftDocumentSourceById?: Maybe<DraftDocumentSource>;
  finishHistoricalDataEntry: ChartOfAccounts;
  generateSchedPayment: Scalars['Boolean'];
  importAccountingImportJob: AccountingImportJob;
  markAllNotificationsReadByUserId?: Maybe<Array<Notification>>;
  markNotificationReadById: Notification;
  markPaymentNSF: Array<Payment>;
  markPaymentReversed: Payment;
  markSourceDeductionPaymentNSF?: Maybe<SourceDeductionPayment>;
  markTimeCardAsPaid: TimeCard;
  markTimeCardAsUnpaid: TimeCard;
  mergeVendors?: Maybe<Vendor>;
  moveFileInfoToAccountInfoId: FileInfo;
  payPerDiem?: Maybe<Array<Maybe<CalendarEvent>>>;
  payPvl?: Maybe<Array<Maybe<CalendarEvent>>>;
  pinCommunicationFeedItem: Scalars['Boolean'];
  postHistoricalTransactions: AccountingImportPostHistoricalResult;
  reemployEmployee?: Maybe<Employee>;
  releaseCaft: CaftRelease;
  removeAuthorizedRepresentative?: Maybe<Scalars['String']>;
  removeDirector: Scalars['String'];
  removePartner: Scalars['String'];
  reversePerDiemPayment?: Maybe<Array<Maybe<CalendarEvent>>>;
  reversePvlPayment?: Maybe<Array<Maybe<CalendarEvent>>>;
  root?: Maybe<Scalars['String']>;
  runIndexer?: Maybe<Scalars['Boolean']>;
  saveAccountingImportJob: AccountingImportJob;
  saveOpeningBalances?: Maybe<Array<Scalars['String']>>;
  sendAgentInvitation: Scalars['Boolean'];
  sendContactInvitation: Scalars['Boolean'];
  sendPasswordResetEmail: Scalars['Boolean'];
  setAccountRatingByAccountInfoId: AccountRating;
  setActiveAgent?: Maybe<User>;
  setCaftOptions: Scalars['Boolean'];
  sortFileInfo?: Maybe<FileInfo>;
  spacesCleanupCleanAll?: Maybe<Array<SpacesCleanup>>;
  spacesCleanupCleanExpired?: Maybe<Array<SpacesCleanup>>;
  updateCommunicationFeedItem: CommunicationFeedItem;
  updateDocument?: Maybe<Document>;
  updateDocumentSortingClass: DocumentSortingClass;
  updateDraftDocument: DraftDocument;
  updateMaintenanceLogEvent?: Maybe<CalendarEvent>;
  updatePerDiemEvent?: Maybe<CalendarEvent>;
  updatePvlEvent?: Maybe<CalendarEvent>;
};


export type MutationAddCommunicationFeedItemNoteArgs = {
  data: AddCommunicationFeedItemNoteInput;
};


export type MutationAddContractArgs = {
  data: AddContractInput;
};


export type MutationAddContractTemplateArgs = {
  data: AddContractTemplateInput;
};


export type MutationAddDocumentArgs = {
  data: AddDocumentInput;
};


export type MutationAddDocumentSortingClassArgs = {
  data: AddDocumentSortingClassInput;
};


export type MutationAddDraftDocumentArgs = {
  data: AddDraftDocumentInput;
};


export type MutationAddExpectedDocumentArgs = {
  data: AddExpectedDocumentInput;
};


export type MutationAddExpectedDocumentExceptionArgs = {
  data: AddExpectedDocumentExceptionInput;
};


export type MutationAddInvoiceArgs = {
  data: AddInvoiceInput;
};


export type MutationAddPaymentArgs = {
  data: AddPaymentInput;
};


export type MutationAddPodcastArgs = {
  data: AddPodcastInput;
};


export type MutationArchiveCorporateAssetArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationArchiveFinancialAccountByIdArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationArchivePersonalVehicleArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationAuthorizeContractArgs = {
  contractId: Scalars['String'];
};


export type MutationAuthorizeUserOnOrganizationArgs = {
  organizationId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationChangeAssignedToArgs = {
  data: ChangeAssignedToInput;
};


export type MutationChangeHourlyPayByAgentIdArgs = {
  agentId: Scalars['String'];
  hourlyPay: Scalars['Money'];
  startingDate: Scalars['LocalDate'];
};


export type MutationChangePriorityArgs = {
  data: ChangePriorityInput;
};


export type MutationChangeStatusArgs = {
  data: ChangeStatusInput;
};


export type MutationChangeVacationPayRateByAgentIdArgs = {
  agentId: Scalars['String'];
  startingDate: Scalars['LocalDate'];
  vacationPayRate: Scalars['Int'];
};


export type MutationCloneAccountArgs = {
  accountInfoId: Scalars['String'];
};


export type MutationCloseAcFiscalYearArgs = {
  accountInfoId: Scalars['String'];
};


export type MutationCopyPayStubArgs = {
  payStubId: Scalars['String'];
};


export type MutationCreateAcPayableArgs = {
  data: CreateAcPayableInput;
};


export type MutationCreateAcPayableTemplateArgs = {
  data: CreateAcPayableTemplateInput;
};


export type MutationCreateAcPaymentArgs = {
  data: CreateAcPaymentInput;
};


export type MutationCreateAcReceiptArgs = {
  data: CreateAcReceiptInput;
};


export type MutationCreateAcReceivableArgs = {
  data: CreateAcReceivableInput;
};


export type MutationCreateAcReceivableTemplateArgs = {
  data: CreateAcReceivableTemplateInput;
};


export type MutationCreateAccommodationAmountArgs = {
  data: CreateAccommodationAmountInput;
};


export type MutationCreateAccountsArgs = {
  data: CreateAccountsInput;
};


export type MutationCreateAnnouncementArgs = {
  data: CreateAnnouncementInput;
};


export type MutationCreateAuthorizedRepresentativeAndContactArgs = {
  data: CreateAuthorizedRepresentativeAndContactInput;
};


export type MutationCreateCaftReleaseArgs = {
  dueDate: Scalars['DateTime'];
  now?: InputMaybe<Scalars['DateTime']>;
};


export type MutationCreateCorporateAssetArgs = {
  data: CreateCorporateAssetInput;
};


export type MutationCreateCorporateTaxesPaidArgs = {
  data: CreateCorporateTaxesPaidInput;
};


export type MutationCreateDirectorArgs = {
  data: CreateDirectorDataInput;
};


export type MutationCreateDocumentTypeArgs = {
  data: CreateDocumentTypeInput;
};


export type MutationCreateDomesticReductionRateArgs = {
  data: CreateDomesticReductionRateInput;
};


export type MutationCreateEiRateArgs = {
  data: CreateEiRateInput;
};


export type MutationCreateEmployeeAndContactArgs = {
  data: CreateEmployeeAndContactInput;
};


export type MutationCreateExpenseArgs = {
  data: CreateExpenseInput;
};


export type MutationCreateFinancialAccountArgs = {
  data: CreateFinancialAccountInput;
};


export type MutationCreateFuelTaxRateArgs = {
  data: CreateFuelTaxRateInput;
};


export type MutationCreateGeneralLedgerAccountTemplateArgs = {
  data: CreateGeneralLedgerAccountTemplateInput;
};


export type MutationCreateGstDepositedArgs = {
  data: CreateGstDepositedInput;
};


export type MutationCreateGstFiledArgs = {
  data: CreateGstFiledInput;
};


export type MutationCreateGstHeldArgs = {
  data: CreateGstHeldInput;
};


export type MutationCreateGstPaidArgs = {
  data: CreateGstPaidInput;
};


export type MutationCreateJournalEntryArgs = {
  data: CreateJournalEntryInput;
};


export type MutationCreateJournalEntryTemplateArgs = {
  data: CreateJournalEntryTemplateInput;
};


export type MutationCreateLeadArgs = {
  data: CreateLeadInput;
};


export type MutationCreateMaintenanceLogEventArgs = {
  accountInfoId: Scalars['String'];
  data: CreateMaintenanceLogEventDataInput;
  event: CreateCalendarEventInput;
};


export type MutationCreateManagementBonusArgs = {
  data: CreateManagementBonusInput;
};


export type MutationCreateMinimumWageArgs = {
  data: CreateMinimumWageInput;
};


export type MutationCreateOfficeArgs = {
  data: CreateOfficeInput;
};


export type MutationCreateOrganizationArgs = {
  data: CreateOrganizationInput;
};


export type MutationCreatePartnerArgs = {
  data: CreatePartnerDataInput;
};


export type MutationCreatePayStatementArgs = {
  data: CreatePayStatementInput;
};


export type MutationCreatePayStubArgs = {
  data: PayStubAddInput;
};


export type MutationCreatePerDiemEventArgs = {
  days: Array<CreatePerDiemEventsInput>;
  employeeId: Scalars['String'];
};


export type MutationCreatePersonalVehicleArgs = {
  data: CreatePersonalVehicleInput;
};


export type MutationCreatePvlEventArgs = {
  accountInfoId: Scalars['String'];
  data: CreatePvlEventDataInput;
  event: CreateCalendarEventInput;
};


export type MutationCreatePvlRateArgs = {
  data: CreatePvlRateInput;
};


export type MutationCreateReminderArgs = {
  data: CreateReminderInput;
};


export type MutationCreateSourceDeductionOpeningBalanceArgs = {
  data: CreateSourceDeductionOpeningBalanceInput;
};


export type MutationCreateSourceDeductionPayableArgs = {
  data: CreateSourceDeductionPayableInput;
};


export type MutationCreateSourceDeductionPaymentArgs = {
  data: CreateSourceDeductionPaymentInput;
};


export type MutationCreateSourceDeductionStatementBalanceArgs = {
  data: CreateSourceDeductionStatementBalanceInput;
};


export type MutationCreateStatementArgs = {
  data: CreateStatementInput;
};


export type MutationCreateT1Args = {
  data: CreateT1Input;
};


export type MutationCreateT2FiledArgs = {
  data: CreateT2FiledInput;
};


export type MutationCreateT4Args = {
  data: CreateT4Input;
};


export type MutationCreateT5Args = {
  data: CreateT5Input;
};


export type MutationCreateTaxRateArgs = {
  data: CreateTaxRateInput;
};


export type MutationCreateTicketArgs = {
  data: CreateTicketInput;
};


export type MutationCreateTicketCategoryArgs = {
  data: CreateTicketCategoryInput;
};


export type MutationCreateTicketReplyArgs = {
  data: CreateTicketReplyInput;
};


export type MutationCreateTimeCardArgs = {
  agentId: Scalars['String'];
};


export type MutationCreateTreasuryAmountArgs = {
  data: CreateTreasuryAmountInput;
};


export type MutationCreateVendorArgs = {
  data: CreateVendorInput;
};


export type MutationCropFileArgs = {
  data: Crop;
};


export type MutationDeactivateAgentByIdArgs = {
  id: Scalars['String'];
};


export type MutationDeleteAcPayableByIdArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteAcPayableTemplateByIdArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteAcPaymentByIdArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteAcReceiptByIdArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteAcReceivableByIdArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteAcReceivableTemplateByIdArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteAccommodationAmountArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteAccountCascadeArgs = {
  accountInfoId: Scalars['String'];
};


export type MutationDeleteAccountingDataCascadeArgs = {
  accountInfoId: Scalars['String'];
  deleteHistoricEntries: Scalars['Boolean'];
};


export type MutationDeleteAccountingImportJobArgs = {
  jobId: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteCalendarEventArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteCommunicationFeedItemArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteContractArgs = {
  contractId: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteContractTemplateByIdArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteCorporateTaxesPaidArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteDocumentByIdArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteDocumentTypeByIdArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteDomesticReductionRateArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteDraftDocumentByIdArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteEiRateArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteExpectedDocumentArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteExpectedDocumentExceptionArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteExpenseArgs = {
  data: DeleteExpenseInput;
};


export type MutationDeleteFileByPathArgs = {
  path: Scalars['String'];
};


export type MutationDeleteFileInfoByIdArgs = {
  id: Scalars['String'];
};


export type MutationDeleteFuelTaxRateArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteGeneralLedgerAccountTemplateByIdArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteGstDepositedArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteGstFiledArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteGstHeldArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteGstPaidArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteImageArgs = {
  filePath: Scalars['String'];
};


export type MutationDeleteJournalEntryArgs = {
  id: Scalars['String'];
};


export type MutationDeleteJournalEntryTemplateByIdArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteLeadCascadeArgs = {
  leadId: Scalars['String'];
};


export type MutationDeleteManagementBonusArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteMinimumWageByIdArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeletePayStatementArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeletePayStubArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeletePodcastByIdArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteProcessorErrorByIdArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteProcessorQueueItemArgs = {
  id: Scalars['String'];
};


export type MutationDeletePvlRateArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteSourceDeductionOpeningBalanceArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteSourceDeductionPayableArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteSourceDeductionPaymentArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteSourceDeductionStatementBalanceArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteStatementArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteT1Args = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteT2FiledArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteT4Args = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteT5Args = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteTaxRateArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteTimeCardByIdArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteTreasuryAmountArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteUserByIdArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDeleteVendorByIdArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationDismissAccountNoticeArgs = {
  accountNoticeId: Scalars['String'];
};


export type MutationDismissAllAccountNoticesArgs = {
  accountInfoId: Scalars['String'];
};


export type MutationEditAcPayableArgs = {
  data: EditAcPayableInput;
};


export type MutationEditAcPayableTemplateArgs = {
  data: EditAcPayableTemplateInput;
};


export type MutationEditAcPaymentArgs = {
  data: EditAcPaymentInput;
};


export type MutationEditAcReceiptArgs = {
  data: EditAcReceiptInput;
};


export type MutationEditAcReceivableArgs = {
  data: EditAcReceivableInput;
};


export type MutationEditAcReceivableTemplateArgs = {
  data: EditAcReceivableTemplateInput;
};


export type MutationEditAccommodationAmountArgs = {
  data: EditAccommodationAmountInput;
};


export type MutationEditAccountInfoAddressArgs = {
  accountInfoId: Scalars['String'];
  data: EditAddressInput;
};


export type MutationEditAccountInfoDefaultAgentArgs = {
  accountInfoId: Scalars['String'];
  defaultAgentId: Scalars['String'];
};


export type MutationEditAccountInfoManagerArgs = {
  accountInfoId: Scalars['String'];
  managerId: Scalars['String'];
};


export type MutationEditAccountInfoNameArgs = {
  accountInfoId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationEditAccountInfoOfficeArgs = {
  accountInfoId: Scalars['String'];
  officeId: Scalars['String'];
};


export type MutationEditAccountInfoOrganizationAndOfficeArgs = {
  accountInfoId: Scalars['String'];
  officeId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationEditAccountInfoStatusArgs = {
  accountInfoId: Scalars['String'];
  archived: Scalars['Boolean'];
};


export type MutationEditAgentArgs = {
  data: EditAgentInput;
};


export type MutationEditAlertSettingsArgs = {
  data: EditAlertSettingsInput;
};


export type MutationEditBusinessNumberArgs = {
  accountInfoId: Scalars['String'];
  businessNumber: Scalars['String'];
};


export type MutationEditChartOfAccountsTemplateArgs = {
  data: ChartOfAccountsTemplateEditInput;
};


export type MutationEditContactArgs = {
  data: EditContactDataInput;
};


export type MutationEditContractArgs = {
  data: EditContractInput;
};


export type MutationEditContractTemplateArgs = {
  data: EditContractTemplateInput;
};


export type MutationEditCorporateAssetArgs = {
  data: EditCorporateAssetInput;
};


export type MutationEditCorporateTaxesPaidArgs = {
  data: EditCorporateTaxesPaidInput;
};


export type MutationEditDirectorArgs = {
  data: EditDirectorDataInput;
};


export type MutationEditDocumentTypeArgs = {
  data: EditDocumentTypeInput;
};


export type MutationEditDomesticReductionRateArgs = {
  data: EditDomesticReductionRateInput;
};


export type MutationEditEiRateArgs = {
  data: EditEiRateInput;
};


export type MutationEditEmployeeAndContactArgs = {
  data: EditEmployeeAndContactInput;
};


export type MutationEditExpectedDocumentArgs = {
  data: EditExpectedDocumentInput;
};


export type MutationEditExpectedDocumentExceptionArgs = {
  data: EditExpectedDocumentExceptionInput;
};


export type MutationEditExpenseArgs = {
  data: EditExpenseInput;
};


export type MutationEditFinancialAccountArgs = {
  data: EditFinancialAccountInput;
};


export type MutationEditFuelTaxRateArgs = {
  data: EditFuelTaxRateInput;
};


export type MutationEditGstFilingFrequencyArgs = {
  accountInfoId: Scalars['String'];
  gstFilingFrequency: GstFilingFrequencyEnum;
};


export type MutationEditGeneralLedgerAccountArgs = {
  data: EditGeneralLedgerAccountInput;
};


export type MutationEditGeneralLedgerAccountTemplateArgs = {
  data: EditGeneralLedgerAccountTemplateInput;
};


export type MutationEditGstDepositedArgs = {
  data: EditGstDepositedInput;
};


export type MutationEditGstFiledArgs = {
  data: EditGstFiledInput;
};


export type MutationEditGstHeldArgs = {
  data: EditGstHeldInput;
};


export type MutationEditGstPaidArgs = {
  data: EditGstPaidInput;
};


export type MutationEditIncorporationDateArgs = {
  accountInfoId: Scalars['String'];
  incorporationDate: Scalars['LocalDate'];
};


export type MutationEditJournalEntryArgs = {
  data: EditJournalEntryInput;
};


export type MutationEditJournalEntryTemplateArgs = {
  data: EditJournalEntryTemplateInput;
};


export type MutationEditLeadArgs = {
  data: EditLeadInput;
};


export type MutationEditLinkedGlAccountArgs = {
  data: LinkedGlAccountEditInput;
};


export type MutationEditManagementBonusArgs = {
  data: EditManagementBonusInput;
};


export type MutationEditMinimumWageArgs = {
  data: EditMinimumWageInput;
};


export type MutationEditMultipleAlertSettingsArgs = {
  data: Array<EditAlertSettingsInput>;
};


export type MutationEditOfficeArgs = {
  data: EditOfficeInput;
};


export type MutationEditOrganizationArgs = {
  data: EditOrganizationInput;
};


export type MutationEditPartnerArgs = {
  data: EditPartnerDataInput;
};


export type MutationEditPayStatementArgs = {
  data: EditPayStatementInput;
};


export type MutationEditPayStubArgs = {
  data: PayStubEditInput;
};


export type MutationEditPersonalVehicleArgs = {
  data: EditPersonalVehicleInput;
};


export type MutationEditPodcastArgs = {
  data: EditPodcastInput;
};


export type MutationEditPvlRateArgs = {
  data: EditPvlRateInput;
};


export type MutationEditReminderArgs = {
  data: EditReminderInput;
};


export type MutationEditSelfEmployedAccountArgs = {
  data: EditSelfEmployedInput;
};


export type MutationEditSourceDeductionOpeningBalanceArgs = {
  data: EditSourceDeductionOpeningBalanceInput;
};


export type MutationEditSourceDeductionPayableArgs = {
  data: EditSourceDeductionPayableInput;
};


export type MutationEditSourceDeductionPaymentArgs = {
  data: EditSourceDeductionPaymentInput;
};


export type MutationEditSourceDeductionStatementBalanceArgs = {
  data: EditSourceDeductionStatementBalanceInput;
};


export type MutationEditStatementArgs = {
  data: EditStatementInput;
};


export type MutationEditT1Args = {
  data: EditT1Input;
};


export type MutationEditT2FiledArgs = {
  data: EditT2FiledInput;
};


export type MutationEditT4Args = {
  data: EditT4Input;
};


export type MutationEditT5Args = {
  data: EditT5Input;
};


export type MutationEditTaxRateArgs = {
  data: EditTaxRateInput;
};


export type MutationEditTicketCategoryArgs = {
  data: EditTicketCategoryInput;
};


export type MutationEditTimeCardArgs = {
  data: EditTimeCardInput;
};


export type MutationEditTreasuryAmountArgs = {
  data: EditTreasuryAmountInput;
};


export type MutationEditUserArgs = {
  data: EditUserInput;
};


export type MutationEditVendorArgs = {
  data: EditVendorInput;
};


export type MutationEditYearEndArgs = {
  accountInfoId: Scalars['String'];
  yearEnd: Scalars['LocalDate'];
};


export type MutationEndContractArgs = {
  contractId: Scalars['String'];
};


export type MutationEndEmployeeEmploymentArgs = {
  data: EndEmployeeEmploymentInput;
};


export type MutationFinalizeDraftDocumentSourceByIdArgs = {
  id: Scalars['String'];
};


export type MutationFinishHistoricalDataEntryArgs = {
  data: HistoricalDataEntryInput;
};


export type MutationGenerateSchedPaymentArgs = {
  now?: InputMaybe<Scalars['DateTime']>;
};


export type MutationImportAccountingImportJobArgs = {
  data: Array<AccountingImportDestGlInput>;
  jobId: Scalars['String'];
};


export type MutationMarkAllNotificationsReadByUserIdArgs = {
  id: Scalars['String'];
};


export type MutationMarkNotificationReadByIdArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationMarkPaymentNsfArgs = {
  now?: InputMaybe<Scalars['DateTime']>;
  paymentId: Scalars['String'];
};


export type MutationMarkPaymentReversedArgs = {
  paymentId: Scalars['String'];
};


export type MutationMarkSourceDeductionPaymentNsfArgs = {
  date: Scalars['LocalDate'];
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationMarkTimeCardAsPaidArgs = {
  timeCardId: Scalars['String'];
};


export type MutationMarkTimeCardAsUnpaidArgs = {
  timeCardId: Scalars['String'];
};


export type MutationMergeVendorsArgs = {
  vendor1: Scalars['String'];
  vendor2: Scalars['String'];
};


export type MutationMoveFileInfoToAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
  fileInfoId: Scalars['String'];
};


export type MutationPayPerDiemArgs = {
  date: Scalars['LocalDate'];
  employeeId: Scalars['String'];
  filter: PayPerDiemFilterInput;
  sendMail: Scalars['Boolean'];
};


export type MutationPayPvlArgs = {
  date: Scalars['LocalDate'];
  employeeId: Scalars['String'];
  filter: PayPvlFilterInput;
  sendMail: Scalars['Boolean'];
};


export type MutationPinCommunicationFeedItemArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationPostHistoricalTransactionsArgs = {
  data: Array<AccountingImportDestGlInput>;
  jobId: Scalars['String'];
};


export type MutationReemployEmployeeArgs = {
  employee: EditMeta;
};


export type MutationReleaseCaftArgs = {
  caftReleaseId: Scalars['String'];
  now?: InputMaybe<Scalars['DateTime']>;
};


export type MutationRemoveAuthorizedRepresentativeArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationRemoveDirectorArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationRemovePartnerArgs = {
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationReversePerDiemPaymentArgs = {
  perDiemPaymentEventId: Scalars['String'];
};


export type MutationReversePvlPaymentArgs = {
  pvlPaymentEventId: Scalars['String'];
};


export type MutationSaveAccountingImportJobArgs = {
  jobId: Scalars['String'];
  state: AccountingImportJobStateInput;
  version: Scalars['Int'];
};


export type MutationSaveOpeningBalancesArgs = {
  glAccountInputs: Array<EditGeneralLedgerAccountInput>;
};


export type MutationSendAgentInvitationArgs = {
  data: AgentInvitationInput;
};


export type MutationSendContactInvitationArgs = {
  contactId: Scalars['String'];
  registrationPageEndpoint: Scalars['String'];
};


export type MutationSendPasswordResetEmailArgs = {
  email: Scalars['String'];
  hostname: Scalars['String'];
  passwordResetEndpoint: Scalars['String'];
};


export type MutationSetAccountRatingByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
  accountRatingId: Scalars['String'];
};


export type MutationSetActiveAgentArgs = {
  agentId: Scalars['String'];
};


export type MutationSetCaftOptionsArgs = {
  options: SetCaftOptionsInput;
};


export type MutationSortFileInfoArgs = {
  id: Scalars['String'];
};


export type MutationUpdateCommunicationFeedItemArgs = {
  color: Scalars['String'];
  contents: Scalars['String'];
  id: Scalars['String'];
  pinned: Scalars['Boolean'];
  version: Scalars['Int'];
};


export type MutationUpdateDocumentArgs = {
  data: UpdateDocumentInput;
};


export type MutationUpdateDocumentSortingClassArgs = {
  data: UpdateDocumentSortingClassInput;
};


export type MutationUpdateDraftDocumentArgs = {
  data: UpdateDraftDocumentInput;
};


export type MutationUpdateMaintenanceLogEventArgs = {
  data: UpdateMaintenanceLogEventDataInput;
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationUpdatePerDiemEventArgs = {
  data: UpdatePerDiemEventDataInput;
  id: Scalars['String'];
  version: Scalars['Int'];
};


export type MutationUpdatePvlEventArgs = {
  data: UpdatePvlEventDataInput;
  id: Scalars['String'];
  version: Scalars['Int'];
};

export type Notification = {
  __typename?: 'Notification';
  dateTimeCreated: Scalars['DateTime'];
  dateTimeRead?: Maybe<Scalars['DateTime']>;
  dateTimeSent?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['String'];
  mobileRoute?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: NotificationType;
  user: User;
  version: Scalars['Int'];
  webRoute?: Maybe<Scalars['String']>;
};

export type NotificationType = {
  __typename?: 'NotificationType';
  icon: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  required: Scalars['Boolean'];
  version: Scalars['Int'];
};

export type Office = {
  __typename?: 'Office';
  agents?: Maybe<Array<Agent>>;
  id: Scalars['String'];
  mailingAddress: Address;
  name: Scalars['String'];
  organization: Organization;
  physicalAddress: Address;
  timezone: Scalars['String'];
  version: Scalars['Int'];
};

export type OfficeDetails = {
  __typename?: 'OfficeDetails';
  caftAmount: Scalars['Money'];
  corporateAccounts: Scalars['Int'];
  employees: Scalars['Int'];
  feeAmount: Scalars['Money'];
  id: Scalars['String'];
  name: Scalars['String'];
  personalAccounts: Scalars['Int'];
  selfEmployedAccounts: Scalars['Int'];
};

export type OfficeReport = {
  __typename?: 'OfficeReport';
  caftAmount: Scalars['Money'];
  corporateAccounts: Scalars['Int'];
  feeAmount: Scalars['Money'];
  id: Scalars['String'];
  name: Scalars['String'];
  personalAccounts: Scalars['Int'];
  selfEmployedAccounts: Scalars['Int'];
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['String'];
  managementAccount?: Maybe<AccountInfo>;
  name: Scalars['String'];
  offices?: Maybe<Array<Office>>;
  payrollFrequency: PayrollFrequencyEnum;
  payrollStartDate: Scalars['LocalDate'];
  version: Scalars['Int'];
};

export type OrganizationReport = {
  __typename?: 'OrganizationReport';
  id: Scalars['String'];
  name: Scalars['String'];
  offices?: Maybe<Array<OfficeDetails>>;
};

export type OriginalFile = {
  __typename?: 'OriginalFile';
  bytes: Scalars['Int'];
  filename: Scalars['String'];
  hash: Scalars['String'];
  id: Scalars['String'];
  version?: Maybe<Scalars['Int']>;
};

export type OtherDeductionsLineItem = {
  __typename?: 'OtherDeductionsLineItem';
  amount: Scalars['Money'];
  description: Scalars['String'];
  id: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  rates?: Maybe<Scalars['Float']>;
  version: Scalars['Int'];
};

export type OtherDeductionsLineItemInput = {
  amount: Scalars['Money'];
  description: Scalars['String'];
  quantity?: InputMaybe<Scalars['Float']>;
  rates?: InputMaybe<Scalars['Float']>;
};

export type OtherDeductionsLineItemInputEdit = {
  amount: Scalars['Money'];
  description: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Float']>;
  rates?: InputMaybe<Scalars['Float']>;
};

export type OutstandingAccountInvoices = {
  __typename?: 'OutstandingAccountInvoices';
  accountInfo: AccountInfo;
  balance: Scalars['Money'];
  id: Scalars['String'];
};

export type OverviewReport = {
  __typename?: 'OverviewReport';
  activeUsers: Scalars['Int'];
  admins: Scalars['Int'];
  basicAccounts: AccountType;
  caftAmount: Scalars['Money'];
  corporateAccounts: AccountType;
  feeAmount: Scalars['Money'];
  managers: Scalars['Int'];
  personalAccounts: AccountType;
  selfEmployedAccounts: AccountType;
  sorters: Scalars['Int'];
  sysAdmins: Scalars['Int'];
  workstations: Scalars['Int'];
};

export type Partner = {
  __typename?: 'Partner';
  contact: Contact;
  id: Scalars['String'];
  ownershipPercentage?: Maybe<Scalars['Int']>;
  version: Scalars['Int'];
};

export type PayPerDiemFilterInput = {
  endDate?: InputMaybe<Scalars['LocalDate']>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
};

export type PayPvlFilterInput = {
  endDate?: InputMaybe<Scalars['LocalDate']>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
};

export type PayStatement = {
  __typename?: 'PayStatement';
  accountInfo: AccountInfo;
  comment?: Maybe<Scalars['String']>;
  date: Scalars['LocalDate'];
  id: Scalars['String'];
  journalEntry: JournalEntry;
  payStatementLines: Array<PayStatementLine>;
  reversed?: Maybe<Scalars['Boolean']>;
  version: Scalars['Int'];
};

export type PayStatementFilterInput = {
  endDate?: InputMaybe<Scalars['LocalDate']>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
};

export type PayStatementLine = {
  __typename?: 'PayStatementLine';
  glAccount: GeneralLedgerAccount;
  gst?: Maybe<Scalars['Money']>;
  id: Scalars['String'];
  lineNumber: Scalars['Int'];
  payStatement: PayStatement;
  subtotal: Scalars['Money'];
  total: Scalars['Money'];
  uiTotal: Scalars['Money'];
  version: Scalars['Int'];
};

export type PayStub = {
  __typename?: 'PayStub';
  cpp: Scalars['Money'];
  cpp2: Scalars['Money'];
  ei: Scalars['Money'];
  employee: Employee;
  endDate: Scalars['LocalDate'];
  grossIncome: Array<GrossIncomeLineItem>;
  id: Scalars['String'];
  otherDeductions?: Maybe<Array<OtherDeductionsLineItem>>;
  payDate: Scalars['LocalDate'];
  startDate: Scalars['LocalDate'];
  tax: Scalars['Money'];
  version: Scalars['Int'];
};

export type PayStubAddInput = {
  cpp: Scalars['Money'];
  cpp2: Scalars['Money'];
  ei: Scalars['Money'];
  employeeId: Scalars['String'];
  endDate: Scalars['LocalDate'];
  grossIncome: Array<GrossIncomeLineItemInput>;
  otherDeductions?: InputMaybe<Array<OtherDeductionsLineItemInput>>;
  payDate: Scalars['LocalDate'];
  startDate: Scalars['LocalDate'];
  tax: Scalars['Money'];
};

export type PayStubEditInput = {
  cpp: Scalars['Money'];
  cpp2: Scalars['Money'];
  ei: Scalars['Money'];
  employeeId: Scalars['String'];
  endDate: Scalars['LocalDate'];
  grossIncome: Array<GrossIncomeLineItemInputEdit>;
  id: Scalars['String'];
  otherDeductions?: InputMaybe<Array<OtherDeductionsLineItemInputEdit>>;
  payDate: Scalars['LocalDate'];
  startDate: Scalars['LocalDate'];
  tax: Scalars['Money'];
  version: Scalars['Int'];
};

export type PayStubInterval = {
  __typename?: 'PayStubInterval';
  endDate?: Maybe<Scalars['LocalDate']>;
  payDate?: Maybe<Scalars['LocalDate']>;
  startDate?: Maybe<Scalars['LocalDate']>;
};

export type PayStubRow = SourceDeductionRowInterface & {
  __typename?: 'PayStubRow';
  cpp?: Maybe<Scalars['Money']>;
  cpp2?: Maybe<Scalars['Money']>;
  date: Scalars['LocalDate'];
  ei?: Maybe<Scalars['Money']>;
  grossIncome?: Maybe<Scalars['Money']>;
  id: Scalars['String'];
  otherDeductions?: Maybe<Array<OtherDeductionsLineItem>>;
  payStubName?: Maybe<Scalars['String']>;
  period: Scalars['LocalDate'];
  remittance?: Maybe<Scalars['Money']>;
  tax?: Maybe<Scalars['Money']>;
  type: SourceDeductionRowType;
  version: Scalars['Int'];
};

export type PayStubsFilterInput = {
  employeeIds?: InputMaybe<Array<Scalars['String']>>;
  endDate?: InputMaybe<Scalars['LocalDate']>;
  orderDirection?: InputMaybe<PayStubsFilterOrderDirectionEnum>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
};

export enum PayStubsFilterOrderDirectionEnum {
  Asc = 'asc',
  Desc = 'desc'
}

export type PayableRow = SourceDeductionRowInterface & {
  __typename?: 'PayableRow';
  amount: Scalars['Money'];
  arrears?: Maybe<Scalars['Boolean']>;
  date: Scalars['LocalDate'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  period: Scalars['LocalDate'];
  type: SourceDeductionRowType;
  version: Scalars['Int'];
};

export type Payment = {
  __typename?: 'Payment';
  accountInfo: AccountInfo;
  date: Scalars['LocalDate'];
  id: Scalars['String'];
  invoicePayment: Array<InvoicePayment>;
  nsfPayment?: Maybe<Payment>;
  paymentMethod?: Maybe<PaymentMethodEnum>;
  paymentNumber: Scalars['Int'];
  status: PaymentStatus;
  total: Scalars['Money'];
  version: Scalars['Int'];
};

export enum PaymentMethodEnum {
  Caft = 'CAFT',
  Cash = 'Cash',
  Cheque = 'Cheque',
  ETransfer = 'eTransfer'
}

export type PaymentRow = SourceDeductionRowInterface & {
  __typename?: 'PaymentRow';
  chequeNumber?: Maybe<Scalars['Int']>;
  date: Scalars['LocalDate'];
  id: Scalars['String'];
  lineItems?: Maybe<Array<SourceDeductionPaymentLineItem>>;
  nsf?: Maybe<Scalars['Boolean']>;
  nsfDate?: Maybe<Scalars['LocalDate']>;
  paymentType?: Maybe<Scalars['String']>;
  period: Scalars['LocalDate'];
  type: SourceDeductionRowType;
  version: Scalars['Int'];
};

export type PaymentSchedule = {
  __typename?: 'PaymentSchedule';
  balance: Scalars['Money'];
  payments: Array<Payment>;
  scheduledPayments: Array<ScheduledPayment>;
};

export enum PaymentStatus {
  Nsf = 'NSF',
  NsfReversed = 'NsfReversed',
  Reversed = 'Reversed',
  Successful = 'Successful'
}

export enum PayrollFrequencyEnum {
  BiWeekly = 'BiWeekly',
  Monthly = 'Monthly',
  Weekly = 'Weekly'
}

export type PerDiemEvent = {
  __typename?: 'PerDiemEvent';
  accountsPayable?: Maybe<Scalars['Money']>;
  eliminateShowerAndAmenities: Scalars['Boolean'];
  location: LocationEnum;
  overnight: Scalars['Boolean'];
  paid?: Maybe<Scalars['Boolean']>;
  perDiem?: Maybe<Scalars['Money']>;
  tax?: Maybe<Scalars['Money']>;
  taxableMealAllowance?: Maybe<Scalars['Money']>;
};

export type PerDiemPaymentEvent = {
  __typename?: 'PerDiemPaymentEvent';
  canadaDayCount: Scalars['Int'];
  totalAccountsPayable: Scalars['Money'];
  totalPerDiem: Scalars['Money'];
  totalTax: Scalars['Money'];
  totalTaxableMealAllowance: Scalars['Money'];
  usDayCount: Scalars['Int'];
};

export enum Permission {
  EditRates = 'editRates',
  InviteUser = 'inviteUser',
  ManageAgents = 'manageAgents',
  ManageAllAgents = 'manageAllAgents',
  ManageAssignedAgents = 'manageAssignedAgents',
  ManageDocumentTags = 'manageDocumentTags',
  ManageDocumentTypes = 'manageDocumentTypes',
  ManageOwnOrganization = 'manageOwnOrganization',
  MarketingTools = 'marketingTools',
  ReadAllAccount = 'readAllAccount',
  ReadOwnAccount = 'readOwnAccount',
  Sysadmin = 'sysadmin',
  ViewAdminPayrollAccount = 'viewAdminPayrollAccount',
  ViewReports = 'viewReports',
  WriteAllAccount = 'writeAllAccount',
  WriteOwnAccount = 'writeOwnAccount',
  WriteOwnUser = 'writeOwnUser'
}

export type PersonalAccount = {
  __typename?: 'PersonalAccount';
  accountInfo: AccountInfo;
  id: Scalars['String'];
  version: Scalars['Int'];
};

export type PersonalVehicle = {
  __typename?: 'PersonalVehicle';
  accountInfo: AccountInfo;
  archived: Scalars['Boolean'];
  color?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  make: Scalars['String'];
  model?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  version: Scalars['Int'];
  year?: Maybe<Scalars['Int']>;
};

export type Podcast = {
  __typename?: 'Podcast';
  date: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  image: Scalars['String'];
  title: Scalars['String'];
  version: Scalars['Int'];
  youtubeId: Scalars['String'];
};

export type PreAuthorizedPayment = {
  __typename?: 'PreAuthorizedPayment';
  authorizedLimit: Scalars['Money'];
  bankAccountNumber: Scalars['String'];
  date: Scalars['LocalDate'];
  frequency: FrequencyEnum;
  id: Scalars['String'];
  institutionNumber: Scalars['String'];
  lastGeneration?: Maybe<Scalars['DateTime']>;
  transitNumber: Scalars['String'];
  version: Scalars['Int'];
};

export type ProcessorError = {
  __typename?: 'ProcessorError';
  account?: Maybe<AccountInfo>;
  data?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  version?: Maybe<Scalars['Int']>;
};

export type ProcessorQueue = {
  __typename?: 'ProcessorQueue';
  accountInfo?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  files?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export enum ProvinceEnum {
  Ab = 'AB',
  Bc = 'BC',
  Mb = 'MB',
  Nb = 'NB',
  Nl = 'NL',
  Ns = 'NS',
  Nt = 'NT',
  Nu = 'NU',
  On = 'ON',
  Pe = 'PE',
  Qc = 'QC',
  Sk = 'SK',
  Yt = 'YT'
}

export type PvlEvent = {
  __typename?: 'PvlEvent';
  endingKM: Scalars['Float'];
  expenses?: Maybe<Scalars['Money']>;
  options?: Maybe<Array<Scalars['String']>>;
  otherReason?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['Boolean']>;
  personalVehicleId: Scalars['String'];
  startingKM: Scalars['Float'];
  tax?: Maybe<Scalars['Money']>;
};

export type PvlPaymentEvent = {
  __typename?: 'PvlPaymentEvent';
  totalExpenses: Scalars['Money'];
  totalTax: Scalars['Money'];
};

export type PvlRate = {
  __typename?: 'PvlRate';
  after5000Rate: Scalars['Float'];
  effectiveDate: Scalars['LocalDate'];
  first5000Rate: Scalars['Float'];
  id: Scalars['String'];
  province: ProvinceEnum;
  sourceImage?: Maybe<Scalars['String']>;
  sourceUrl: Scalars['String'];
  version: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  areAnyGlAccountsInUse: Scalars['Boolean'];
  areLinkedGlAccountsUsed: Scalars['Boolean'];
  checkAccountingImportJob?: Maybe<AccountingImportCheckResult>;
  checkUserExistsByEmail?: Maybe<Scalars['Boolean']>;
  doSearchQuery?: Maybe<SearchResults>;
  exportArchive: Scalars['String'];
  getAcFiscalYearsByAccountInfoId?: Maybe<Array<AcFiscalYear>>;
  getAcPayableById?: Maybe<AcPayable>;
  getAcPayableInvoiceNumberDuplicate?: Maybe<Array<AcPayable>>;
  getAcPayablePreviewJournalEntryLines?: Maybe<Array<GetPreviewJournalEntryLinesReturn>>;
  getAcPayableTemplateById?: Maybe<AcPayableTemplate>;
  getAcPayableTemplatesByAccountInfoId?: Maybe<Array<AcPayableTemplate>>;
  getAcPayableTemplatesByIds?: Maybe<Array<AcPayableTemplate>>;
  getAcPayablesByAccountInfoId?: Maybe<Array<AcPayable>>;
  getAcPayablesByAccountInfoIdAndPayableIds?: Maybe<Array<AcPayable>>;
  getAcPayablesByIds?: Maybe<Array<AcPayable>>;
  getAcPaymentById?: Maybe<AcPayment>;
  getAcPaymentPreviewJournalEntryLines?: Maybe<Array<GetPreviewJournalEntryLinesReturn>>;
  getAcPaymentsByAccountInfoId?: Maybe<Array<AcPayment>>;
  getAcPaymentsByPayableId?: Maybe<Array<AcPayment>>;
  getAcReceiptById?: Maybe<AcReceipt>;
  getAcReceiptPreviewJournalEntryLines?: Maybe<Array<GetPreviewJournalEntryLinesReturn>>;
  getAcReceiptsByAccountInfoId?: Maybe<Array<AcReceipt>>;
  getAcReceiptsByReceivableId?: Maybe<Array<AcReceipt>>;
  getAcReceivableById?: Maybe<AcReceivable>;
  getAcReceivableInvoiceNumberDuplicate?: Maybe<Array<AcReceivable>>;
  getAcReceivablePreviewJournalEntryLines?: Maybe<Array<GetPreviewJournalEntryLinesReturn>>;
  getAcReceivableTemplateById?: Maybe<AcReceivableTemplate>;
  getAcReceivableTemplatesByAccountInfoId?: Maybe<Array<AcReceivableTemplate>>;
  getAcReceivableTemplatesByIds?: Maybe<Array<AcReceivableTemplate>>;
  getAcReceivablesByAccountInfoId?: Maybe<Array<AcReceivable>>;
  getAcReceivablesByAccountInfoIdAndReceivableIds?: Maybe<Array<AcReceivable>>;
  getAcReceivablesByIds?: Maybe<Array<AcReceivable>>;
  getAccommodationAmountByDate?: Maybe<AccommodationAmount>;
  getAccommodationAmountById?: Maybe<AccommodationAmount>;
  getAccountByInfoId?: Maybe<Account>;
  getAccountCarriers?: Maybe<Array<Carrier>>;
  getAccountInfoByFriendlyId?: Maybe<AccountInfo>;
  getAccountInfoById?: Maybe<AccountInfo>;
  getAccountInfoByUserId?: Maybe<Array<AccountInfo>>;
  getAccountRatingByAccountInfoId?: Maybe<AccountRating>;
  getAccountingImportJob?: Maybe<AccountingImportJob>;
  getAccountingImportJobs: Array<AccountingImportJob>;
  getAccountsWithoutCaft?: Maybe<Array<AccountInfo>>;
  getAccountsWithoutFees?: Maybe<Array<AccountInfo>>;
  getActiveAgentsByRoles?: Maybe<Array<Agent>>;
  getActiveAgentsBySupervisorAgentId?: Maybe<Array<Agent>>;
  getActiveContractsByAccountInfoId: Array<Contract>;
  getAdjacentPerDiemEventsLengths: GetAdjacentPerDiemEventsLengthsReturn;
  getAgentById: Agent;
  getAgentsByAuthorizedUser?: Maybe<Array<Agent>>;
  getAgentsByRoles?: Maybe<Array<Agent>>;
  getAlertSettingsByUserId?: Maybe<Array<AlertSettings>>;
  getAllAccommodationRates?: Maybe<Array<AccommodationAmount>>;
  getAllAccountInfo?: Maybe<Array<AccountInfo>>;
  getAllAccountRatings?: Maybe<Array<AccountRating>>;
  getAllAccountTypes?: Maybe<Array<DropdownAccountTypes>>;
  getAllAccountsByDefaultAgent?: Maybe<Array<AccountInfo>>;
  getAllAccountsByManager?: Maybe<Array<AccountInfo>>;
  getAllAccountsWithInactiveAgents?: Maybe<Array<AccountInfo>>;
  getAllAgents?: Maybe<Array<Agent>>;
  getAllAnnouncements?: Maybe<Array<Announcement>>;
  getAllContactEmails: Scalars['String'];
  getAllContractsByAccountInfoId: Array<Contract>;
  getAllDomesticReductionRates?: Maybe<Array<DomesticReductionRate>>;
  getAllEIRates?: Maybe<Array<EiRate>>;
  getAllFileArchivesByAccountId?: Maybe<Array<FileArchive>>;
  getAllFiscalYearRanges?: Maybe<Array<AcFiscalYearRange>>;
  getAllFuelTaxRates?: Maybe<Array<FuelTaxRate>>;
  getAllMinimumWages?: Maybe<Array<MinimumWage>>;
  getAllPvlRates?: Maybe<Array<PvlRate>>;
  getAllTaxRates?: Maybe<Array<TaxRate>>;
  getAllTicketCategories?: Maybe<Array<TicketCategory>>;
  getAllTicketsByUserId?: Maybe<Array<Ticket>>;
  getAllTreasuryAmounts?: Maybe<Array<TreasuryAmount>>;
  getAnnualSavingsReportByAccountId?: Maybe<Array<AnnualSavingsReport>>;
  getAreFuturesPaid?: Maybe<Scalars['Boolean']>;
  getAuthorizedRepresentativeById: AuthorizedRepresentative;
  getAuthorizedRepresentativesByAccountInfoId?: Maybe<Array<AuthorizedRepresentative>>;
  getAverageDaysToReceiveDocuments: Scalars['Int'];
  getBalance: DateRangeBalances;
  getBalanceSheet: BalanceSheet;
  getBasicAccountByAccountInfoId?: Maybe<BasicAccount>;
  getBusinessNumber?: Maybe<Scalars['String']>;
  getBusinessReport?: Maybe<Report>;
  getCaftOptions: CaftOptions;
  getCaftPendingItemsReport?: Maybe<Array<CaftPending>>;
  getCaftRelease?: Maybe<CaftRelease>;
  getCaftReleases: Array<CaftRelease>;
  getCalendarEventById: CalendarEvent;
  getCalendarEventsByEmployeeIds?: Maybe<Array<CalendarEvent>>;
  getCarrierReport?: Maybe<Array<CarrierReport>>;
  getChartOfAccountsByAccountInfoId?: Maybe<ChartOfAccounts>;
  getChartOfAccountsById?: Maybe<ChartOfAccounts>;
  getChartOfAccountsTemplateById?: Maybe<ChartOfAccountsTemplate>;
  getChartOfAccountsTemplates?: Maybe<Array<ChartOfAccountsTemplate>>;
  getCommunicationFeedItems?: Maybe<Array<CommunicationFeedItem>>;
  getContactById?: Maybe<Contact>;
  getContactsByAuthorizedUserId?: Maybe<Array<Maybe<Contact>>>;
  getContractById: Contract;
  getContractTemplateById?: Maybe<ContractTemplate>;
  getContractTemplates?: Maybe<Array<Maybe<ContractTemplate>>>;
  getContractTemplatesByOrganization?: Maybe<Array<ContractTemplate>>;
  getCorporateAccountByAccountInfoId?: Maybe<CorporateAccount>;
  getCorporateAssetById?: Maybe<CorporateAsset>;
  getCorporateAssetGlAccountData?: Maybe<Array<CorporateAssetGlAccountDataReturn>>;
  getCorporateAssetsByAccountId?: Maybe<Array<CorporateAsset>>;
  getCorporateOverview: CorporateOverview;
  getCorporateTaxesEvents?: Maybe<Array<CorporateTaxesEvent>>;
  getCorporateTaxesPaidById?: Maybe<CorporateTaxesPaid>;
  getDirectorById?: Maybe<Director>;
  getDirectorsByAccountInfoId?: Maybe<Array<Director>>;
  getDocumentById?: Maybe<Document>;
  getDocumentSorterReport?: Maybe<Array<DocumentSorterReport>>;
  getDocumentSortingClassById: DocumentSortingClass;
  getDocumentSortingClasses?: Maybe<Array<DocumentSortingClass>>;
  getDocumentTypeBreakdown?: Maybe<Array<DocumentTypeBreakdown>>;
  getDocumentTypeById?: Maybe<DocumentType>;
  getDocumentTypes?: Maybe<Array<DocumentType>>;
  getDocumentTypesByGroup?: Maybe<Array<DocumentType>>;
  getDocumentURL?: Maybe<Scalars['String']>;
  getDocumentUploadSourceReport?: Maybe<Array<DocumentUploadSourceReport>>;
  getDocumentsByAccount?: Maybe<Array<Document>>;
  getDocumentsByStatement?: Maybe<Array<Document>>;
  getDocumentsForJournalEntries?: Maybe<Array<Document>>;
  getDomesticReductionRateByDate?: Maybe<DomesticReductionRate>;
  getDraftDocumentById?: Maybe<DraftDocument>;
  getDraftDocumentsByDraftDocumentSourceId?: Maybe<Array<DraftDocument>>;
  getEIRateByDate?: Maybe<EiRate>;
  getEIRateById?: Maybe<EiRate>;
  getEmployeeById: Employee;
  getEmployeeReport: EmployeeReport;
  getEmployeesByAccountInfoId?: Maybe<Array<Employee>>;
  getEmployeesForAgent?: Maybe<Array<Employee>>;
  getExchangeRate: Scalars['Float'];
  getExpectedDocumentById?: Maybe<ExpectedDocument>;
  getExpectedDocumentExceptionById?: Maybe<ExpectedDocumentException>;
  getExpectedDocumentTypes?: Maybe<Array<Maybe<DocumentType>>>;
  getExpectedDocumentsByAccount: Array<ExpectedDocument>;
  getExpenseById?: Maybe<Expense>;
  getExpensesByAccountId?: Maybe<Array<Maybe<Expense>>>;
  getFileArchiveUrl: Scalars['String'];
  getFileInfoById?: Maybe<FileInfo>;
  getFileInfoURL?: Maybe<Scalars['String']>;
  getFileInfoURLFromSourceDocumentId: Scalars['String'];
  getFileInfosByAccount?: Maybe<Array<FileInfo>>;
  getFileURLByFilePath?: Maybe<Scalars['String']>;
  getFileUrlByDraftDocumentId: Scalars['String'];
  getFiledList?: Maybe<Array<GstFiled>>;
  getFilteredTickets?: Maybe<Array<Ticket>>;
  getFinancialAccountByAccountInfoId?: Maybe<Array<FinancialAccount>>;
  getFinancialAccountById: FinancialAccountDetails;
  getFinancialAccountPreviewData?: Maybe<Array<FinancialAccountPreviewDataReturn>>;
  getFirstJournalEntry?: Maybe<JournalEntry>;
  getFiscalYearGroupedAccountsByDefaultAgent?: Maybe<Array<GroupedAccounts>>;
  getFuelTaxRateById?: Maybe<FuelTaxRate>;
  getGeneralLedgerAccountById?: Maybe<GeneralLedgerAccount>;
  getGeneralLedgerAccountTemplateById?: Maybe<GeneralLedgerAccountTemplate>;
  getGeneralLedgerReport: Array<GeneralLedgerReport>;
  getGlobalAnnualSavingsReport?: Maybe<Array<AnnualSavingsReport>>;
  getGlobalVendors?: Maybe<Array<Vendor>>;
  getGstDepositedById?: Maybe<GstDeposited>;
  getGstFiledById?: Maybe<GstFiled>;
  getGstFilingFrequencies?: Maybe<Array<Maybe<DropdownGstFilingFrequencyTypes>>>;
  getGstFilingFrequency?: Maybe<GstFilingFrequencyEnum>;
  getGstHeldById?: Maybe<GstHeld>;
  getGstPaidById?: Maybe<GstPaid>;
  getImageUrl?: Maybe<Scalars['String']>;
  getIncomeStatement: IncomeStatement;
  getIncorporationDate?: Maybe<Scalars['LocalDate']>;
  getIndexStatistics: SearchStatistics;
  getInvoiceById?: Maybe<Invoice>;
  getIsPreviousMonthPayed?: Maybe<Scalars['Boolean']>;
  getJournalEntries?: Maybe<Array<JournalEntry>>;
  getJournalEntriesByDocumentId?: Maybe<Array<JournalEntry>>;
  getJournalEntryById?: Maybe<JournalEntry>;
  getJournalEntryTemplateById?: Maybe<JournalEntryTemplate>;
  getJournalEntryTemplatesByAccountInfoId?: Maybe<Array<JournalEntryTemplate>>;
  getJournalEntryTemplatesByIds?: Maybe<Array<JournalEntryTemplate>>;
  getLastClosedDate?: Maybe<Scalars['LocalDate']>;
  getLastFiscalYear?: Maybe<AcFiscalYear>;
  getLatestTimeCard?: Maybe<TimeCard>;
  getLeadById?: Maybe<Lead>;
  getLeadSourceReport: Array<LeadSource>;
  getLeadsReport: Array<LeadReport>;
  getLikeLeads: Array<Maybe<Lead>>;
  getLikeVendors?: Maybe<Array<Vendor>>;
  getLocations?: Maybe<Array<Location>>;
  getManagementBonusById?: Maybe<ManagementBonus>;
  getMinimumWageById: MinimumWage;
  getMissingDocuments?: Maybe<MissingStatementItems>;
  getMissingFilesReport?: Maybe<MissingFilesReport>;
  getNextFiscalYearRange: AcFiscalYearRange;
  getNotificationTypes?: Maybe<Array<NotificationType>>;
  getNotificationsByUserId?: Maybe<Array<Notification>>;
  getNumberOfUserEventsByAccountId: Scalars['Int'];
  getNumberOfUserEventsByAgentId: Scalars['Int'];
  getNumberOfUserEventsByUserId: Scalars['Int'];
  getOfficeById: Office;
  getOfficeReport?: Maybe<Array<OfficeReport>>;
  getOffices?: Maybe<Array<Office>>;
  getOfficesByOrganization?: Maybe<Array<Office>>;
  getOrganizationById: Organization;
  getOrganizationReport?: Maybe<Array<OrganizationReport>>;
  getOrganizations?: Maybe<Array<Organization>>;
  getOutstandingInvoicesByAccountInfoId?: Maybe<Array<Maybe<Invoice>>>;
  getOutstandingInvoicesByAccounts?: Maybe<Array<OutstandingAccountInvoices>>;
  getOverviewReport: OverviewReport;
  getPartnerById?: Maybe<Partner>;
  getPartnersByAccountInfoId?: Maybe<Array<Partner>>;
  getPayStatementById?: Maybe<PayStatement>;
  getPayStatementsByAccountInfoId?: Maybe<Array<PayStatement>>;
  getPayStubById?: Maybe<PayStub>;
  getPayStubsByAccount?: Maybe<Array<PayStub>>;
  getPayStubsNextIntervalDates?: Maybe<PayStubInterval>;
  getPaymentById?: Maybe<Payment>;
  getPaymentScheduleByAccountInfoId?: Maybe<PaymentSchedule>;
  getPersonalAccountByAccountInfoId?: Maybe<PersonalAccount>;
  getPersonalVehicleById?: Maybe<PersonalVehicle>;
  getPersonalVehiclesByAccountId?: Maybe<Array<PersonalVehicle>>;
  getPodcastById: Podcast;
  getPodcasts?: Maybe<Array<Podcast>>;
  getProcessorErrors?: Maybe<Array<ProcessorError>>;
  getProcessorQueueItemsWaiting?: Maybe<Array<ProcessorQueue>>;
  getPvlRateByDate?: Maybe<PvlRate>;
  getPvlRateById?: Maybe<PvlRate>;
  getRecentAccountsByAgentId?: Maybe<Array<AccountInfo>>;
  getReductionRateById?: Maybe<DomesticReductionRate>;
  getRegistrationInviteLink: Scalars['String'];
  getRelatedAccounts?: Maybe<Array<AccountInfo>>;
  getReminderById: Reminder;
  getRemindersByUserId?: Maybe<Array<Reminder>>;
  getScheduledPaymentById?: Maybe<ScheduledPayment>;
  getSelfEmployedAccountByAccountInfoId?: Maybe<SelfEmployedAccount>;
  getSelfEmployedOverview: SelfEmployedOverview;
  getSortedDocsByDraftDocumentSourceId?: Maybe<Array<Document>>;
  getSortedDocsByFileInfoId?: Maybe<Array<Document>>;
  getSorterOverview?: Maybe<Array<SorterOverview>>;
  getSourceDeductionByAccountInfoAndYear?: Maybe<SourceDeductionYear>;
  getSourceDeductionOpeningBalanceById?: Maybe<SourceDeductionOpeningBalance>;
  getSourceDeductionPayableById?: Maybe<SourceDeductionPayable>;
  getSourceDeductionPaymentById?: Maybe<SourceDeductionPayment>;
  getSourceDeductionStatementBalanceById?: Maybe<SourceDeductionStatementBalance>;
  getSourceDeductionsStatementBalanceByAccountInfoAndYear?: Maybe<Array<SourceDeductionStatementBalance>>;
  getSpacesCleanup?: Maybe<Array<SpacesCleanup>>;
  getStatHolidayPay: Scalars['Money'];
  getStatementByDocumentId?: Maybe<Array<Statement>>;
  getStatementByFinancialAccountId?: Maybe<Array<Statement>>;
  getStatementById?: Maybe<Statement>;
  getT1ByAccountInfoId?: Maybe<Array<T1>>;
  getT1ById?: Maybe<T1>;
  getT2FiledById?: Maybe<T2Filed>;
  getT4ByAccountInfoId?: Maybe<Array<T4>>;
  getT4ById?: Maybe<T4>;
  getT4SummaryByAccount?: Maybe<Array<Maybe<T4Summary>>>;
  getT5ById?: Maybe<T5>;
  getT5sByAccountInfoId?: Maybe<Array<T5>>;
  getTaxRateByDateAndProvince?: Maybe<TaxRate>;
  getTaxRateByDateAndProvinceByEmployee?: Maybe<TaxRate>;
  getTaxRateById?: Maybe<TaxRate>;
  getTicketById: Ticket;
  getTicketCategoryById: TicketCategory;
  getTicketReplyById: TicketReply;
  getTimeCardById: TimeCard;
  getTimeCards?: Maybe<Array<TimeCard>>;
  getTransactionsByAccountInfoId?: Maybe<Transactions>;
  getTreasuryAmountByDateAndLocationByEmployee?: Maybe<TreasuryAmount>;
  getTreasuryAmountById?: Maybe<TreasuryAmount>;
  getUnsortedFilesByAccount?: Maybe<Array<DraftDocumentSource>>;
  getUsageReport?: Maybe<Array<StorageUsage>>;
  getUsageReportByAccountId: AccountStorage;
  getUserAccountInfo?: Maybe<Array<Maybe<AccountInfo>>>;
  getUserActivityByAgentId?: Maybe<Array<UserActivity>>;
  getUserActivityByUserId?: Maybe<Array<UserActivity>>;
  getUserById?: Maybe<User>;
  getUserEventsByAccount?: Maybe<Array<UserEvent>>;
  getUserEventsByAgent?: Maybe<Array<UserEvent>>;
  getUserEventsByUser?: Maybe<Array<UserEvent>>;
  getVendorById?: Maybe<Vendor>;
  getVendorsByAccountInfo?: Maybe<Array<Vendor>>;
  getYearEndByAccountInfoId?: Maybe<Scalars['LocalDate']>;
  getYearEndReportByAgent?: Maybe<Array<YearEndYear>>;
  getYearEndReportByAgentsOrganization: Array<YearEndOrganizationReport>;
  glAccountsInUse: GeneralLedgerAccountsInUse;
  performOpeningBalanceTrialBalanceCheck: TrialBalanceResult;
  root?: Maybe<Scalars['String']>;
};


export type QueryAreAnyGlAccountsInUseArgs = {
  ids: Array<Scalars['String']>;
};


export type QueryAreLinkedGlAccountsUsedArgs = {
  assetId: Scalars['String'];
};


export type QueryCheckAccountingImportJobArgs = {
  data: Array<AccountingImportDestGlInput>;
  jobId: Scalars['String'];
};


export type QueryCheckUserExistsByEmailArgs = {
  email: Scalars['String'];
};


export type QueryDoSearchQueryArgs = {
  data: SearchQueryInput;
};


export type QueryExportArchiveArgs = {
  data: ExportArchivesFilterInput;
};


export type QueryGetAcFiscalYearsByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetAcPayableByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetAcPayableInvoiceNumberDuplicateArgs = {
  accountInfoId: Scalars['String'];
  invoiceNumber: Scalars['String'];
  vendorId: Scalars['String'];
  viewedPayableId?: InputMaybe<Scalars['String']>;
};


export type QueryGetAcPayablePreviewJournalEntryLinesArgs = {
  data: GetPreviewAcPayableJournalEntryLinesInput;
};


export type QueryGetAcPayableTemplateByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetAcPayableTemplatesByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
  filter?: InputMaybe<AcPayableTemplateFilterInput>;
};


export type QueryGetAcPayableTemplatesByIdsArgs = {
  ids: Array<Scalars['String']>;
};


export type QueryGetAcPayablesByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
  filter?: InputMaybe<AcPayableFilterInput>;
};


export type QueryGetAcPayablesByAccountInfoIdAndPayableIdsArgs = {
  accountInfoId: Scalars['String'];
  filter?: InputMaybe<AcPayableFilterInput>;
  payableIds?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryGetAcPayablesByIdsArgs = {
  ids: Array<Scalars['String']>;
};


export type QueryGetAcPaymentByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetAcPaymentPreviewJournalEntryLinesArgs = {
  data: GetPreviewAcPaymentJournalEntryLinesInput;
};


export type QueryGetAcPaymentsByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
  filter?: InputMaybe<AcPaymentFilterInput>;
};


export type QueryGetAcPaymentsByPayableIdArgs = {
  payableId: Scalars['String'];
};


export type QueryGetAcReceiptByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetAcReceiptPreviewJournalEntryLinesArgs = {
  data: GetPreviewAcReceiptJournalEntryLinesInput;
};


export type QueryGetAcReceiptsByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
  filter?: InputMaybe<AcReceiptFilterInput>;
};


export type QueryGetAcReceiptsByReceivableIdArgs = {
  receivableId: Scalars['String'];
};


export type QueryGetAcReceivableByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetAcReceivableInvoiceNumberDuplicateArgs = {
  accountInfoId: Scalars['String'];
  invoiceNumber: Scalars['String'];
  vendorId: Scalars['String'];
  viewedReceivableId?: InputMaybe<Scalars['String']>;
};


export type QueryGetAcReceivablePreviewJournalEntryLinesArgs = {
  data: GetPreviewAcReceivableJournalEntryLinesInput;
};


export type QueryGetAcReceivableTemplateByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetAcReceivableTemplatesByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
  filter?: InputMaybe<AcReceivableTemplateFilterInput>;
};


export type QueryGetAcReceivableTemplatesByIdsArgs = {
  ids: Array<Scalars['String']>;
};


export type QueryGetAcReceivablesByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
  filter?: InputMaybe<AcReceivableFilterInput>;
};


export type QueryGetAcReceivablesByAccountInfoIdAndReceivableIdsArgs = {
  accountInfoId: Scalars['String'];
  filter?: InputMaybe<AcReceivableFilterInput>;
  receivableIds?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryGetAcReceivablesByIdsArgs = {
  ids: Array<Scalars['String']>;
};


export type QueryGetAccommodationAmountByDateArgs = {
  date: Scalars['LocalDate'];
};


export type QueryGetAccommodationAmountByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetAccountByInfoIdArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetAccountCarriersArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetAccountInfoByFriendlyIdArgs = {
  friendlyId: Scalars['Int'];
};


export type QueryGetAccountInfoByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetAccountInfoByUserIdArgs = {
  id: Scalars['String'];
};


export type QueryGetAccountRatingByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetAccountingImportJobArgs = {
  jobId: Scalars['String'];
};


export type QueryGetAccountingImportJobsArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetActiveAgentsByRolesArgs = {
  roles: Array<AgentRole>;
};


export type QueryGetActiveAgentsBySupervisorAgentIdArgs = {
  agentId: Scalars['String'];
};


export type QueryGetActiveContractsByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetAdjacentPerDiemEventsLengthsArgs = {
  data: GetAdjacentPerDiemEventsLengthsInput;
};


export type QueryGetAgentByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetAgentsByAuthorizedUserArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryGetAgentsByRolesArgs = {
  roles: Array<AgentRole>;
};


export type QueryGetAlertSettingsByUserIdArgs = {
  id: Scalars['String'];
};


export type QueryGetAllAccountsByDefaultAgentArgs = {
  agentId: Scalars['String'];
  type?: InputMaybe<AccountTypeEnum>;
};


export type QueryGetAllAccountsByManagerArgs = {
  managerId: Scalars['String'];
};


export type QueryGetAllAccountsWithInactiveAgentsArgs = {
  type: AccountTypeEnum;
};


export type QueryGetAllAgentsArgs = {
  organizationId: Scalars['String'];
};


export type QueryGetAllContractsByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetAllFileArchivesByAccountIdArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetAllFiscalYearRangesArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetAllTicketsByUserIdArgs = {
  userId: Scalars['String'];
};


export type QueryGetAnnualSavingsReportByAccountIdArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetAreFuturesPaidArgs = {
  eventId: Scalars['String'];
};


export type QueryGetAuthorizedRepresentativeByIdArgs = {
  authorizedRepresentativeId: Scalars['String'];
};


export type QueryGetAuthorizedRepresentativesByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetAverageDaysToReceiveDocumentsArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetBalanceArgs = {
  glAccountId: Scalars['String'];
  range: BalanceFilterInput;
};


export type QueryGetBalanceSheetArgs = {
  accountInfoId: Scalars['String'];
  filter: BalanceSheetFilterInput;
};


export type QueryGetBasicAccountByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetBusinessNumberArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetBusinessReportArgs = {
  accountInfoId: Scalars['String'];
  endDate: Scalars['LocalDate'];
  startDate: Scalars['LocalDate'];
};


export type QueryGetCaftReleaseArgs = {
  id: Scalars['String'];
};


export type QueryGetCalendarEventByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetCalendarEventsByEmployeeIdsArgs = {
  accountInfoId: Scalars['String'];
  employeeIds?: InputMaybe<Array<Scalars['String']>>;
  filter?: InputMaybe<CalendarEventFilterInput>;
};


export type QueryGetChartOfAccountsByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetChartOfAccountsByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetChartOfAccountsTemplateByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetCommunicationFeedItemsArgs = {
  accountInfoId?: InputMaybe<Scalars['String']>;
  endDate: Scalars['LocalDate'];
  filter?: InputMaybe<Array<CommunicationTypeEnum>>;
  leadId?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  startDate: Scalars['LocalDate'];
};


export type QueryGetContactByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetContactsByAuthorizedUserIdArgs = {
  userId: Scalars['String'];
};


export type QueryGetContractByIdArgs = {
  contractId: Scalars['String'];
};


export type QueryGetContractTemplateByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetContractTemplatesByOrganizationArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetCorporateAccountByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetCorporateAssetByIdArgs = {
  assetId: Scalars['String'];
};


export type QueryGetCorporateAssetGlAccountDataArgs = {
  data: GetAssetPreviewGlDataInput;
};


export type QueryGetCorporateAssetsByAccountIdArgs = {
  accountInfoId: Scalars['String'];
  filter?: InputMaybe<AssetControllerFilterType>;
};


export type QueryGetCorporateOverviewArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetCorporateTaxesEventsArgs = {
  accountInfoId: Scalars['String'];
  taxYear: Scalars['Int'];
};


export type QueryGetCorporateTaxesPaidByIdArgs = {
  corporateTaxesPaidId: Scalars['String'];
};


export type QueryGetDirectorByIdArgs = {
  directorId: Scalars['String'];
};


export type QueryGetDirectorsByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetDocumentByIdArgs = {
  documentId: Scalars['String'];
};


export type QueryGetDocumentSortingClassByIdArgs = {
  documentSortingClassId: Scalars['String'];
};


export type QueryGetDocumentTypeBreakdownArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetDocumentTypeByIdArgs = {
  documentTypeId: Scalars['String'];
};


export type QueryGetDocumentTypesArgs = {
  includeArchived: Scalars['Boolean'];
};


export type QueryGetDocumentTypesByGroupArgs = {
  group: DocumentGroupEnum;
};


export type QueryGetDocumentUrlArgs = {
  documentId: Scalars['String'];
};


export type QueryGetDocumentsByAccountArgs = {
  accountInfoId: Scalars['String'];
  filters: DocumentsFilterInput;
};


export type QueryGetDocumentsByStatementArgs = {
  documentId: Scalars['String'];
};


export type QueryGetDocumentsForJournalEntriesArgs = {
  accountInfoId: Scalars['String'];
  documentId?: InputMaybe<Scalars['String']>;
};


export type QueryGetDomesticReductionRateByDateArgs = {
  date: Scalars['LocalDate'];
};


export type QueryGetDraftDocumentByIdArgs = {
  draftDocumentId: Scalars['String'];
};


export type QueryGetDraftDocumentsByDraftDocumentSourceIdArgs = {
  draftDocumentSourceId: Scalars['String'];
};


export type QueryGetEiRateByDateArgs = {
  date: Scalars['LocalDate'];
};


export type QueryGetEiRateByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetEmployeeByIdArgs = {
  employeeId: Scalars['String'];
};


export type QueryGetEmployeeReportArgs = {
  employeeId: Scalars['String'];
};


export type QueryGetEmployeesByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
  filter?: InputMaybe<EmployeeFilterInput>;
};


export type QueryGetEmployeesForAgentArgs = {
  agentId: Scalars['String'];
};


export type QueryGetExchangeRateArgs = {
  baseCurrency: Scalars['String'];
  currency: Scalars['String'];
  date: Scalars['LocalDate'];
};


export type QueryGetExpectedDocumentByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetExpectedDocumentExceptionByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetExpectedDocumentsByAccountArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetExpenseByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetExpensesByAccountIdArgs = {
  accountInfoId: Scalars['String'];
  filters: ExpenseFilterInput;
};


export type QueryGetFileArchiveUrlArgs = {
  fileArchiveId: Scalars['String'];
};


export type QueryGetFileInfoByIdArgs = {
  fileInfoId: Scalars['String'];
};


export type QueryGetFileInfoUrlArgs = {
  fileInfoId: Scalars['String'];
};


export type QueryGetFileInfoUrlFromSourceDocumentIdArgs = {
  draftDocumentSourceId: Scalars['String'];
};


export type QueryGetFileInfosByAccountArgs = {
  accountInfoId: Scalars['String'];
  filters: FileInfosFilterInput;
};


export type QueryGetFileUrlByFilePathArgs = {
  filePath: Scalars['String'];
};


export type QueryGetFileUrlByDraftDocumentIdArgs = {
  draftDocumentId: Scalars['String'];
};


export type QueryGetFiledListArgs = {
  accountInfoId: Scalars['String'];
  endDate?: InputMaybe<Scalars['LocalDate']>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
  type?: InputMaybe<GstFiledDropdownTypeEnum>;
};


export type QueryGetFilteredTicketsArgs = {
  data: GetFilteredTicketsInput;
};


export type QueryGetFinancialAccountByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
  filter?: InputMaybe<FinancialAccountFilterType>;
};


export type QueryGetFinancialAccountByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetFinancialAccountPreviewDataArgs = {
  data: GetAccountsPreviewInput;
};


export type QueryGetFirstJournalEntryArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetFiscalYearGroupedAccountsByDefaultAgentArgs = {
  agentId: Scalars['String'];
  type?: InputMaybe<AccountTypeEnum>;
};


export type QueryGetFuelTaxRateByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetGeneralLedgerAccountByIdArgs = {
  generalLedgerAccountId: Scalars['String'];
};


export type QueryGetGeneralLedgerAccountTemplateByIdArgs = {
  generalLedgerAccountTemplateId: Scalars['String'];
};


export type QueryGetGeneralLedgerReportArgs = {
  accountInfoId: Scalars['String'];
  filter?: InputMaybe<JournalEntryFilterInput>;
};


export type QueryGetGstDepositedByIdArgs = {
  depositedGstId: Scalars['String'];
};


export type QueryGetGstFiledByIdArgs = {
  gstFiledId: Scalars['String'];
};


export type QueryGetGstFilingFrequencyArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetGstHeldByIdArgs = {
  gstHeldId: Scalars['String'];
};


export type QueryGetGstPaidByIdArgs = {
  gstPaidId: Scalars['String'];
};


export type QueryGetImageUrlArgs = {
  filePath: Scalars['String'];
};


export type QueryGetIncomeStatementArgs = {
  accountInfoId: Scalars['String'];
  filter: IncomeStatementFilterInput;
};


export type QueryGetIncorporationDateArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetInvoiceByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetIsPreviousMonthPayedArgs = {
  employeeId: Scalars['String'];
  eventType: CalendarEventTypeEnum;
  firstDayOfCurrentMonth: Scalars['LocalDate'];
};


export type QueryGetJournalEntriesArgs = {
  accountInfoId: Scalars['String'];
  filter?: InputMaybe<JournalEntryFilterInput>;
};


export type QueryGetJournalEntriesByDocumentIdArgs = {
  documentId: Scalars['String'];
};


export type QueryGetJournalEntryByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetJournalEntryTemplateByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetJournalEntryTemplatesByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
  filter?: InputMaybe<JournalEntryTemplateFilterInput>;
};


export type QueryGetJournalEntryTemplatesByIdsArgs = {
  ids: Array<Scalars['String']>;
};


export type QueryGetLastClosedDateArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetLastFiscalYearArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetLatestTimeCardArgs = {
  agentId: Scalars['String'];
};


export type QueryGetLeadByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetLeadsReportArgs = {
  status: LeadStatusEnum;
};


export type QueryGetLikeLeadsArgs = {
  data: LikeLeadInput;
};


export type QueryGetLikeVendorsArgs = {
  accountInfoId: Scalars['String'];
  name: Scalars['String'];
};


export type QueryGetManagementBonusByIdArgs = {
  managementBonusId: Scalars['String'];
};


export type QueryGetMinimumWageByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetMissingDocumentsArgs = {
  accountInfoId: Scalars['String'];
  endDate: Scalars['LocalDate'];
  startDate: Scalars['LocalDate'];
};


export type QueryGetMissingFilesReportArgs = {
  cursor?: InputMaybe<Scalars['String']>;
};


export type QueryGetNextFiscalYearRangeArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetNotificationsByUserIdArgs = {
  id: Scalars['String'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};


export type QueryGetNumberOfUserEventsByAccountIdArgs = {
  filter?: InputMaybe<CountUserEventsFilterInput>;
  id: Scalars['String'];
};


export type QueryGetNumberOfUserEventsByAgentIdArgs = {
  filter?: InputMaybe<CountUserEventsFilterInput>;
  id: Scalars['String'];
};


export type QueryGetNumberOfUserEventsByUserIdArgs = {
  filter?: InputMaybe<CountUserEventsFilterInput>;
  id: Scalars['String'];
};


export type QueryGetOfficeByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetOfficeReportArgs = {
  officeId: Scalars['String'];
};


export type QueryGetOfficesByOrganizationArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['String']>;
};


export type QueryGetOrganizationByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetOutstandingInvoicesByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetPartnerByIdArgs = {
  partnerId: Scalars['String'];
};


export type QueryGetPartnersByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetPayStatementByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetPayStatementsByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
  filters?: InputMaybe<PayStatementFilterInput>;
};


export type QueryGetPayStubByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetPayStubsByAccountArgs = {
  accountInfoId: Scalars['String'];
  filter?: InputMaybe<PayStubsFilterInput>;
};


export type QueryGetPayStubsNextIntervalDatesArgs = {
  employeeId: Scalars['String'];
};


export type QueryGetPaymentByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetPaymentScheduleByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
  from?: InputMaybe<Scalars['LocalDate']>;
  to?: InputMaybe<Scalars['LocalDate']>;
};


export type QueryGetPersonalAccountByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetPersonalVehicleByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetPersonalVehiclesByAccountIdArgs = {
  accountInfoId: Scalars['String'];
  filter?: InputMaybe<GetPersonalVehiclesFilterInput>;
};


export type QueryGetPodcastByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetPodcastsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};


export type QueryGetPvlRateByDateArgs = {
  date: Scalars['LocalDate'];
  employee: Scalars['String'];
};


export type QueryGetPvlRateByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetRecentAccountsByAgentIdArgs = {
  agentId: Scalars['String'];
};


export type QueryGetReductionRateByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetRegistrationInviteLinkArgs = {
  contactId: Scalars['String'];
  registrationPageEndpoint: Scalars['String'];
};


export type QueryGetRelatedAccountsArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetReminderByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetRemindersByUserIdArgs = {
  id: Scalars['String'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  type?: InputMaybe<ReminderFilter>;
};


export type QueryGetScheduledPaymentByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetSelfEmployedAccountByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetSelfEmployedOverviewArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetSortedDocsByDraftDocumentSourceIdArgs = {
  draftDocumentSourceId: Scalars['String'];
};


export type QueryGetSortedDocsByFileInfoIdArgs = {
  fileInfoId: Scalars['String'];
};


export type QueryGetSorterOverviewArgs = {
  agentId: Scalars['String'];
};


export type QueryGetSourceDeductionByAccountInfoAndYearArgs = {
  accountInfoId: Scalars['String'];
  year: Scalars['Int'];
};


export type QueryGetSourceDeductionOpeningBalanceByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetSourceDeductionPayableByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetSourceDeductionPaymentByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetSourceDeductionStatementBalanceByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetSourceDeductionsStatementBalanceByAccountInfoAndYearArgs = {
  accountInfoId: Scalars['String'];
  year: Scalars['Int'];
};


export type QueryGetStatHolidayPayArgs = {
  agentId: Scalars['String'];
};


export type QueryGetStatementByDocumentIdArgs = {
  documentId: Scalars['String'];
};


export type QueryGetStatementByFinancialAccountIdArgs = {
  financialAccountId: Scalars['String'];
};


export type QueryGetStatementByIdArgs = {
  statementId: Scalars['String'];
};


export type QueryGetT1ByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetT1ByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetT2FiledByIdArgs = {
  t2FiledId: Scalars['String'];
};


export type QueryGetT4ByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetT4ByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetT4SummaryByAccountArgs = {
  accountInfoId: Scalars['String'];
  year: Scalars['Int'];
};


export type QueryGetT5ByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetT5sByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
  endDate: Scalars['LocalDate'];
  startDate: Scalars['LocalDate'];
};


export type QueryGetTaxRateByDateAndProvinceArgs = {
  date: Scalars['LocalDate'];
  province: ProvinceEnum;
};


export type QueryGetTaxRateByDateAndProvinceByEmployeeArgs = {
  date: Scalars['LocalDate'];
  employee: Scalars['String'];
};


export type QueryGetTaxRateByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetTicketByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetTicketCategoryByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetTicketReplyByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetTimeCardByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetTimeCardsArgs = {
  agentId: Scalars['String'];
};


export type QueryGetTransactionsByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
  from?: InputMaybe<Scalars['LocalDate']>;
  to?: InputMaybe<Scalars['LocalDate']>;
};


export type QueryGetTreasuryAmountByDateAndLocationByEmployeeArgs = {
  date: Scalars['LocalDate'];
  employee: Scalars['String'];
};


export type QueryGetTreasuryAmountByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetUnsortedFilesByAccountArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetUsageReportByAccountIdArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetUserAccountInfoArgs = {
  id: Scalars['String'];
};


export type QueryGetUserActivityByAgentIdArgs = {
  agentId: Scalars['String'];
  filter: UserActivityFilter;
};


export type QueryGetUserActivityByUserIdArgs = {
  filter: UserActivityFilter;
  userId: Scalars['String'];
};


export type QueryGetUserByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetUserEventsByAccountArgs = {
  filter?: InputMaybe<UserEventFilterInput>;
  id: Scalars['String'];
};


export type QueryGetUserEventsByAgentArgs = {
  filter?: InputMaybe<UserEventFilterInput>;
  id: Scalars['String'];
};


export type QueryGetUserEventsByUserArgs = {
  filter?: InputMaybe<UserEventFilterInput>;
  id: Scalars['String'];
};


export type QueryGetVendorByIdArgs = {
  vendorId: Scalars['String'];
};


export type QueryGetVendorsByAccountInfoArgs = {
  accountInfoId: Scalars['String'];
  archived: Scalars['Boolean'];
  includeGlobal: Scalars['Boolean'];
};


export type QueryGetYearEndByAccountInfoIdArgs = {
  accountInfoId: Scalars['String'];
};


export type QueryGetYearEndReportByAgentArgs = {
  agentId: Scalars['String'];
};


export type QueryGetYearEndReportByAgentsOrganizationArgs = {
  agentId: Scalars['String'];
};


export type QueryGlAccountsInUseArgs = {
  ids: Array<Scalars['String']>;
};


export type QueryPerformOpeningBalanceTrialBalanceCheckArgs = {
  data: HistoricalDataEntryInput;
};

export enum ReceiveMethodEnum {
  App = 'App',
  Email = 'Email',
  SystemGenerated = 'SystemGenerated',
  Unknown = 'Unknown',
  Website = 'Website'
}

export enum RecurrencePatternEnum {
  Annually = 'annually',
  BiMonthly = 'biMonthly',
  BiWeekly = 'biWeekly',
  Daily = 'daily',
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Random = 'random',
  SemiAnnually = 'semiAnnually',
  SemiMonthly = 'semiMonthly',
  ThirteenPeriod = 'thirteenPeriod',
  Weekly = 'weekly'
}

export enum ReferralEnum {
  Billboard = 'Billboard',
  ClientReferral = 'ClientReferral',
  Other = 'Other',
  Podcast = 'Podcast',
  SocialMedia = 'SocialMedia',
  TradeShow = 'TradeShow',
  WebSearch = 'WebSearch'
}

export type Reminder = {
  __typename?: 'Reminder';
  createdAt: Scalars['DateTime'];
  createdBy: Agent;
  dateTimeSent?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  message: Scalars['String'];
  scheduledDeliveryDay: Scalars['LocalDate'];
  sendTo: Agent;
  version: Scalars['Int'];
};

export enum ReminderFilter {
  Other = 'Other',
  Past = 'Past',
  Upcoming = 'Upcoming'
}

export type Report = {
  __typename?: 'Report';
  assets: AccountingReport;
  equities: AccountingReport;
  expenses: AccountingReport;
  gst: BusinessReport;
  liabilities: AccountingReport;
  revenue: BusinessReport;
};

export type Sai = {
  __typename?: 'Sai';
  companyName: Scalars['String'];
  companyType: Scalars['Int'];
  dataCountryType: Scalars['Int'];
  dataVersion: Scalars['Int'];
  databaseType: Scalars['Int'];
  fiscalEndDate: Scalars['LocalDate'];
  fiscalStartDate: Scalars['LocalDate'];
  latestTransactionDate: Scalars['LocalDate'];
  product: Scalars['String'];
  sessionDate: Scalars['LocalDate'];
};

export type ScheduledPayment = {
  __typename?: 'ScheduledPayment';
  accountInfo: AccountInfo;
  amount: Scalars['Money'];
  amountOwing: Scalars['Money'];
  date: Scalars['LocalDate'];
  id: Scalars['String'];
  version: Scalars['Int'];
};

export type SearchQueryInput = {
  query: Scalars['String'];
  searchAuthorizedRepresentative: Scalars['Boolean'];
  searchBasic: Scalars['Boolean'];
  searchCorporate: Scalars['Boolean'];
  searchInactive: Scalars['Boolean'];
  searchLead: Scalars['Boolean'];
  searchPersonal: Scalars['Boolean'];
  searchSelfEmployed: Scalars['Boolean'];
  searchUser: Scalars['Boolean'];
};

export type SearchResultItem = {
  __typename?: 'SearchResultItem';
  accountInfoId?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  businessNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  friendlyId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  organizationId?: Maybe<Scalars['String']>;
  people?: Maybe<Array<Maybe<Scalars['String']>>>;
  phoneNumber?: Maybe<Scalars['String']>;
  type: SearchTypEnum;
};

export type SearchResults = {
  __typename?: 'SearchResults';
  hits: Hits;
  timed_out: Scalars['Boolean'];
  took: Scalars['Int'];
};

export type SearchStatistics = {
  __typename?: 'SearchStatistics';
  account: Scalars['Int'];
  authorizedRepresentative: Scalars['Int'];
  index: Scalars['Int'];
  lead: Scalars['Int'];
  sync: Scalars['Boolean'];
  user: Scalars['Int'];
};

export enum SearchTypEnum {
  AuthorizedRepresentative = 'AuthorizedRepresentative',
  BasicAccount = 'BasicAccount',
  CorporateAccount = 'CorporateAccount',
  Lead = 'Lead',
  PersonalAccount = 'PersonalAccount',
  SelfEmployedAccount = 'SelfEmployedAccount',
  User = 'User'
}

export type SelfEmployedAccount = {
  __typename?: 'SelfEmployedAccount';
  accountInfo: AccountInfo;
  businessNumber?: Maybe<Scalars['String']>;
  communicationCode?: Maybe<Scalars['String']>;
  employees?: Maybe<Array<Maybe<Employee>>>;
  gstFilingFrequency: GstFilingFrequencyEnum;
  id: Scalars['String'];
  partners?: Maybe<Array<Maybe<Partner>>>;
  version: Scalars['Int'];
  yearEnd?: Maybe<Scalars['LocalDate']>;
};


export type SelfEmployedAccountEmployeesArgs = {
  filters?: InputMaybe<EmployeeFilterInput>;
};

export type SelfEmployedOverview = {
  __typename?: 'SelfEmployedOverview';
  accountNotices?: Maybe<Array<AccountNotice>>;
  fiscalEnd?: Maybe<Scalars['LocalDate']>;
  fiscalStart?: Maybe<Scalars['LocalDate']>;
  gst?: Maybe<Scalars['LocalDate']>;
  id?: Maybe<Scalars['String']>;
  unprocessedDocuments?: Maybe<Scalars['Int']>;
  unsortedFiles: Scalars['Int'];
};

export type Service = {
  __typename?: 'Service';
  blacklist?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['String'];
  version: Scalars['Int'];
};

export type ServiceInput = {
  blacklist?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};

export type SetCaftOptionsInput = {
  fileCreationNum: Scalars['Int'];
  filter: Array<Scalars['String']>;
  paymentDaysAhead: Scalars['Int'];
  schedPayDaysAhead: Scalars['Int'];
};

export type SimplyCompany = {
  __typename?: 'SimplyCompany';
  apGlAccount: Scalars['Int'];
  arGlAccount: Scalars['Int'];
  businessNumber: Scalars['String'];
  fiscalEndDate: Scalars['LocalDate'];
  fiscalStartDate: Scalars['LocalDate'];
  name: Scalars['String'];
};

export type SimplyContact = {
  __typename?: 'SimplyContact';
  id: Scalars['Int'];
  module: SimplyContactModule;
  name: Scalars['String'];
};

export enum SimplyContactModule {
  Customer = 'customer',
  Vendor = 'vendor'
}

export type SimplyContactNoModule = {
  __typename?: 'SimplyContactNoModule';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type SorterOverview = {
  __typename?: 'SorterOverview';
  draftDocumentSources?: Maybe<Array<DraftDocumentSource>>;
  office?: Maybe<Office>;
};

export type SourceDeductionOpeningBalance = {
  __typename?: 'SourceDeductionOpeningBalance';
  accountInfo: AccountInfo;
  amount: Scalars['Money'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  version: Scalars['Int'];
  year: Scalars['Int'];
};

export type SourceDeductionPayable = {
  __typename?: 'SourceDeductionPayable';
  accountInfo: AccountInfo;
  amount: Scalars['Money'];
  date: Scalars['LocalDate'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  period: Scalars['LocalDate'];
  version: Scalars['Int'];
};

export type SourceDeductionPayment = {
  __typename?: 'SourceDeductionPayment';
  accountInfo: AccountInfo;
  chequeNumber?: Maybe<Scalars['Int']>;
  date: Scalars['LocalDate'];
  id: Scalars['String'];
  lineItems: Array<SourceDeductionPaymentLineItem>;
  nsf: Scalars['Boolean'];
  nsfDate?: Maybe<Scalars['LocalDate']>;
  paymentType: SourceDeductionPaymentTypeEnum;
  period: Scalars['LocalDate'];
  version: Scalars['Int'];
};

export type SourceDeductionPaymentLineItem = {
  __typename?: 'SourceDeductionPaymentLineItem';
  amount: Scalars['Money'];
  arrears: Scalars['Boolean'];
  description: Scalars['String'];
  id: Scalars['String'];
  version: Scalars['Int'];
};

export type SourceDeductionPaymentLineItemInput = {
  amount: Scalars['Money'];
  arrears: Scalars['Boolean'];
  description: Scalars['String'];
};

export enum SourceDeductionPaymentTypeEnum {
  Cheque = 'cheque',
  Online = 'online'
}

export type SourceDeductionRow = PayStubRow | PayableRow | PaymentRow;

export type SourceDeductionRowInterface = {
  date: Scalars['LocalDate'];
  id: Scalars['String'];
  period: Scalars['LocalDate'];
  type: SourceDeductionRowType;
  version: Scalars['Int'];
};

export enum SourceDeductionRowType {
  OpeningBalance = 'openingBalance',
  PayStub = 'payStub',
  PayStubTotal = 'payStubTotal',
  Payable = 'payable',
  Payment = 'payment'
}

export type SourceDeductionStatementBalance = {
  __typename?: 'SourceDeductionStatementBalance';
  accountInfo: AccountInfo;
  amount: Scalars['Money'];
  id: Scalars['String'];
  period: Scalars['LocalDate'];
  version: Scalars['Int'];
};

export type SourceDeductionYear = {
  __typename?: 'SourceDeductionYear';
  openingBalance?: Maybe<SourceDeductionOpeningBalance>;
  rows: Array<SourceDeductionRow>;
};

export type SpacesCleanup = {
  __typename?: 'SpacesCleanup';
  basePath: Scalars['String'];
  bucket: Scalars['String'];
  connector: Scalars['String'];
  expiryDate: Scalars['LocalDate'];
  id: Scalars['String'];
  path: Scalars['String'];
  type: SpacesCleanupTypeEnum;
  version: Scalars['Int'];
};

export enum SpacesCleanupTypeEnum {
  Archive = 'archive',
  Document = 'document'
}

export type SpecificAccount = BasicAccount | CorporateAccount | PersonalAccount | SelfEmployedAccount;

export type Statement = {
  __typename?: 'Statement';
  closingBalance: Scalars['Money'];
  document: Document;
  financialAccount: FinancialAccount;
  id: Scalars['String'];
  openingBalance: Scalars['Money'];
  statementLines: Array<StatementLine>;
  version: Scalars['Int'];
};

export type StatementLine = {
  __typename?: 'StatementLine';
  accountInfo: AccountInfo;
  credit?: Maybe<Scalars['Money']>;
  date?: Maybe<Scalars['LocalDate']>;
  debit?: Maybe<Scalars['Money']>;
  description?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
  id: Scalars['String'];
  reconciled: Scalars['Boolean'];
  statement: Statement;
  status?: Maybe<StatementStatusEnum>;
  version: Scalars['Int'];
};

export enum StatementStatusEnum {
  DocumentRequired = 'DocumentRequired',
  NotApplicable = 'NotApplicable',
  NotProvided = 'NotProvided',
  Pending = 'Pending'
}

export type StorageUsage = {
  __typename?: 'StorageUsage';
  accountInfoId: Scalars['String'];
  accountName: Scalars['String'];
  bytesUsed?: Maybe<Scalars['Float']>;
  fileCount: Scalars['Int'];
  friendlyId: Scalars['Int'];
};

export type Subscription = {
  __typename?: 'Subscription';
  root?: Maybe<Scalars['String']>;
};

export type T1 = {
  __typename?: 'T1';
  confirmationNumber: Scalars['String'];
  filedOn: Scalars['LocalDate'];
  id: Scalars['String'];
  personalAccount: PersonalAccount;
  taxYear: Scalars['Int'];
  taxes: Scalars['Money'];
  version: Scalars['Int'];
};

export type T2Filed = {
  __typename?: 'T2Filed';
  accountInfo: AccountInfo;
  confirmationNumber?: Maybe<Scalars['String']>;
  filedOnDate: Scalars['LocalDate'];
  filingMethod: FilingMethodEnum;
  id: Scalars['String'];
  taxAmount: Scalars['Money'];
  taxYear: Scalars['Int'];
  version: Scalars['Int'];
};

export type T4 = {
  __typename?: 'T4';
  confirmationNumber: Scalars['String'];
  corporateAccount: CorporateAccount;
  filedOn: Scalars['LocalDate'];
  id: Scalars['String'];
  taxYear: Scalars['Int'];
  version: Scalars['Int'];
};

export type T4Summary = {
  __typename?: 'T4Summary';
  cpp?: Maybe<Scalars['Money']>;
  cpp2?: Maybe<Scalars['Money']>;
  ei?: Maybe<Scalars['Money']>;
  employee?: Maybe<Employee>;
  grossIncome?: Maybe<Scalars['Money']>;
  id?: Maybe<Scalars['String']>;
  incomeTax?: Maybe<Scalars['Money']>;
};

export type T5 = {
  __typename?: 'T5';
  confirmationNumber: Scalars['String'];
  corporateAccount: CorporateAccount;
  dividendAmount: Scalars['Money'];
  filedOn: Scalars['LocalDate'];
  id: Scalars['String'];
  taxYear: Scalars['Int'];
  version: Scalars['Int'];
};

export type TaxRate = {
  __typename?: 'TaxRate';
  effectiveDate: Scalars['LocalDate'];
  gstHst: Scalars['Float'];
  id: Scalars['String'];
  province: ProvinceEnum;
  pst: Scalars['Float'];
  version: Scalars['Int'];
};

export type Ticket = {
  __typename?: 'Ticket';
  assignedTo: Agent;
  attachment?: Maybe<Scalars['String']>;
  category: TicketCategory;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  description: Scalars['String'];
  id: Scalars['String'];
  priority: TicketPriorityEnum;
  status: TicketStatusEnum;
  ticketNumber: Scalars['Int'];
  ticketReplies?: Maybe<Array<TicketReply>>;
  version: Scalars['Int'];
};

export type TicketCategory = {
  __typename?: 'TicketCategory';
  defaultAgent: Agent;
  id: Scalars['String'];
  name: Scalars['String'];
  version: Scalars['Int'];
};

export enum TicketPriorityEnum {
  Emergency = 'Emergency',
  High = 'High',
  Low = 'Low',
  Normal = 'Normal'
}

export type TicketReply = {
  __typename?: 'TicketReply';
  attachment?: Maybe<Scalars['String']>;
  createdBy: User;
  id: Scalars['String'];
  message: Scalars['String'];
  replyTime: Scalars['DateTime'];
  ticket: Ticket;
  version: Scalars['Int'];
};

export enum TicketStatusEnum {
  Closed = 'Closed',
  Open = 'Open',
  Resolved = 'Resolved'
}

export type TimeCard = {
  __typename?: 'TimeCard';
  agent: Agent;
  bankExtraHours: Scalars['Boolean'];
  cpp: Scalars['Money'];
  earnedBankedHours: Scalars['Int'];
  earnedVacationPay: Scalars['Money'];
  ei: Scalars['Money'];
  gross: Scalars['Money'];
  id: Scalars['String'];
  incomeTax: Scalars['Money'];
  paid: Scalars['Boolean'];
  payOutVacation: Scalars['Boolean'];
  payPeriodEnd: Scalars['LocalDate'];
  payPeriodStart: Scalars['LocalDate'];
  timeCardLines?: Maybe<Array<TimeCardLine>>;
  useBankedHours?: Maybe<Scalars['Float']>;
  useBankedVacationPay?: Maybe<Scalars['Money']>;
  vacationPayRate: Scalars['Int'];
  version: Scalars['Int'];
};

export type TimeCardLine = {
  __typename?: 'TimeCardLine';
  afternoonIn: Scalars['Float'];
  afternoonOut: Scalars['Float'];
  date: Scalars['LocalDate'];
  hourlyPay: Scalars['Money'];
  id: Scalars['String'];
  morningIn: Scalars['Float'];
  morningOut: Scalars['Float'];
  publicHolidayPay?: Maybe<Scalars['Money']>;
  timeCard: TimeCard;
  version: Scalars['Int'];
};

export type TimeClockEvent = {
  __typename?: 'TimeClockEvent';
  time: Scalars['LocalTime'];
  type: Scalars['String'];
};

export type Transactions = {
  __typename?: 'Transactions';
  balance: Scalars['Money'];
  invoices: Array<Invoice>;
  payments: Array<Payment>;
  scheduledBalance: Scalars['Money'];
  scheduledPayments: Array<ScheduledPayment>;
};

export type TreasuryAmount = {
  __typename?: 'TreasuryAmount';
  effectiveDate: Scalars['LocalDate'];
  id: Scalars['String'];
  incidentalExpenseAllowance: Scalars['Money'];
  location: LocationEnum;
  mealAllowance: Scalars['Money'];
  version: Scalars['Int'];
};

export type TrialBalanceResult = {
  __typename?: 'TrialBalanceResult';
  results: Array<BalanceComparisonResult>;
  success?: Maybe<Scalars['Boolean']>;
  validDates: Scalars['Boolean'];
};

export type UpdateDocumentInput = {
  amount?: InputMaybe<Scalars['Money']>;
  cash: Scalars['Boolean'];
  corporateAsset?: InputMaybe<Scalars['String']>;
  date: Scalars['LocalDate'];
  employee?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  status: DocumentStatusEnum;
  type: Scalars['String'];
  vendor: Scalars['String'];
  version: Scalars['Int'];
};

export type UpdateDocumentSortingClassInput = {
  active: Scalars['Boolean'];
  amountRequired: Scalars['Boolean'];
  className: Scalars['String'];
  id: Scalars['String'];
  partialSortingRequired: Scalars['Boolean'];
  type: Scalars['String'];
  vendor: Scalars['String'];
  version: Scalars['Int'];
};

export type UpdateDraftDocumentInput = {
  amount?: InputMaybe<Scalars['Money']>;
  cash: Scalars['Boolean'];
  corporateAsset?: InputMaybe<Scalars['String']>;
  createdDocument: Scalars['Boolean'];
  date: Scalars['LocalDate'];
  displayName: Scalars['String'];
  employee?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  status: DocumentStatusEnum;
  type?: InputMaybe<Scalars['String']>;
  vendor?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type UpdateMaintenanceLogEventDataInput = {
  amount: Scalars['Money'];
  corporateAssetId: Scalars['String'];
  odometer?: InputMaybe<Scalars['Int']>;
  serviceDescription: Scalars['String'];
  vendor: Scalars['String'];
};

export type UpdatePerDiemEventDataInput = {
  eliminateShowerAndAmenities: Scalars['Boolean'];
  location: LocationEnum;
  overnight: Scalars['Boolean'];
  paid?: InputMaybe<Scalars['Boolean']>;
};

export type UpdatePvlEventDataInput = {
  endingKM: Scalars['Float'];
  options?: InputMaybe<Array<Scalars['String']>>;
  otherReason?: InputMaybe<Scalars['String']>;
  paid?: InputMaybe<Scalars['Boolean']>;
  personalVehicleId: Scalars['String'];
  startingKM: Scalars['Float'];
};

export type User = {
  __typename?: 'User';
  activeAgent?: Maybe<Agent>;
  devices?: Maybe<Array<Device>>;
  email: Scalars['String'];
  givenName: Scalars['String'];
  id: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  registrationDate: Scalars['LocalDate'];
  service?: Maybe<Service>;
  surname: Scalars['String'];
  version: Scalars['Int'];
};

export type UserActivity = {
  __typename?: 'UserActivity';
  activity?: Maybe<Array<UserActivityType>>;
  date: Scalars['LocalDate'];
};

export type UserActivityFilter = {
  endDate: Scalars['LocalDate'];
  startDate: Scalars['LocalDate'];
};

export type UserActivityType = {
  __typename?: 'UserActivityType';
  clockedEvents?: Maybe<Array<TimeClockEvent>>;
  score: Scalars['Int'];
  time: Scalars['LocalTime'];
};

export type UserEvent = {
  __typename?: 'UserEvent';
  accountInfo?: Maybe<AccountInfo>;
  action: Scalars['String'];
  agent?: Maybe<Agent>;
  controller: Scalars['String'];
  data?: Maybe<Scalars['String']>;
  feature: Scalars['String'];
  id: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: UserEventTypeEnum;
  user?: Maybe<User>;
  userAgentDescription: Scalars['String'];
};

export type UserEventFilterInput = {
  endDate?: InputMaybe<Scalars['LocalDate']>;
  feature?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
};

export enum UserEventTypeEnum {
  Create = 'create',
  Delete = 'delete',
  Edit = 'edit',
  Other = 'other',
  Reverse = 'reverse',
  Send = 'send'
}

export type Vendor = {
  __typename?: 'Vendor';
  accountInfo?: Maybe<AccountInfo>;
  archived: Scalars['Boolean'];
  createdBy: Agent;
  id: Scalars['String'];
  name: Scalars['String'];
  version: Scalars['Int'];
};

export type YearEndOrganizationReport = {
  __typename?: 'YearEndOrganizationReport';
  office: Office;
  years?: Maybe<Array<Maybe<YearEndYear>>>;
};

export type YearEndYear = {
  __typename?: 'YearEndYear';
  count: Scalars['Int'];
  year: Scalars['String'];
};

export const scalarTypePolicies = {
	AcFiscalYear: {fields: {closeDate: localDateTypePolicy, endDate: localDateTypePolicy, startDate: localDateTypePolicy}},
	AcFiscalYearRange: {fields: {endDate: localDateTypePolicy, startDate: localDateTypePolicy}},
	AcPayable: {fields: {amountOwing: moneyTypePolicy, date: localDateTypePolicy}},
	AcPayableLine: {fields: {gst: moneyTypePolicy, subTotal: moneyTypePolicy, total: moneyTypePolicy}},
	AcPayableTemplate: {fields: {endDate: localDateTypePolicy, lastScheduledDate: localDateTypePolicy, nextScheduledDate: localDateTypePolicy}},
	AcPayableTemplateLine: {fields: {gst: moneyTypePolicy, subTotal: moneyTypePolicy}},
	AcPayment: {fields: {date: localDateTypePolicy, total: moneyTypePolicy}},
	AcPaymentLine: {fields: {amount: moneyTypePolicy}},
	AcReceipt: {fields: {date: localDateTypePolicy, total: moneyTypePolicy}},
	AcReceiptLine: {fields: {amount: moneyTypePolicy}},
	AcReceivable: {fields: {amountOwing: moneyTypePolicy, date: localDateTypePolicy}},
	AcReceivableLine: {fields: {subTotal: moneyTypePolicy, total: moneyTypePolicy}},
	AcReceivableTemplate: {fields: {endDate: localDateTypePolicy, lastScheduledDate: localDateTypePolicy, nextScheduledDate: localDateTypePolicy}},
	AcReceivableTemplateLine: {fields: {subTotal: moneyTypePolicy}},
	AccommodationAmount: {fields: {amount: moneyTypePolicy, effectiveDate: localDateTypePolicy}},
	AccountInfoWithFees: {fields: {fiscalYear: localDateTypePolicy}},
	AccountNotice: {fields: {dateTimeRead: dateTimeTypePolicy}},
	AccountingImportApTransaction: {fields: {amount: moneyTypePolicy, date: localDateTypePolicy, srcDate: localDateTypePolicy}},
	AccountingImportJob: {fields: {dateSubmitted: dateTimeTypePolicy}},
	AccountingImportPreviousYearTransaction: {fields: {amount: moneyTypePolicy, date: localDateTypePolicy}},
	AccountingReport: {fields: {total: moneyTypePolicy}},
	AccountingReportDetails: {fields: {amount: moneyTypePolicy}},
	Agent: {fields: {bankedVacationPay: moneyTypePolicy}},
	Announcement: {fields: {dateTimeCreated: dateTimeTypePolicy, dateTimeSent: dateTimeTypePolicy}},
	AnnualSavingsReport: {fields: {perDiem: moneyTypePolicy, pvl: moneyTypePolicy, tax: moneyTypePolicy, total: moneyTypePolicy}},
	Balance: {fields: {creditBalance: moneyTypePolicy, debitBalance: moneyTypePolicy}},
	BalanceComparisonResult: {
		fields: {actualCredit: moneyTypePolicy, actualDebit: moneyTypePolicy, expectedCredit: moneyTypePolicy, expectedDebit: moneyTypePolicy},
	},
	BusinessReport: {fields: {total: moneyTypePolicy}},
	BusinessReportDetails: {fields: {amount: moneyTypePolicy}},
	CaftBatch: {fields: {createdOn: dateTimeTypePolicy, total: moneyTypePolicy}},
	CaftItem: {fields: {amount: moneyTypePolicy, createdOn: dateTimeTypePolicy}},
	CaftPending: {fields: {amount: moneyTypePolicy, createdOn: dateTimeTypePolicy}},
	CaftRelease: {fields: {createdOn: dateTimeTypePolicy, dueDate: dateTimeTypePolicy, releasedOn: dateTimeTypePolicy, total: moneyTypePolicy}},
	CalendarEvent: {fields: {date: localDateTypePolicy}},
	ChartOfAccounts: {fields: {historicClosingDate: localDateTypePolicy}},
	CommunicationFeedItem: {fields: {createdAt: dateTimeTypePolicy}},
	Contact: {fields: {dateOfBirth: localDateTypePolicy}},
	Contract: {fields: {caftAmount: moneyTypePolicy, endDate: localDateTypePolicy, startDate: localDateTypePolicy}},
	ContractData: {fields: {endDate: localDateTypePolicy}},
	ContractsInfos: {fields: {caftAmount: moneyTypePolicy, feeAmount: moneyTypePolicy}},
	CorporateAccount: {fields: {incorporationDate: localDateTypePolicy, yearEnd: localDateTypePolicy}},
	CorporateAssetToGeneralLedgerAccount: {fields: {currentBalance: moneyTypePolicy}},
	CorporateOverview: {
		fields: {
			arrears: moneyTypePolicy,
			fiscalEnd: localDateTypePolicy,
			fiscalStart: localDateTypePolicy,
			gst: localDateTypePolicy,
			payroll: moneyTypePolicy,
			sourceDeductions: moneyTypePolicy,
		},
	},
	CorporateTaxesEvent: {fields: {date: localDateTypePolicy}},
	CorporateTaxesPaid: {fields: {amount: moneyTypePolicy, datePaid: localDateTypePolicy}},
	Device: {fields: {registeredDate: dateTimeTypePolicy}},
	Director: {fields: {dateElected: localDateTypePolicy, dateSteppedDown: localDateTypePolicy}},
	Document: {fields: {amount: moneyTypePolicy, date: localDateTypePolicy, sortedOn: localDateTypePolicy}},
	DocumentSorterReport: {fields: {endDate: localDateTypePolicy, startDate: localDateTypePolicy}},
	DocumentUploadSourceReport: {fields: {endDate: localDateTypePolicy, startDate: localDateTypePolicy}},
	DomesticReductionRate: {fields: {effectiveDate: localDateTypePolicy}},
	DraftDocument: {fields: {amount: moneyTypePolicy, date: localDateTypePolicy, sortedOn: localDateTypePolicy}},
	EIRate: {fields: {effectiveDate: localDateTypePolicy}},
	Employee: {fields: {endDate: localDateTypePolicy, startDate: localDateTypePolicy}},
	ExpectedDocument: {fields: {endDate: localDateTypePolicy, startDate: localDateTypePolicy}},
	ExpectedDocumentEvent: {fields: {endDate: localDateTypePolicy, expectedDate: localDateTypePolicy, startDate: localDateTypePolicy}},
	ExpectedDocumentException: {fields: {date: localDateTypePolicy}},
	Expense: {fields: {date: localDateTypePolicy, gst: moneyTypePolicy, total: moneyTypePolicy, uiTotal: moneyTypePolicy}},
	Fee: {
		fields: {
			date: localDateTypePolicy,
			lastGeneration: dateTimeTypePolicy,
			subTotal: moneyTypePolicy,
			taxTotal: moneyTypePolicy,
			total: moneyTypePolicy,
		},
	},
	FeeLineItem: {fields: {amount: moneyTypePolicy, gst: moneyTypePolicy, hst: moneyTypePolicy, pst: moneyTypePolicy, qst: moneyTypePolicy}},
	FeeTemplate: {fields: {date: localDateTypePolicy, subTotal: moneyTypePolicy, taxTotal: moneyTypePolicy, total: moneyTypePolicy}},
	FeeTemplateLineItem: {fields: {amount: moneyTypePolicy, gst: moneyTypePolicy, hst: moneyTypePolicy, pst: moneyTypePolicy, qst: moneyTypePolicy}},
	FileArchive: {fields: {creationDate: localDateTypePolicy, expiryDate: localDateTypePolicy}},
	FileInfo: {fields: {receivedOn: localDateTypePolicy}},
	FinancialAccountDetails: {fields: {currentBalance: moneyTypePolicy}},
	FuelTaxRate: {fields: {fuelTax: moneyTypePolicy, lastUpdatedOn: localDateTypePolicy}},
	GeneralLedgerAccount: {fields: {closedDate: localDateTypePolicy, openingBalance: moneyTypePolicy, openingDate: localDateTypePolicy}},
	GetPreviewJournalEntryLinesReturn: {fields: {credit: moneyTypePolicy, debit: moneyTypePolicy}},
	GrossIncomeLineItem: {fields: {amount: moneyTypePolicy}},
	GstDeposited: {fields: {amount: moneyTypePolicy, dateDeposited: localDateTypePolicy}},
	GstFiled: {fields: {amount: moneyTypePolicy, filedOnDate: localDateTypePolicy, fromDate: localDateTypePolicy, toDate: localDateTypePolicy}},
	GstHeld: {fields: {amount: moneyTypePolicy, dateHeld: localDateTypePolicy}},
	GstPaid: {fields: {amount: moneyTypePolicy, datePaid: localDateTypePolicy}},
	Image: {fields: {timestamp: dateTimeTypePolicy}},
	Invoice: {fields: {amountOwing: moneyTypePolicy, date: localDateTypePolicy, total: moneyTypePolicy}},
	InvoiceLineItems: {fields: {amount: moneyTypePolicy, gst: moneyTypePolicy, hst: moneyTypePolicy, pst: moneyTypePolicy, qst: moneyTypePolicy}},
	InvoicePayment: {fields: {amount: moneyTypePolicy}},
	JournalEntry: {fields: {date: localDateTypePolicy}},
	JournalEntryLine: {fields: {credit: moneyTypePolicy, debit: moneyTypePolicy}},
	JournalEntryTemplate: {fields: {endDate: localDateTypePolicy, lastScheduledDate: localDateTypePolicy, nextScheduledDate: localDateTypePolicy}},
	JournalEntryTemplateLine: {fields: {credit: moneyTypePolicy, debit: moneyTypePolicy}},
	Lead: {fields: {createdAt: localDateTypePolicy}},
	LeadReport: {fields: {communicationDate: dateTimeTypePolicy}},
	MaintenanceLogEvent: {fields: {amount: moneyTypePolicy}},
	ManagementBonus: {fields: {amount: moneyTypePolicy}},
	MinimumWage: {fields: {effectiveDate: localDateTypePolicy, hourlyRate: moneyTypePolicy}},
	Mutation: {fields: {changeHourlyPayByAgentId: moneyTypePolicy}},
	Notification: {fields: {dateTimeCreated: dateTimeTypePolicy, dateTimeRead: dateTimeTypePolicy, dateTimeSent: dateTimeTypePolicy}},
	OfficeDetails: {fields: {caftAmount: moneyTypePolicy, feeAmount: moneyTypePolicy}},
	OfficeReport: {fields: {caftAmount: moneyTypePolicy, feeAmount: moneyTypePolicy}},
	Organization: {fields: {payrollStartDate: localDateTypePolicy}},
	OtherDeductionsLineItem: {fields: {amount: moneyTypePolicy}},
	OutstandingAccountInvoices: {fields: {balance: moneyTypePolicy}},
	OverviewReport: {fields: {caftAmount: moneyTypePolicy, feeAmount: moneyTypePolicy}},
	PayStatement: {fields: {date: localDateTypePolicy}},
	PayStatementLine: {fields: {gst: moneyTypePolicy, subtotal: moneyTypePolicy, total: moneyTypePolicy, uiTotal: moneyTypePolicy}},
	PayStub: {
		fields: {
			cpp: moneyTypePolicy,
			cpp2: moneyTypePolicy,
			ei: moneyTypePolicy,
			endDate: localDateTypePolicy,
			payDate: localDateTypePolicy,
			startDate: localDateTypePolicy,
			tax: moneyTypePolicy,
		},
	},
	PayStubInterval: {fields: {endDate: localDateTypePolicy, payDate: localDateTypePolicy, startDate: localDateTypePolicy}},
	PayStubRow: {
		fields: {
			cpp: moneyTypePolicy,
			cpp2: moneyTypePolicy,
			date: localDateTypePolicy,
			ei: moneyTypePolicy,
			grossIncome: moneyTypePolicy,
			period: localDateTypePolicy,
			remittance: moneyTypePolicy,
			tax: moneyTypePolicy,
		},
	},
	PayableRow: {fields: {amount: moneyTypePolicy, date: localDateTypePolicy, period: localDateTypePolicy}},
	Payment: {fields: {date: localDateTypePolicy, total: moneyTypePolicy}},
	PaymentRow: {fields: {date: localDateTypePolicy, nsfDate: localDateTypePolicy, period: localDateTypePolicy}},
	PaymentSchedule: {fields: {balance: moneyTypePolicy}},
	PerDiemEvent: {fields: {accountsPayable: moneyTypePolicy, perDiem: moneyTypePolicy, tax: moneyTypePolicy, taxableMealAllowance: moneyTypePolicy}},
	PerDiemPaymentEvent: {
		fields: {
			totalAccountsPayable: moneyTypePolicy,
			totalPerDiem: moneyTypePolicy,
			totalTax: moneyTypePolicy,
			totalTaxableMealAllowance: moneyTypePolicy,
		},
	},
	Podcast: {fields: {date: dateTimeTypePolicy}},
	PreAuthorizedPayment: {fields: {authorizedLimit: moneyTypePolicy, date: localDateTypePolicy, lastGeneration: dateTimeTypePolicy}},
	PvlEvent: {fields: {expenses: moneyTypePolicy, tax: moneyTypePolicy}},
	PvlPaymentEvent: {fields: {totalExpenses: moneyTypePolicy, totalTax: moneyTypePolicy}},
	PvlRate: {fields: {effectiveDate: localDateTypePolicy}},
	Query: {
		fields: {
			getIncorporationDate: localDateTypePolicy,
			getLastClosedDate: localDateTypePolicy,
			getStatHolidayPay: moneyTypePolicy,
			getYearEndByAccountInfoId: localDateTypePolicy,
		},
	},
	Reminder: {fields: {createdAt: dateTimeTypePolicy, dateTimeSent: dateTimeTypePolicy, scheduledDeliveryDay: localDateTypePolicy}},
	Sai: {
		fields: {
			fiscalEndDate: localDateTypePolicy,
			fiscalStartDate: localDateTypePolicy,
			latestTransactionDate: localDateTypePolicy,
			sessionDate: localDateTypePolicy,
		},
	},
	ScheduledPayment: {fields: {amount: moneyTypePolicy, amountOwing: moneyTypePolicy, date: localDateTypePolicy}},
	SelfEmployedAccount: {fields: {yearEnd: localDateTypePolicy}},
	SelfEmployedOverview: {fields: {fiscalEnd: localDateTypePolicy, fiscalStart: localDateTypePolicy, gst: localDateTypePolicy}},
	SimplyCompany: {fields: {fiscalEndDate: localDateTypePolicy, fiscalStartDate: localDateTypePolicy}},
	SourceDeductionOpeningBalance: {fields: {amount: moneyTypePolicy}},
	SourceDeductionPayable: {fields: {amount: moneyTypePolicy, date: localDateTypePolicy, period: localDateTypePolicy}},
	SourceDeductionPayment: {fields: {date: localDateTypePolicy, nsfDate: localDateTypePolicy, period: localDateTypePolicy}},
	SourceDeductionPaymentLineItem: {fields: {amount: moneyTypePolicy}},
	SourceDeductionStatementBalance: {fields: {amount: moneyTypePolicy, period: localDateTypePolicy}},
	SpacesCleanup: {fields: {expiryDate: localDateTypePolicy}},
	Statement: {fields: {closingBalance: moneyTypePolicy, openingBalance: moneyTypePolicy}},
	StatementLine: {fields: {credit: moneyTypePolicy, date: localDateTypePolicy, debit: moneyTypePolicy}},
	T1: {fields: {filedOn: localDateTypePolicy, taxes: moneyTypePolicy}},
	T2Filed: {fields: {filedOnDate: localDateTypePolicy, taxAmount: moneyTypePolicy}},
	T4: {fields: {filedOn: localDateTypePolicy}},
	T4Summary: {fields: {cpp: moneyTypePolicy, cpp2: moneyTypePolicy, ei: moneyTypePolicy, grossIncome: moneyTypePolicy, incomeTax: moneyTypePolicy}},
	T5: {fields: {dividendAmount: moneyTypePolicy, filedOn: localDateTypePolicy}},
	TaxRate: {fields: {effectiveDate: localDateTypePolicy}},
	Ticket: {fields: {createdAt: dateTimeTypePolicy}},
	TicketReply: {fields: {replyTime: dateTimeTypePolicy}},
	TimeCard: {
		fields: {
			cpp: moneyTypePolicy,
			earnedVacationPay: moneyTypePolicy,
			ei: moneyTypePolicy,
			gross: moneyTypePolicy,
			incomeTax: moneyTypePolicy,
			payPeriodEnd: localDateTypePolicy,
			payPeriodStart: localDateTypePolicy,
			useBankedVacationPay: moneyTypePolicy,
		},
	},
	TimeCardLine: {fields: {date: localDateTypePolicy, hourlyPay: moneyTypePolicy, publicHolidayPay: moneyTypePolicy}},
	TimeClockEvent: {fields: {time: localTimeTypePolicy}},
	Transactions: {fields: {balance: moneyTypePolicy, scheduledBalance: moneyTypePolicy}},
	TreasuryAmount: {fields: {effectiveDate: localDateTypePolicy, incidentalExpenseAllowance: moneyTypePolicy, mealAllowance: moneyTypePolicy}},
	User: {fields: {registrationDate: localDateTypePolicy}},
	UserActivity: {fields: {date: localDateTypePolicy}},
	UserActivityType: {fields: {time: localTimeTypePolicy}},
	UserEvent: {fields: {timestamp: dateTimeTypePolicy}},
};


export function acPaymentMethodEnumLookup(value: string) {
  switch (value) {
    case 'Cash': return AcPaymentMethodEnum.Cash;
    case 'Cheque': return AcPaymentMethodEnum.Cheque;
    case 'PayLater': return AcPaymentMethodEnum.PayLater;
    default: throw new Error('Cannot find enum value: AcPaymentMethodEnum');
  }
}

export function accountEventFeatureEnumLookup(value: string) {
  switch (value) {
    case 'accounting': return AccountEventFeatureEnum.Accounting;
    case 'accounts': return AccountEventFeatureEnum.Accounts;
    case 'administration': return AccountEventFeatureEnum.Administration;
    case 'assets': return AccountEventFeatureEnum.Assets;
    case 'calendar': return AccountEventFeatureEnum.Calendar;
    case 'communications': return AccountEventFeatureEnum.Communications;
    case 'corporateTaxes': return AccountEventFeatureEnum.CorporateTaxes;
    case 'documents': return AccountEventFeatureEnum.Documents;
    case 'gst': return AccountEventFeatureEnum.Gst;
    case 'notifications': return AccountEventFeatureEnum.Notifications;
    case 'payroll': return AccountEventFeatureEnum.Payroll;
    case 'personalTaxes': return AccountEventFeatureEnum.PersonalTaxes;
    case 'rates': return AccountEventFeatureEnum.Rates;
    case 'receivables': return AccountEventFeatureEnum.Receivables;
    case 'search': return AccountEventFeatureEnum.Search;
    case 'support': return AccountEventFeatureEnum.Support;
    case 'system': return AccountEventFeatureEnum.System;
    case 't5': return AccountEventFeatureEnum.T5;
    case 'tasks': return AccountEventFeatureEnum.Tasks;
    case 'users': return AccountEventFeatureEnum.Users;
    default: throw new Error('Cannot find enum value: AccountEventFeatureEnum');
  }
}

export function accountNoticeTypeEnumLookup(value: string) {
  switch (value) {
    case 'Account': return AccountNoticeTypeEnum.Account;
    case 'SortedDocument': return AccountNoticeTypeEnum.SortedDocument;
    case 'UnsortedDocument': return AccountNoticeTypeEnum.UnsortedDocument;
    default: throw new Error('Cannot find enum value: AccountNoticeTypeEnum');
  }
}

export function accountTypeEnumLookup(value: string) {
  switch (value) {
    case 'BasicAccount': return AccountTypeEnum.BasicAccount;
    case 'CorporateAccount': return AccountTypeEnum.CorporateAccount;
    case 'PersonalAccount': return AccountTypeEnum.PersonalAccount;
    case 'SelfEmployedAccount': return AccountTypeEnum.SelfEmployedAccount;
    default: throw new Error('Cannot find enum value: AccountTypeEnum');
  }
}

export function agentRoleLookup(value: string) {
  switch (value) {
    case 'Admin': return AgentRole.Admin;
    case 'Director': return AgentRole.Director;
    case 'Manager': return AgentRole.Manager;
    case 'Marketing': return AgentRole.Marketing;
    case 'Recruiter': return AgentRole.Recruiter;
    case 'Sorter': return AgentRole.Sorter;
    case 'Sysadmin': return AgentRole.Sysadmin;
    case 'Workstation': return AgentRole.Workstation;
    default: throw new Error('Cannot find enum value: AgentRole');
  }
}

export function announcementGroupLookup(value: string) {
  switch (value) {
    case 'ActiveAccounts': return AnnouncementGroup.ActiveAccounts;
    case 'Agents': return AnnouncementGroup.Agents;
    case 'Everyone': return AnnouncementGroup.Everyone;
    default: throw new Error('Cannot find enum value: AnnouncementGroup');
  }
}

export function backupFileStatusEnumLookup(value: string) {
  switch (value) {
    case 'failure': return BackupFileStatusEnum.Failure;
    case 'pending': return BackupFileStatusEnum.Pending;
    case 'retrying': return BackupFileStatusEnum.Retrying;
    case 'success': return BackupFileStatusEnum.Success;
    default: throw new Error('Cannot find enum value: BackupFileStatusEnum');
  }
}

export function caftReleaseStatusLookup(value: string) {
  switch (value) {
    case 'Downloaded': return CaftReleaseStatus.Downloaded;
    case 'Pending': return CaftReleaseStatus.Pending;
    default: throw new Error('Cannot find enum value: CaftReleaseStatus');
  }
}

export function calendarEventTypeEnumLookup(value: string) {
  switch (value) {
    case 'MaintenanceLogEvent': return CalendarEventTypeEnum.MaintenanceLogEvent;
    case 'PerDiemEvent': return CalendarEventTypeEnum.PerDiemEvent;
    case 'PerDiemPaymentEvent': return CalendarEventTypeEnum.PerDiemPaymentEvent;
    case 'PvlEvent': return CalendarEventTypeEnum.PvlEvent;
    case 'PvlPaymentEvent': return CalendarEventTypeEnum.PvlPaymentEvent;
    default: throw new Error('Cannot find enum value: CalendarEventTypeEnum');
  }
}

export function calendarOrderByDirectionLookup(value: string) {
  switch (value) {
    case 'ASC': return CalendarOrderByDirection.Asc;
    case 'DESC': return CalendarOrderByDirection.Desc;
    default: throw new Error('Cannot find enum value: CalendarOrderByDirection');
  }
}

export function communicationStatusEnumLookup(value: string) {
  switch (value) {
    case 'Active': return CommunicationStatusEnum.Active;
    case 'Deleted': return CommunicationStatusEnum.Deleted;
    case 'Disabled': return CommunicationStatusEnum.Disabled;
    default: throw new Error('Cannot find enum value: CommunicationStatusEnum');
  }
}

export function communicationTypeEnumLookup(value: string) {
  switch (value) {
    case 'Call': return CommunicationTypeEnum.Call;
    case 'CorporateTaxes': return CommunicationTypeEnum.CorporateTaxes;
    case 'Email': return CommunicationTypeEnum.Email;
    case 'Note': return CommunicationTypeEnum.Note;
    default: throw new Error('Cannot find enum value: CommunicationTypeEnum');
  }
}

export function corporateAssetTypeEnumLookup(value: string) {
  switch (value) {
    case 'Building': return CorporateAssetTypeEnum.Building;
    case 'Equipment': return CorporateAssetTypeEnum.Equipment;
    case 'IncorporationCost': return CorporateAssetTypeEnum.IncorporationCost;
    case 'ParkingRoad': return CorporateAssetTypeEnum.ParkingRoad;
    case 'PersonalVehicle': return CorporateAssetTypeEnum.PersonalVehicle;
    case 'Trailer': return CorporateAssetTypeEnum.Trailer;
    case 'Truck': return CorporateAssetTypeEnum.Truck;
    default: throw new Error('Cannot find enum value: CorporateAssetTypeEnum');
  }
}

export function corporateTaxEventsEnumLookup(value: string) {
  switch (value) {
    case 'Filed': return CorporateTaxEventsEnum.Filed;
    case 'ManagementBonus': return CorporateTaxEventsEnum.ManagementBonus;
    case 'Paid': return CorporateTaxEventsEnum.Paid;
    case 'T183': return CorporateTaxEventsEnum.T183;
    default: throw new Error('Cannot find enum value: CorporateTaxEventsEnum');
  }
}

export function corporateTaxesPaymentTypeEnumLookup(value: string) {
  switch (value) {
    case 'cheque': return CorporateTaxesPaymentTypeEnum.Cheque;
    case 'preAuthorizedDebit': return CorporateTaxesPaymentTypeEnum.PreAuthorizedDebit;
    default: throw new Error('Cannot find enum value: CorporateTaxesPaymentTypeEnum');
  }
}

export function currencyCountryEnumLookup(value: string) {
  switch (value) {
    case 'AED': return CurrencyCountryEnum.Aed;
    case 'AFN': return CurrencyCountryEnum.Afn;
    case 'ALL': return CurrencyCountryEnum.All;
    case 'AMD': return CurrencyCountryEnum.Amd;
    case 'ARS': return CurrencyCountryEnum.Ars;
    case 'AUD': return CurrencyCountryEnum.Aud;
    case 'AZN': return CurrencyCountryEnum.Azn;
    case 'BAM': return CurrencyCountryEnum.Bam;
    case 'BDT': return CurrencyCountryEnum.Bdt;
    case 'BGN': return CurrencyCountryEnum.Bgn;
    case 'BHD': return CurrencyCountryEnum.Bhd;
    case 'BIF': return CurrencyCountryEnum.Bif;
    case 'BND': return CurrencyCountryEnum.Bnd;
    case 'BOB': return CurrencyCountryEnum.Bob;
    case 'BRL': return CurrencyCountryEnum.Brl;
    case 'BWP': return CurrencyCountryEnum.Bwp;
    case 'BYR': return CurrencyCountryEnum.Byr;
    case 'BZD': return CurrencyCountryEnum.Bzd;
    case 'CAD': return CurrencyCountryEnum.Cad;
    case 'CDF': return CurrencyCountryEnum.Cdf;
    case 'CHF': return CurrencyCountryEnum.Chf;
    case 'CLP': return CurrencyCountryEnum.Clp;
    case 'CNY': return CurrencyCountryEnum.Cny;
    case 'COP': return CurrencyCountryEnum.Cop;
    case 'CRC': return CurrencyCountryEnum.Crc;
    case 'CVE': return CurrencyCountryEnum.Cve;
    case 'CZK': return CurrencyCountryEnum.Czk;
    case 'DJF': return CurrencyCountryEnum.Djf;
    case 'DKK': return CurrencyCountryEnum.Dkk;
    case 'DOP': return CurrencyCountryEnum.Dop;
    case 'DZD': return CurrencyCountryEnum.Dzd;
    case 'EEK': return CurrencyCountryEnum.Eek;
    case 'EGP': return CurrencyCountryEnum.Egp;
    case 'ERN': return CurrencyCountryEnum.Ern;
    case 'ETB': return CurrencyCountryEnum.Etb;
    case 'EUR': return CurrencyCountryEnum.Eur;
    case 'GBP': return CurrencyCountryEnum.Gbp;
    case 'GEL': return CurrencyCountryEnum.Gel;
    case 'GHS': return CurrencyCountryEnum.Ghs;
    case 'GNF': return CurrencyCountryEnum.Gnf;
    case 'GTQ': return CurrencyCountryEnum.Gtq;
    case 'HKD': return CurrencyCountryEnum.Hkd;
    case 'HNL': return CurrencyCountryEnum.Hnl;
    case 'HRK': return CurrencyCountryEnum.Hrk;
    case 'HUF': return CurrencyCountryEnum.Huf;
    case 'IDR': return CurrencyCountryEnum.Idr;
    case 'ILS': return CurrencyCountryEnum.Ils;
    case 'INR': return CurrencyCountryEnum.Inr;
    case 'IQD': return CurrencyCountryEnum.Iqd;
    case 'IRR': return CurrencyCountryEnum.Irr;
    case 'ISK': return CurrencyCountryEnum.Isk;
    case 'JMD': return CurrencyCountryEnum.Jmd;
    case 'JOD': return CurrencyCountryEnum.Jod;
    case 'JPY': return CurrencyCountryEnum.Jpy;
    case 'KES': return CurrencyCountryEnum.Kes;
    case 'KHR': return CurrencyCountryEnum.Khr;
    case 'KMF': return CurrencyCountryEnum.Kmf;
    case 'KRW': return CurrencyCountryEnum.Krw;
    case 'KWD': return CurrencyCountryEnum.Kwd;
    case 'KZT': return CurrencyCountryEnum.Kzt;
    case 'LBP': return CurrencyCountryEnum.Lbp;
    case 'LKR': return CurrencyCountryEnum.Lkr;
    case 'LTL': return CurrencyCountryEnum.Ltl;
    case 'LVL': return CurrencyCountryEnum.Lvl;
    case 'LYD': return CurrencyCountryEnum.Lyd;
    case 'MAD': return CurrencyCountryEnum.Mad;
    case 'MDL': return CurrencyCountryEnum.Mdl;
    case 'MGA': return CurrencyCountryEnum.Mga;
    case 'MKD': return CurrencyCountryEnum.Mkd;
    case 'MMK': return CurrencyCountryEnum.Mmk;
    case 'MOP': return CurrencyCountryEnum.Mop;
    case 'MUR': return CurrencyCountryEnum.Mur;
    case 'MXN': return CurrencyCountryEnum.Mxn;
    case 'MYR': return CurrencyCountryEnum.Myr;
    case 'MZN': return CurrencyCountryEnum.Mzn;
    case 'NAD': return CurrencyCountryEnum.Nad;
    case 'NGN': return CurrencyCountryEnum.Ngn;
    case 'NIO': return CurrencyCountryEnum.Nio;
    case 'NOK': return CurrencyCountryEnum.Nok;
    case 'NPR': return CurrencyCountryEnum.Npr;
    case 'NZD': return CurrencyCountryEnum.Nzd;
    case 'OMR': return CurrencyCountryEnum.Omr;
    case 'PAB': return CurrencyCountryEnum.Pab;
    case 'PEN': return CurrencyCountryEnum.Pen;
    case 'PHP': return CurrencyCountryEnum.Php;
    case 'PKR': return CurrencyCountryEnum.Pkr;
    case 'PLN': return CurrencyCountryEnum.Pln;
    case 'PYG': return CurrencyCountryEnum.Pyg;
    case 'QAR': return CurrencyCountryEnum.Qar;
    case 'RON': return CurrencyCountryEnum.Ron;
    case 'RSD': return CurrencyCountryEnum.Rsd;
    case 'RUB': return CurrencyCountryEnum.Rub;
    case 'RWF': return CurrencyCountryEnum.Rwf;
    case 'SAR': return CurrencyCountryEnum.Sar;
    case 'SDG': return CurrencyCountryEnum.Sdg;
    case 'SEK': return CurrencyCountryEnum.Sek;
    case 'SGD': return CurrencyCountryEnum.Sgd;
    case 'SOS': return CurrencyCountryEnum.Sos;
    case 'SYP': return CurrencyCountryEnum.Syp;
    case 'THB': return CurrencyCountryEnum.Thb;
    case 'TND': return CurrencyCountryEnum.Tnd;
    case 'TOP': return CurrencyCountryEnum.Top;
    case 'TRY': return CurrencyCountryEnum.Try;
    case 'TTD': return CurrencyCountryEnum.Ttd;
    case 'TWD': return CurrencyCountryEnum.Twd;
    case 'TZS': return CurrencyCountryEnum.Tzs;
    case 'UAH': return CurrencyCountryEnum.Uah;
    case 'UGX': return CurrencyCountryEnum.Ugx;
    case 'USD': return CurrencyCountryEnum.Usd;
    case 'UYU': return CurrencyCountryEnum.Uyu;
    case 'UZS': return CurrencyCountryEnum.Uzs;
    case 'VEF': return CurrencyCountryEnum.Vef;
    case 'VND': return CurrencyCountryEnum.Vnd;
    case 'XAF': return CurrencyCountryEnum.Xaf;
    case 'XOF': return CurrencyCountryEnum.Xof;
    case 'YER': return CurrencyCountryEnum.Yer;
    case 'ZAR': return CurrencyCountryEnum.Zar;
    case 'ZMK': return CurrencyCountryEnum.Zmk;
    default: throw new Error('Cannot find enum value: CurrencyCountryEnum');
  }
}

export function directorOfficeEnumLookup(value: string) {
  switch (value) {
    case 'president': return DirectorOfficeEnum.President;
    case 'vicePresident': return DirectorOfficeEnum.VicePresident;
    default: throw new Error('Cannot find enum value: DirectorOfficeEnum');
  }
}

export function documentGroupEnumLookup(value: string) {
  switch (value) {
    case 'Accounting': return DocumentGroupEnum.Accounting;
    case 'CorporateDissolution': return DocumentGroupEnum.CorporateDissolution;
    case 'Cra': return DocumentGroupEnum.Cra;
    case 'Incorporation': return DocumentGroupEnum.Incorporation;
    default: throw new Error('Cannot find enum value: DocumentGroupEnum');
  }
}

export function documentStatusEnumLookup(value: string) {
  switch (value) {
    case 'Entered': return DocumentStatusEnum.Entered;
    case 'Pending': return DocumentStatusEnum.Pending;
    case 'Reconciled': return DocumentStatusEnum.Reconciled;
    case 'Reviewed': return DocumentStatusEnum.Reviewed;
    default: throw new Error('Cannot find enum value: DocumentStatusEnum');
  }
}

export function employeePaymentIntervalEnumLookup(value: string) {
  switch (value) {
    case 'Biweekly26': return EmployeePaymentIntervalEnum.Biweekly26;
    case 'Biweekly27': return EmployeePaymentIntervalEnum.Biweekly27;
    case 'Monthly12': return EmployeePaymentIntervalEnum.Monthly12;
    case 'SemiMonthly24': return EmployeePaymentIntervalEnum.SemiMonthly24;
    case 'Weekly52': return EmployeePaymentIntervalEnum.Weekly52;
    case 'Weekly53': return EmployeePaymentIntervalEnum.Weekly53;
    default: throw new Error('Cannot find enum value: EmployeePaymentIntervalEnum');
  }
}

export function employeeStatusFilterEnumLookup(value: string) {
  switch (value) {
    case 'All': return EmployeeStatusFilterEnum.All;
    case 'Employed': return EmployeeStatusFilterEnum.Employed;
    case 'PreviouslyEmployed': return EmployeeStatusFilterEnum.PreviouslyEmployed;
    default: throw new Error('Cannot find enum value: EmployeeStatusFilterEnum');
  }
}

export function expectedDocumentFrequencyEnumLookup(value: string) {
  switch (value) {
    case 'annually': return ExpectedDocumentFrequencyEnum.Annually;
    case 'monthly': return ExpectedDocumentFrequencyEnum.Monthly;
    case 'quarterly': return ExpectedDocumentFrequencyEnum.Quarterly;
    default: throw new Error('Cannot find enum value: ExpectedDocumentFrequencyEnum');
  }
}

export function fileArchiveStatusEnumLookup(value: string) {
  switch (value) {
    case 'complete': return FileArchiveStatusEnum.Complete;
    case 'expired': return FileArchiveStatusEnum.Expired;
    case 'failed': return FileArchiveStatusEnum.Failed;
    case 'inProgress': return FileArchiveStatusEnum.InProgress;
    default: throw new Error('Cannot find enum value: FileArchiveStatusEnum');
  }
}

export function filingMethodEnumLookup(value: string) {
  switch (value) {
    case 'eFiled': return FilingMethodEnum.EFiled;
    case 'faxed': return FilingMethodEnum.Faxed;
    case 'handDelivered': return FilingMethodEnum.HandDelivered;
    case 'mailed': return FilingMethodEnum.Mailed;
    default: throw new Error('Cannot find enum value: FilingMethodEnum');
  }
}

export function financialAccountTypeEnumLookup(value: string) {
  switch (value) {
    case 'BankAccount': return FinancialAccountTypeEnum.BankAccount;
    case 'CreditCard': return FinancialAccountTypeEnum.CreditCard;
    case 'LongTermLoan': return FinancialAccountTypeEnum.LongTermLoan;
    case 'ShortTermLoan': return FinancialAccountTypeEnum.ShortTermLoan;
    default: throw new Error('Cannot find enum value: FinancialAccountTypeEnum');
  }
}

export function frequencyEnumLookup(value: string) {
  switch (value) {
    case 'Annually': return FrequencyEnum.Annually;
    case 'Monthly': return FrequencyEnum.Monthly;
    case 'OneTime': return FrequencyEnum.OneTime;
    case 'Quarterly': return FrequencyEnum.Quarterly;
    default: throw new Error('Cannot find enum value: FrequencyEnum');
  }
}

export function gstFilingFrequencyEnumLookup(value: string) {
  switch (value) {
    case 'Annually': return GstFilingFrequencyEnum.Annually;
    case 'Monthly': return GstFilingFrequencyEnum.Monthly;
    case 'Quarterly': return GstFilingFrequencyEnum.Quarterly;
    default: throw new Error('Cannot find enum value: GstFilingFrequencyEnum');
  }
}

export function generalLedgerAccountGroupEnumLookup(value: string) {
  switch (value) {
    case 'asset': return GeneralLedgerAccountGroupEnum.Asset;
    case 'equity': return GeneralLedgerAccountGroupEnum.Equity;
    case 'expense': return GeneralLedgerAccountGroupEnum.Expense;
    case 'liability': return GeneralLedgerAccountGroupEnum.Liability;
    case 'revenue': return GeneralLedgerAccountGroupEnum.Revenue;
    default: throw new Error('Cannot find enum value: GeneralLedgerAccountGroupEnum');
  }
}

export function generalLedgerAccountTypeEnumLookup(value: string) {
  switch (value) {
    case 'account': return GeneralLedgerAccountTypeEnum.Account;
    case 'heading': return GeneralLedgerAccountTypeEnum.Heading;
    case 'subaccount': return GeneralLedgerAccountTypeEnum.Subaccount;
    case 'subtotal': return GeneralLedgerAccountTypeEnum.Subtotal;
    case 'total': return GeneralLedgerAccountTypeEnum.Total;
    default: throw new Error('Cannot find enum value: GeneralLedgerAccountTypeEnum');
  }
}

export function gstFiledDropdownTypeEnumLookup(value: string) {
  switch (value) {
    case 'gstDeposited': return GstFiledDropdownTypeEnum.GstDeposited;
    case 'gstHeld': return GstFiledDropdownTypeEnum.GstHeld;
    case 'gstPaid': return GstFiledDropdownTypeEnum.GstPaid;
    default: throw new Error('Cannot find enum value: GstFiledDropdownTypeEnum');
  }
}

export function gstHeldReasonEnumLookup(value: string) {
  switch (value) {
    case 'nonCompliant': return GstHeldReasonEnum.NonCompliant;
    case 'transferredToCorporateTaxReturn': return GstHeldReasonEnum.TransferredToCorporateTaxReturn;
    case 'transferredToSourceDeduction': return GstHeldReasonEnum.TransferredToSourceDeduction;
    case 'unknown': return GstHeldReasonEnum.Unknown;
    default: throw new Error('Cannot find enum value: GstHeldReasonEnum');
  }
}

export function gstPaymentTypeEnumLookup(value: string) {
  switch (value) {
    case 'cheque': return GstPaymentTypeEnum.Cheque;
    case 'preAuthorizedDebit': return GstPaymentTypeEnum.PreAuthorizedDebit;
    default: throw new Error('Cannot find enum value: GstPaymentTypeEnum');
  }
}

export function journalEntryTypeEnumLookup(value: string) {
  switch (value) {
    case 'asset': return JournalEntryTypeEnum.Asset;
    case 'expense': return JournalEntryTypeEnum.Expense;
    case 'fiscalYear': return JournalEntryTypeEnum.FiscalYear;
    case 'general': return JournalEntryTypeEnum.General;
    case 'historical': return JournalEntryTypeEnum.Historical;
    case 'payStatement': return JournalEntryTypeEnum.PayStatement;
    case 'payable': return JournalEntryTypeEnum.Payable;
    case 'payment': return JournalEntryTypeEnum.Payment;
    case 'receipt': return JournalEntryTypeEnum.Receipt;
    case 'receivable': return JournalEntryTypeEnum.Receivable;
    default: throw new Error('Cannot find enum value: JournalEntryTypeEnum');
  }
}

export function leadStatusEnumLookup(value: string) {
  switch (value) {
    case 'Converted': return LeadStatusEnum.Converted;
    case 'Disqualified': return LeadStatusEnum.Disqualified;
    case 'FollowUp': return LeadStatusEnum.FollowUp;
    case 'NotInterested': return LeadStatusEnum.NotInterested;
    default: throw new Error('Cannot find enum value: LeadStatusEnum');
  }
}

export function linkedGlAccountTypeLookup(value: string) {
  switch (value) {
    case 'CurrentEarnings': return LinkedGlAccountType.CurrentEarnings;
    case 'Deposit': return LinkedGlAccountType.Deposit;
    case 'GstCollected': return LinkedGlAccountType.GstCollected;
    case 'GstPaid': return LinkedGlAccountType.GstPaid;
    case 'GstPayable': return LinkedGlAccountType.GstPayable;
    case 'Payables': return LinkedGlAccountType.Payables;
    case 'Receivables': return LinkedGlAccountType.Receivables;
    case 'RetainedEarnings': return LinkedGlAccountType.RetainedEarnings;
    default: throw new Error('Cannot find enum value: LinkedGlAccountType');
  }
}

export function locationEnumLookup(value: string) {
  switch (value) {
    case 'AB': return LocationEnum.Ab;
    case 'BC': return LocationEnum.Bc;
    case 'CA': return LocationEnum.Ca;
    case 'MB': return LocationEnum.Mb;
    case 'NB': return LocationEnum.Nb;
    case 'NL': return LocationEnum.Nl;
    case 'NS': return LocationEnum.Ns;
    case 'NT': return LocationEnum.Nt;
    case 'NU': return LocationEnum.Nu;
    case 'ON': return LocationEnum.On;
    case 'PE': return LocationEnum.Pe;
    case 'QC': return LocationEnum.Qc;
    case 'SK': return LocationEnum.Sk;
    case 'US': return LocationEnum.Us;
    case 'YT': return LocationEnum.Yt;
    default: throw new Error('Cannot find enum value: LocationEnum');
  }
}

export function payStubsFilterOrderDirectionEnumLookup(value: string) {
  switch (value) {
    case 'asc': return PayStubsFilterOrderDirectionEnum.Asc;
    case 'desc': return PayStubsFilterOrderDirectionEnum.Desc;
    default: throw new Error('Cannot find enum value: PayStubsFilterOrderDirectionEnum');
  }
}

export function paymentMethodEnumLookup(value: string) {
  switch (value) {
    case 'CAFT': return PaymentMethodEnum.Caft;
    case 'Cash': return PaymentMethodEnum.Cash;
    case 'Cheque': return PaymentMethodEnum.Cheque;
    case 'eTransfer': return PaymentMethodEnum.ETransfer;
    default: throw new Error('Cannot find enum value: PaymentMethodEnum');
  }
}

export function paymentStatusLookup(value: string) {
  switch (value) {
    case 'NSF': return PaymentStatus.Nsf;
    case 'NsfReversed': return PaymentStatus.NsfReversed;
    case 'Reversed': return PaymentStatus.Reversed;
    case 'Successful': return PaymentStatus.Successful;
    default: throw new Error('Cannot find enum value: PaymentStatus');
  }
}

export function payrollFrequencyEnumLookup(value: string) {
  switch (value) {
    case 'BiWeekly': return PayrollFrequencyEnum.BiWeekly;
    case 'Monthly': return PayrollFrequencyEnum.Monthly;
    case 'Weekly': return PayrollFrequencyEnum.Weekly;
    default: throw new Error('Cannot find enum value: PayrollFrequencyEnum');
  }
}

export function permissionLookup(value: string) {
  switch (value) {
    case 'editRates': return Permission.EditRates;
    case 'inviteUser': return Permission.InviteUser;
    case 'manageAgents': return Permission.ManageAgents;
    case 'manageAllAgents': return Permission.ManageAllAgents;
    case 'manageAssignedAgents': return Permission.ManageAssignedAgents;
    case 'manageDocumentTags': return Permission.ManageDocumentTags;
    case 'manageDocumentTypes': return Permission.ManageDocumentTypes;
    case 'manageOwnOrganization': return Permission.ManageOwnOrganization;
    case 'marketingTools': return Permission.MarketingTools;
    case 'readAllAccount': return Permission.ReadAllAccount;
    case 'readOwnAccount': return Permission.ReadOwnAccount;
    case 'sysadmin': return Permission.Sysadmin;
    case 'viewAdminPayrollAccount': return Permission.ViewAdminPayrollAccount;
    case 'viewReports': return Permission.ViewReports;
    case 'writeAllAccount': return Permission.WriteAllAccount;
    case 'writeOwnAccount': return Permission.WriteOwnAccount;
    case 'writeOwnUser': return Permission.WriteOwnUser;
    default: throw new Error('Cannot find enum value: Permission');
  }
}

export function provinceEnumLookup(value: string) {
  switch (value) {
    case 'AB': return ProvinceEnum.Ab;
    case 'BC': return ProvinceEnum.Bc;
    case 'MB': return ProvinceEnum.Mb;
    case 'NB': return ProvinceEnum.Nb;
    case 'NL': return ProvinceEnum.Nl;
    case 'NS': return ProvinceEnum.Ns;
    case 'NT': return ProvinceEnum.Nt;
    case 'NU': return ProvinceEnum.Nu;
    case 'ON': return ProvinceEnum.On;
    case 'PE': return ProvinceEnum.Pe;
    case 'QC': return ProvinceEnum.Qc;
    case 'SK': return ProvinceEnum.Sk;
    case 'YT': return ProvinceEnum.Yt;
    default: throw new Error('Cannot find enum value: ProvinceEnum');
  }
}

export function receiveMethodEnumLookup(value: string) {
  switch (value) {
    case 'App': return ReceiveMethodEnum.App;
    case 'Email': return ReceiveMethodEnum.Email;
    case 'SystemGenerated': return ReceiveMethodEnum.SystemGenerated;
    case 'Unknown': return ReceiveMethodEnum.Unknown;
    case 'Website': return ReceiveMethodEnum.Website;
    default: throw new Error('Cannot find enum value: ReceiveMethodEnum');
  }
}

export function recurrencePatternEnumLookup(value: string) {
  switch (value) {
    case 'annually': return RecurrencePatternEnum.Annually;
    case 'biMonthly': return RecurrencePatternEnum.BiMonthly;
    case 'biWeekly': return RecurrencePatternEnum.BiWeekly;
    case 'daily': return RecurrencePatternEnum.Daily;
    case 'monthly': return RecurrencePatternEnum.Monthly;
    case 'quarterly': return RecurrencePatternEnum.Quarterly;
    case 'random': return RecurrencePatternEnum.Random;
    case 'semiAnnually': return RecurrencePatternEnum.SemiAnnually;
    case 'semiMonthly': return RecurrencePatternEnum.SemiMonthly;
    case 'thirteenPeriod': return RecurrencePatternEnum.ThirteenPeriod;
    case 'weekly': return RecurrencePatternEnum.Weekly;
    default: throw new Error('Cannot find enum value: RecurrencePatternEnum');
  }
}

export function referralEnumLookup(value: string) {
  switch (value) {
    case 'Billboard': return ReferralEnum.Billboard;
    case 'ClientReferral': return ReferralEnum.ClientReferral;
    case 'Other': return ReferralEnum.Other;
    case 'Podcast': return ReferralEnum.Podcast;
    case 'SocialMedia': return ReferralEnum.SocialMedia;
    case 'TradeShow': return ReferralEnum.TradeShow;
    case 'WebSearch': return ReferralEnum.WebSearch;
    default: throw new Error('Cannot find enum value: ReferralEnum');
  }
}

export function reminderFilterLookup(value: string) {
  switch (value) {
    case 'Other': return ReminderFilter.Other;
    case 'Past': return ReminderFilter.Past;
    case 'Upcoming': return ReminderFilter.Upcoming;
    default: throw new Error('Cannot find enum value: ReminderFilter');
  }
}

export function searchTypEnumLookup(value: string) {
  switch (value) {
    case 'AuthorizedRepresentative': return SearchTypEnum.AuthorizedRepresentative;
    case 'BasicAccount': return SearchTypEnum.BasicAccount;
    case 'CorporateAccount': return SearchTypEnum.CorporateAccount;
    case 'Lead': return SearchTypEnum.Lead;
    case 'PersonalAccount': return SearchTypEnum.PersonalAccount;
    case 'SelfEmployedAccount': return SearchTypEnum.SelfEmployedAccount;
    case 'User': return SearchTypEnum.User;
    default: throw new Error('Cannot find enum value: SearchTypEnum');
  }
}

export function simplyContactModuleLookup(value: string) {
  switch (value) {
    case 'customer': return SimplyContactModule.Customer;
    case 'vendor': return SimplyContactModule.Vendor;
    default: throw new Error('Cannot find enum value: SimplyContactModule');
  }
}

export function sourceDeductionPaymentTypeEnumLookup(value: string) {
  switch (value) {
    case 'cheque': return SourceDeductionPaymentTypeEnum.Cheque;
    case 'online': return SourceDeductionPaymentTypeEnum.Online;
    default: throw new Error('Cannot find enum value: SourceDeductionPaymentTypeEnum');
  }
}

export function sourceDeductionRowTypeLookup(value: string) {
  switch (value) {
    case 'openingBalance': return SourceDeductionRowType.OpeningBalance;
    case 'payStub': return SourceDeductionRowType.PayStub;
    case 'payStubTotal': return SourceDeductionRowType.PayStubTotal;
    case 'payable': return SourceDeductionRowType.Payable;
    case 'payment': return SourceDeductionRowType.Payment;
    default: throw new Error('Cannot find enum value: SourceDeductionRowType');
  }
}

export function spacesCleanupTypeEnumLookup(value: string) {
  switch (value) {
    case 'archive': return SpacesCleanupTypeEnum.Archive;
    case 'document': return SpacesCleanupTypeEnum.Document;
    default: throw new Error('Cannot find enum value: SpacesCleanupTypeEnum');
  }
}

export function statementStatusEnumLookup(value: string) {
  switch (value) {
    case 'DocumentRequired': return StatementStatusEnum.DocumentRequired;
    case 'NotApplicable': return StatementStatusEnum.NotApplicable;
    case 'NotProvided': return StatementStatusEnum.NotProvided;
    case 'Pending': return StatementStatusEnum.Pending;
    default: throw new Error('Cannot find enum value: StatementStatusEnum');
  }
}

export function ticketPriorityEnumLookup(value: string) {
  switch (value) {
    case 'Emergency': return TicketPriorityEnum.Emergency;
    case 'High': return TicketPriorityEnum.High;
    case 'Low': return TicketPriorityEnum.Low;
    case 'Normal': return TicketPriorityEnum.Normal;
    default: throw new Error('Cannot find enum value: TicketPriorityEnum');
  }
}

export function ticketStatusEnumLookup(value: string) {
  switch (value) {
    case 'Closed': return TicketStatusEnum.Closed;
    case 'Open': return TicketStatusEnum.Open;
    case 'Resolved': return TicketStatusEnum.Resolved;
    default: throw new Error('Cannot find enum value: TicketStatusEnum');
  }
}

export function userEventTypeEnumLookup(value: string) {
  switch (value) {
    case 'create': return UserEventTypeEnum.Create;
    case 'delete': return UserEventTypeEnum.Delete;
    case 'edit': return UserEventTypeEnum.Edit;
    case 'other': return UserEventTypeEnum.Other;
    case 'reverse': return UserEventTypeEnum.Reverse;
    case 'send': return UserEventTypeEnum.Send;
    default: throw new Error('Cannot find enum value: UserEventTypeEnum');
  }
}

