import {useCan} from '@imperium/auth-client';
import {LocalDate} from '@js-joda/core';
import {LocalDatePicker, TForm} from '@thx/controls';
import {formatDate} from '@thx/date';
import {formatMoney} from '@thx/money';
import {moneySchemaType} from '@thx/yup-types';
import debug from 'debug';
import {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Accordion, Button, Divider, Form, FormField, Icon, Label, Message, MessageHeader, Radio} from 'semantic-ui-react';
import {boolean, date, object, string} from 'yup';
import {DeleteModal} from '~common/components';
import {DocumentStatusEnum, Permission, type UpdateDocumentInput} from '~core/graphql';
import {getFullName} from '~lib/getFullName';
import {useAsyncError} from '~lib/useAsyncError';
import {useConvertMoney} from '~lib/useConvertMoney';
import {useAccountingState} from '../../../../accounting/state';
import {EmployeesDropdown} from '../../../../accounts/components/employees/EmployeesDropdown';
import {CorporateAssetDropdown} from '../../../../assets/components/corporateAssets/CorporateAssetDropdown';
import {useDeleteDocumentMutation} from '../../../graphql/deleteDocument';
import {useGetDocumentByIdQuery} from '../../../graphql/getDocumentById';
import {routes} from '../../../routes';
import {DocumentAmountInput} from '../DocumentAmountInput';
import {DocumentStatusDropdown} from '../DocumentStatusDropdown';
import {DocumentTypeDropdown} from '../DocumentTypeDropdown';
import {VendorDropdown} from '../VendorDropdown';
import {useUpdatedDocumentFormMutation} from './updateDocumentForm';

const d = debug('tacs.web.documents.components.documents.DocumentSidebarViewer');

const addDocumentFormValidation = object().shape({
	type: string().required('The type is required.'),
	vendor: string().required('The vendor is required'),
	employee: string().nullable().notRequired(),
	corporateAsset: string().nullable().notRequired(),
	amount: moneySchemaType().nullable().notRequired(),
	status: string().nullable().notRequired(),
	date: date().required('The date is required.'),
	cash: boolean().required('Cash is required.'),
});

export function DocumentSidebarViewer() {
	const throwError = useAsyncError();
	const {goBack, push} = useHistory();
	const {accountInfoId, documentId} = useParams<typeof routes.types.documentViewer>();
	const [writeAllAccounts] = useCan(Permission.WriteAllAccount);
	const [updateDocumentFormMutation, {loading: mutationLoading}] = useUpdatedDocumentFormMutation();
	const [deleteMutation, {called: calledDelete}] = useDeleteDocumentMutation();
	const [activeAccordion, setActiveAccordion] = useState(false);
	const {fiscalOpenDate} = useAccountingState();

	const {data, loading, error} = useGetDocumentByIdQuery({
		variables: {documentId},
	});
	const {loading: convertLoading, convert: convertToCad} = useConvertMoney(
		'USD',
		'CAD',
		data?.getDocumentById?.date || LocalDate.now(),
		!data?.getDocumentById,
	);

	if (loading) return null;
	if (error) throw error;
	if (!data?.getDocumentById) throw new Error('The unsorted doc meta-information could not be loaded');

	function handleFormSubmit(documentInput: UpdateDocumentInput) {
		if (!data?.getDocumentById) throw new Error('The unsorted doc meta-information could not be loaded');
		updateDocumentFormMutation({
			variables: {
				data: documentInput,
			},
		})
			.then(() => {
				toast.success('Document updated!');
				goBack();
			})
			.catch(throwError);
	}

	function onDeleteClicked() {
		deleteMutation({
			variables: {id: documentId, version: data?.getDocumentById?.version || 0},
		})
			.then(() => {
				toast.success(`${data?.getDocumentById?.type?.name} deleted.`);
				goBack();
			})
			.catch(throwError);
	}

	const documentLocked =
		(fiscalOpenDate &&
			data.getDocumentById.date.isBefore(fiscalOpenDate.minusYears(1)) &&
			data.getDocumentById.status !== DocumentStatusEnum.Pending) ||
		false;
	const pendingFiscalDocument =
		(fiscalOpenDate &&
			data.getDocumentById.date.isBefore(fiscalOpenDate.minusYears(1)) &&
			data.getDocumentById.status === DocumentStatusEnum.Pending) ||
		false;

	return (
		<TForm<UpdateDocumentInput>
			initialValues={{
				id: data.getDocumentById.id,
				version: data?.getDocumentById.version,
				type: data.getDocumentById?.type?.id,
				vendor: data.getDocumentById?.vendor?.id,
				employee: data.getDocumentById?.employee?.id,
				amount: data.getDocumentById?.amount,
				date: data.getDocumentById?.date,
				status: data.getDocumentById?.status,
				cash: data.getDocumentById?.cash,
				corporateAsset: data.getDocumentById?.corporateAsset?.id,
			}}
			onSubmit={handleFormSubmit}
			validationSchema={addDocumentFormValidation}
		>
			{tFormProps => {
				const {handleSubmit, hasErrors, hasWarnings, renderWarnings, values, setFieldValue} = tFormProps;
				return (
					<Form onSubmit={handleSubmit} error={hasErrors} warning={hasWarnings}>
						{documentLocked && (
							<Message info visible>
								<MessageHeader>Notice</MessageHeader>
								Document is from a closed Fiscal Year and can&apos;t be edited.
							</Message>
						)}
						{pendingFiscalDocument && (
							<Message error visible>
								<MessageHeader>Warning</MessageHeader>
								Document is <b>pending</b> from a closed Fiscal Year and has not been processed!
							</Message>
						)}
						<FormField required>
							<label>Date</label>
							<LocalDatePicker
								disabled={!writeAllAccounts || documentLocked}
								maxDate={LocalDate.now()}
								minDate={fiscalOpenDate?.minusYears(1) || LocalDate.now().minusYears(7)}
								onChange={val => setFieldValue('date', val)}
								value={values?.date}
							/>
						</FormField>
						<FormField required>
							<label>Type</label>
							<DocumentTypeDropdown
								selection
								onChange={val => {
									setFieldValue('type', val);
								}}
								value={values?.type}
								disabled={!writeAllAccounts || documentLocked}
							/>
						</FormField>
						<FormField required>
							<label>Vendor</label>
							<VendorDropdown
								disabled={!writeAllAccounts || documentLocked}
								addNewVendorButton
								onChange={val => setFieldValue('vendor', val)}
								value={values?.vendor}
								accountInfoId={accountInfoId}
							/>
						</FormField>
						{writeAllAccounts && (
							<>
								<FormField>
									<label>Amount</label>
									<DocumentAmountInput
										disabled={documentLocked}
										documentTypeId={values?.type || ''}
										onChange={val => setFieldValue('amount', val)}
										value={values?.amount}
									/>
									{!convertLoading && values.amount && values.amount.currency !== 'CAD' && (
										<Label as="div" basic size="large" style={{width: '100%', marginTop: '6px'}}>
											{formatMoney(convertToCad(values.amount), false)} CAD
										</Label>
									)}
								</FormField>
								<FormField>
									<label>Status</label>
									<DocumentStatusDropdown
										disabled={documentLocked}
										multiple={false}
										onChange={value => setFieldValue('status', value)}
										value={values.status}
										documentTypeId={values?.type || ''}
									/>
								</FormField>
								<FormField>
									<label>Cash</label>
									<Radio disabled={documentLocked} toggle checked={values.cash} onChange={(event, val) => setFieldValue('cash', val.checked)} />
								</FormField>
								<Accordion>
									<Accordion.Title active={activeAccordion} index={0} onClick={() => setActiveAccordion(!activeAccordion)}>
										<Icon name="dropdown" />
										More Details
									</Accordion.Title>
									<Accordion.Content active={activeAccordion}>
										<FormField>
											<label>Employee</label>
											<EmployeesDropdown
												disabled={documentLocked}
												clearable
												fluid
												selection
												accountInfoId={accountInfoId}
												value={values.employee || ''}
												onChange={value => {
													setFieldValue('employee', value);
												}}
												defaultNull
											/>
										</FormField>
										<FormField>
											<label>Corporate Asset</label>
											<CorporateAssetDropdown
												disabled={documentLocked}
												clearable
												fluid
												selection
												accountInfoId={accountInfoId}
												value={values.corporateAsset || undefined}
												onChange={value => {
													setFieldValue('corporateAsset', value);
												}}
												defaultNull
											/>
											<Divider />
											Sorted by {getFullName(data?.getDocumentById?.sortedBy)} on {formatDate(data?.getDocumentById?.sortedOn)}
										</FormField>
									</Accordion.Content>
								</Accordion>
							</>
						)}

						<FormField>{renderWarnings()}</FormField>
						{writeAllAccounts && (
							<>
								<Divider />
								<FormField>
									<Button disabled={mutationLoading || documentLocked} color="green" type="submit" fluid>
										Update Document
									</Button>
								</FormField>
								<FormField>
									<DeleteModal
										onDelete={onDeleteClicked}
										headerText="Confirm Delete"
										text={`Are you sure you want to delete the ${data?.getDocumentById?.type?.name?.toLowerCase()}?`}
										trigger={<Button disabled={calledDelete || documentLocked} color="red" icon="trash" title="Delete Document" type="button" />}
									/>
									<Button
										title="View Original File and all Associated Documents."
										color="blue"
										icon="caret square up"
										type="button"
										onClick={() =>
											push(
												routes.to.fileViewer({
													fileInfoId: data?.getDocumentById?.fileInfo.id || '',
													accountInfoId,
												}),
											)
										}
									/>
									<Button color="grey" type="button" onClick={() => goBack()} floated="right">
										{documentLocked ? 'Close' : 'Discard Changes'}
									</Button>
								</FormField>
							</>
						)}
					</Form>
				);
			}}
		</TForm>
	);
}
