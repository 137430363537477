import {useLayoutState} from '@imperium/layout';
import {LocalDate} from '@js-joda/core';
import {formatDate, getFiscalYearRange} from '@thx/date';
import debug from 'debug';
import {memo, useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Icon, MenuItem, Transition} from 'semantic-ui-react';
import styles from '~common/components/DateRangeSelector/style.module.css';
import {CustomTab} from '~common/components/DateRangeSelector/tabs/CustomTab';
import {FullMonthTab} from '~common/components/DateRangeSelector/tabs/FullMonthTab';
import {FullYearTab} from '~common/components/DateRangeSelector/tabs/FullYearTab';
import {PresetTab} from '~common/components/DateRangeSelector/tabs/PresetTab';
import {setDate, useCommonState} from '~common/state';
import {DateNavigatingInterval, DateRangeSelection} from '~common/types';
import type {AcFiscalYearRange} from '~core/graphql';
import {useAccountState} from '../../../accounts/state';

const d = debug('tacs.web.common.components.DateRangeSelector');

function formatDateText(startDate: LocalDate, endDate: LocalDate, selection: DateRangeSelection) {
	switch (selection) {
		case DateRangeSelection.SingleDate:
			return formatDate(startDate);
		case DateRangeSelection.FullMonth:
			return formatDate(startDate, {format: 'MMMM yyyy'});
		case DateRangeSelection.FullYear:
			return formatDate(startDate, {format: 'yyyy'});
		case DateRangeSelection.DateRange:
		default:
			return `${formatDate(startDate)} - ${formatDate(endDate)}`;
	}
}

export function getFyRangeForDate(date: LocalDate, fiscalYearRanges: AcFiscalYearRange[] | null, fiscalYearEnd: LocalDate) {
	let surroundingRange = fiscalYearRanges?.find(fy => !date.isBefore(fy.startDate) && !date.isAfter(fy.endDate));
	if (!surroundingRange) {
		const openFyRange = getFiscalYearRange(date, fiscalYearEnd);
		const partialFy = fiscalYearRanges?.find(fy => !openFyRange.start.isBefore(fy.startDate) && !openFyRange.start.isAfter(fy.endDate));
		if (partialFy) surroundingRange = {startDate: partialFy.endDate.plusDays(1), endDate: openFyRange.end};
		else surroundingRange = {startDate: openFyRange.start, endDate: openFyRange.end};
	}
	return surroundingRange;
}

function selectTab(selection: DateRangeSelection, hidePanel: () => void) {
	switch (selection) {
		case DateRangeSelection.SingleDate:
			return <CustomTab hidePanel={hidePanel} />;
		case DateRangeSelection.FullMonth:
			return <FullMonthTab hidePanel={hidePanel} />;
		case DateRangeSelection.FullYear:
			return <FullYearTab hidePanel={hidePanel} />;
		case DateRangeSelection.DateRange:
		default:
			return <CustomTab hidePanel={hidePanel} />;
	}
}

export const DateRangeSelector = memo(function DateRangeSelector() {
	const dispatch = useDispatch();
	const {isMobile} = useLayoutState();
	const {startDate, endDate, dateRangeSelection, dateNavigatingInterval, lockRangeInFy, allowFuture} = useCommonState();
	const {fiscalYearRanges, yearEnd} = useAccountState();
	const fiscalYearEnd = yearEnd || LocalDate.now().withMonth(12).withDayOfMonth(31);
	const [panelVisible, setPanelVisible] = useState(false);

	const rotate = panelVisible ? 'rotate(180deg)' : 'rotate(0)';

	const hidePanel = useCallback(() => {
		setPanelVisible(false);
	}, []);

	const getFyAwareNavRange = useCallback(
		(startD: LocalDate, endD: LocalDate, forward: boolean) => {
			const startRange = getFyRangeForDate(startD, fiscalYearRanges, fiscalYearEnd);
			const endRange = getFyRangeForDate(endD, fiscalYearRanges, fiscalYearEnd);
			if (!startRange.startDate.equals(endRange.startDate) || !startRange.endDate.equals(endRange.endDate)) {
				return forward ? {start: startD, end: startRange.endDate} : {start: endRange.startDate, end: endD};
			}
			return {start: startD, end: endD};
		},
		[fiscalYearEnd, fiscalYearRanges],
	);

	const navigateBack = useCallback(() => {
		if (panelVisible) hidePanel();
		switch (dateNavigatingInterval) {
			case DateNavigatingInterval.Day:
				dispatch(setDate({start: startDate.minusDays(1), end: startDate.minusDays(1)}));
				break;
			case DateNavigatingInterval.Month:
				if (dateRangeSelection === DateRangeSelection.SingleDate) dispatch(setDate({start: startDate.minusMonths(1), end: startDate.minusMonths(1)}));
				else {
					const newRange = {startDate: startDate.minusMonths(1), endDate: startDate.minusDays(1)};
					dispatch(
						setDate({
							...(lockRangeInFy
								? getFyAwareNavRange(newRange.startDate, newRange.endDate, false)
								: {
										start: newRange.startDate,
										end: newRange.endDate,
								  }),
						}),
					);
				}
				break;
			case DateNavigatingInterval.CalYear:
				if (dateRangeSelection === DateRangeSelection.SingleDate) dispatch(setDate({start: startDate.minusYears(1), end: startDate.minusYears(1)}));
				else dispatch(setDate({start: startDate.minusYears(1), end: startDate.minusDays(1)}));
				break;
			case DateNavigatingInterval.FisYear:
				{
					const currentFyRange = getFyRangeForDate(startDate, fiscalYearRanges, fiscalYearEnd);
					const previousFyRange = getFyRangeForDate(currentFyRange.startDate.minusDays(1), fiscalYearRanges, fiscalYearEnd);
					if (dateRangeSelection === DateRangeSelection.SingleDate)
						if (startDate.isAfter(currentFyRange.startDate)) dispatch(setDate({start: currentFyRange.startDate, end: currentFyRange.startDate}));
						else dispatch(setDate({start: previousFyRange.startDate, end: previousFyRange.startDate}));
					else dispatch(setDate({start: previousFyRange.startDate, end: previousFyRange.endDate}));
				}
				break;
			case DateNavigatingInterval.Quarter:
				if (dateRangeSelection === DateRangeSelection.SingleDate)
					dispatch(setDate({start: startDate.plusDays(1).minusMonths(3).minusDays(1), end: startDate.plusDays(1).minusMonths(3).minusDays(1)}));
				else {
					const newRange = {startDate: startDate.minusMonths(3), endDate: startDate.minusDays(1)};
					dispatch(
						setDate({
							...(lockRangeInFy
								? getFyAwareNavRange(newRange.startDate, newRange.endDate, false)
								: {
										start: newRange.startDate,
										end: newRange.endDate,
								  }),
						}),
					);
				}
				break;
			default:
				break;
		}
	}, [
		dateNavigatingInterval,
		dateRangeSelection,
		dispatch,
		fiscalYearEnd,
		fiscalYearRanges,
		getFyAwareNavRange,
		hidePanel,
		lockRangeInFy,
		panelVisible,
		startDate,
	]);

	const navigateForward = useCallback(() => {
		if (panelVisible) hidePanel();
		switch (dateNavigatingInterval) {
			case DateNavigatingInterval.Day:
				dispatch(setDate({start: endDate.plusDays(1), end: endDate.plusDays(1)}));
				break;
			case DateNavigatingInterval.Month:
				if (dateRangeSelection === DateRangeSelection.SingleDate) dispatch(setDate({start: endDate.plusMonths(1), end: endDate.plusMonths(1)}));
				else {
					const newRange = {start: endDate.plusDays(1), end: endDate.plusDays(1).plusMonths(1).minusDays(1)};
					dispatch(
						setDate({
							...(lockRangeInFy
								? getFyAwareNavRange(newRange.start, newRange.end, true)
								: {
										start: newRange.start,
										end: newRange.end,
								  }),
						}),
					);
				}
				break;
			case DateNavigatingInterval.CalYear:
				if (dateRangeSelection === DateRangeSelection.SingleDate) dispatch(setDate({start: endDate.plusYears(1), end: endDate.plusYears(1)}));
				else dispatch(setDate({start: endDate.plusDays(1), end: endDate.plusYears(1)}));
				break;
			case DateNavigatingInterval.FisYear:
				{
					const currentFyRange = getFyRangeForDate(startDate, fiscalYearRanges, fiscalYearEnd);
					const nextFyRange = getFyRangeForDate(currentFyRange.endDate.plusDays(1), fiscalYearRanges, fiscalYearEnd);
					if (dateRangeSelection === DateRangeSelection.SingleDate) dispatch(setDate({start: nextFyRange.startDate, end: nextFyRange.startDate}));
					else dispatch(setDate({start: nextFyRange.startDate, end: nextFyRange.endDate}));
				}
				break;
			case DateNavigatingInterval.Quarter:
				if (dateRangeSelection === DateRangeSelection.SingleDate)
					dispatch(setDate({start: startDate.plusDays(1).plusMonths(3).minusDays(1), end: startDate.plusDays(1).plusMonths(3).minusDays(1)}));
				else {
					const newRange = {startDate: endDate.plusDays(1), endDate: endDate.plusDays(1).plusMonths(3).minusDays(1)};
					dispatch(
						setDate({
							...(lockRangeInFy
								? getFyAwareNavRange(newRange.startDate, newRange.endDate, true)
								: {
										start: newRange.startDate,
										end: newRange.endDate,
								  }),
						}),
					);
				}
				break;
			default:
				break;
		}
	}, [
		dateNavigatingInterval,
		dateRangeSelection,
		dispatch,
		endDate,
		fiscalYearEnd,
		fiscalYearRanges,
		getFyAwareNavRange,
		hidePanel,
		lockRangeInFy,
		panelVisible,
		startDate,
	]);

	if (dateRangeSelection === DateRangeSelection.None) {
		return null;
	}

	return (
		<>
			<MenuItem icon="arrow left" onClick={navigateBack} />
			{isMobile && (
				<MenuItem
					onClick={() => {
						setPanelVisible(prev => !prev);
					}}
					style={{justifyContent: 'center'}}
				>
					<Icon name="calendar alternate" />
				</MenuItem>
			)}
			{!isMobile && (
				<MenuItem
					onClick={() => {
						setPanelVisible(prev => !prev);
					}}
					style={{width: 225, justifyContent: 'center'}}
				>
					{formatDateText(startDate, endDate, dateRangeSelection)}
					<Icon name="arrow alternate circle down outline" style={{marginLeft: '10px', transform: rotate, transition: 'all 0.2s linear'}} />
				</MenuItem>
			)}
			<MenuItem icon="arrow right" onClick={navigateForward} disabled={!allowFuture ? !endDate.isBefore(LocalDate.now()) : undefined} />
			<Transition visible={panelVisible} animation="slide down" duration={250} unmountOnHide>
				<div className={styles.panel}>
					<div className={styles.panelContent}>
						<PresetTab hidePanel={hidePanel} />
						{selectTab(dateRangeSelection, hidePanel)}
					</div>
				</div>
			</Transition>
		</>
	);
});
