import debug from 'debug';
import {Link} from 'react-router-dom';
import {List, Placeholder} from 'semantic-ui-react';
import {EmployeeStatusFilterEnum} from '~core/graphql';
import {getFullName} from '~lib/getFullName';
import {routes} from '../../../routes';
import {useGetEmployeeListEmployeesQuery} from './getEmployeeListEmployees';

const d = debug('tacs.web.accounts.components.employees.DisplayEmployeeList');

interface DisplayEmployeeListProps {
	accountInfoId: string;
}

export function DisplayEmployeeList(props: DisplayEmployeeListProps) {
	const {accountInfoId} = props;
	const {data, loading, error} = useGetEmployeeListEmployeesQuery({
		variables: {accountInfoId, filter: {status: EmployeeStatusFilterEnum.Employed}},
	});

	if (loading)
		return (
			<Placeholder>
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
			</Placeholder>
		);
	if (error) throw error;
	if (!data) throw new Error("Data couldn't be loaded");

	const employees = data.getEmployeesByAccountInfoId?.slice(0, 15);

	return (
		<List bulleted>
			{employees?.length ? (
				<>
					{employees?.map(employee => {
						return (
							<List.Item key={employee?.id}>
								<Link to={routes.to.viewEmployee({accountInfoId, employeeId: employee?.id || ''})}>
									{employee ? getFullName(employee.contact) : 'name not found'}
								</Link>
							</List.Item>
						);
					})}
					{employees.length - (data.getEmployeesByAccountInfoId?.length || 0) !== 0 && (
						<Link to={routes.to.employees({accountInfoId})}>
							View {(data.getEmployeesByAccountInfoId?.length || 0) - employees.length} other employees...
						</Link>
					)}
				</>
			) : (
				<List.Item>(No Employees added)</List.Item>
			)}
		</List>
	);
}
