import {LayoutData, useLayoutState} from '@imperium/layout';
import {debug} from 'debug';
import {matchPath} from 'react-router-dom';
import {dateRangeSelectionHook} from '~common/hooks/dateRangeSelection/dateRangeSelectionHook';
import {DateRangeSelection, DefaultDateSetting} from '~common/types';
import {AccountTypeEnum, Permission} from '~core/graphql';
import {isLoggedIn, needsPermission, needsRoute} from '~lib/permissionHelpers';
import {useAccountState} from '../accounts/state';
import {routes} from './routes';

const d = debug('tacs.web.calendar.layout');

export const layout: LayoutData = {
	permissions: [Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount],
	primaryMenu: [],
	dataHooks: [
		dateRangeSelectionHook({
			routeMatch: path => matchPath(path, {path: '/account/:id/calendar', exact: true}),
			selection: DateRangeSelection.FullMonth,
			defaultDateSetting: DefaultDateSetting.CurrentMonth,
		}),
	],
	secondaryMenu: [
		{
			text: 'Calendar',
			stateSelectorHook: [useLayoutState, useAccountState],
			visible: {
				...isLoggedIn(),
				...needsRoute('account'),
				'state.type': {$in: [AccountTypeEnum.CorporateAccount]},
			},
			icon: 'calendar alternate outline',
			dropdown: [
				{
					text: 'Calendar',
					icon: 'calendar alternate outline',
					stateSelectorHook: useLayoutState,
					to: data => (data.state.params?.accountInfoId ? routes.to.calendar({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					visible: {...needsPermission([Permission.ViewAdminPayrollAccount])},
					text: 'PVL Report',
					icon: 'car',
					stateSelectorHook: useLayoutState,
					to: data => (data.state.params?.accountInfoId ? routes.to.pvlReport({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					visible: {...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount])},
					text: 'PVL Journal Entries',
					icon: 'car',
					stateSelectorHook: useLayoutState,
					to: data => (data.state.params?.accountInfoId ? routes.to.pvlJournalEntries({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					visible: {...needsPermission([Permission.ViewAdminPayrollAccount])},
					text: 'Per Diem Report',
					icon: 'money bill alternate outline',
					stateSelectorHook: useLayoutState,
					to: data => (data.state.params?.accountInfoId ? routes.to.perDiemReport({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					visible: {...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount])},
					text: 'Per Diem Journal Entries',
					icon: 'money bill alternate outline',
					stateSelectorHook: useLayoutState,
					to: data => (data.state.params?.accountInfoId ? routes.to.perDiemJournalEntries({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					visible: {...needsPermission([Permission.ViewAdminPayrollAccount])},
					text: 'Maintenance Report',
					icon: 'wrench',
					stateSelectorHook: useLayoutState,
					to: data => (data.state.params?.accountInfoId ? routes.to.maintenanceReport({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
			],
		},
	],
};
