import {debug} from 'debug';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Dropdown, Form, FormInput} from 'semantic-ui-react';
import {AccountEventFeatureEnum} from '~core/graphql';
import {createDropdownOptionsFromEnum} from '~lib/createDropdownOptionsFromEnum';
import type {routes} from '../../routes';
import {setUserEventFeatureFilter, setUserEventOffsetFilter, useUserState} from '../../state';

const d = debug('tacs.web.user.components.UserEventTimeline.UserEventTimelineSidebar');

export function UserEventTimelineSidebar() {
	const {userId} = useParams<typeof routes.types.timeline>();
	const dispatch = useDispatch();
	const {userEventFeatureFilter} = useUserState();

	useEffect(() => {
		dispatch(setUserEventFeatureFilter(''));
	}, [dispatch, userId]);

	return (
		<Form>
			<FormInput label="Filter by Feature">
				<Dropdown
					fluid
					clearable
					placeholder="No Filter"
					selection
					search
					options={createDropdownOptionsFromEnum(AccountEventFeatureEnum)}
					value={userEventFeatureFilter || ''}
					onChange={(event, val) => {
						dispatch(setUserEventFeatureFilter(val.value as AccountEventFeatureEnum));
						dispatch(setUserEventOffsetFilter(0));
					}}
				/>
			</FormInput>
		</Form>
	);
}
