import {LocalDate} from '@js-joda/core';
import {LocalDatePicker, TForm} from '@thx/controls';
import {localDateSchemaType} from '@thx/yup-types';
import debug from 'debug';
import {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Button, Divider, Form, List, Dropdown, FormInput} from 'semantic-ui-react';
import {string, object} from 'yup';
import {Loading} from '~common/components';
import type {DirectorOfficeEnum} from '~core/graphql';
import {getFullName} from '~lib/getFullName';
import {useAsyncError} from '~lib/useAsyncError';
import {routes} from '../../../routes';
import {useCreateDirectorMutation} from '../DirectorCreateForm/createDirector';
import {useGetDirectorAddSidebarExistingContactListDataQuery} from './getDirectorAddSidebarExistingContactListData';

const d = debug('tacs.web.accounts.components.directors.DirectorAddSidebar');

const directorAddSidebarContactListValidation = object().shape({
	dateElected: localDateSchemaType().required('Date Elected is required'),
	dateSteppedDown: localDateSchemaType(),
	office: string().required('Office is required'),
});

interface DirectorAddSidebarContactListValidationSchemaType {
	dateElected: LocalDate;
	dateSteppedDown?: LocalDate;
	office: DirectorOfficeEnum;
}

const directorOfficeDropdownOptions = [
	{key: 'president', value: 'president', text: 'President'},
	{key: 'vicePresident', value: 'vicePresident', text: 'Vice President'},
];

export function DirectorAddSidebar() {
	const throwError = useAsyncError();
	const {push} = useHistory();
	const [selectedContactId, setSelectedContactId] = useState('');
	const {accountInfoId} = useParams<typeof routes.types.directors>();
	const {data, loading, error} = useGetDirectorAddSidebarExistingContactListDataQuery({variables: {accountInfoId}});

	const [createDirectorMutation, {loading: mutationLoading}] = useCreateDirectorMutation();

	if (loading) return <Loading />;
	if (error) throw error;
	if (!data) throw new Error("The data couldn't be loaded");

	const contacts = data.getCorporateAccountByAccountInfoId?.accountInfo.contacts?.filter(contact => {
		return (
			// filter out the directors
			!data.getCorporateAccountByAccountInfoId?.directors?.find(part => part?.contact?.id === contact?.id) &&
			// filter out the employees
			!data.getCorporateAccountByAccountInfoId?.accountInfo.employees?.find(emp => emp?.contact?.id === contact?.id)
		);
	});

	function createDirector(contactId: string, dateElected: LocalDate, dateSteppedDown: LocalDate | undefined, office: DirectorOfficeEnum) {
		createDirectorMutation({
			variables: {
				data: {
					contactId,
					accountInfoId,
					director: {
						dateElected,
						dateSteppedDown,
						office,
					},
				},
			},
		})
			.then(() => push(routes.to.directors({accountInfoId})))
			.catch(throwError);
	}

	return (
		<List>
			<List.Header as="h3">Choose From Existing Contacts</List.Header>
			{contacts?.length
				? contacts.map(contact => {
						return (
							<List.Item key={contact.id}>
								{selectedContactId === contact.id ? (
									<TForm<DirectorAddSidebarContactListValidationSchemaType>
										initialValues={{}}
										onSubmit={vals => createDirector(contact.id, vals.dateElected, vals.dateSteppedDown, vals.office)}
										validationSchema={directorAddSidebarContactListValidation}
									>
										{tFormProps => {
											const {setFieldValue, handleSubmit, values, hasErrors, hasWarnings, renderWarnings, fieldError} = tFormProps;
											return (
												<Form onSubmit={handleSubmit} error={hasErrors} warning={hasWarnings}>
													<Divider />
													<List>
														<List.Item>
															<FormInput autoFocus required label="Date elected" error={fieldError('dateElected')}>
																<LocalDatePicker
																	value={values.dateElected || undefined}
																	onChange={val => setFieldValue('dateElected', val)}
																	todayButton="Today"
																	maxDate={LocalDate.now()}
																/>
															</FormInput>
														</List.Item>
														<List.Item>
															<FormInput autoFocus label="Date stepped down" error={fieldError('dateSteppedDown')}>
																<LocalDatePicker
																	value={values.dateSteppedDown || undefined}
																	onChange={val => setFieldValue('dateSteppedDown', val)}
																	todayButton="Today"
																	maxDate={LocalDate.now()}
																/>
															</FormInput>
														</List.Item>
														<List.Item>
															<FormInput label="Office" required error={fieldError('office')}>
																<Dropdown
																	selection
																	options={directorOfficeDropdownOptions}
																	value={values.office || ''}
																	onChange={(e, val) => setFieldValue('office', val.value)}
																/>
															</FormInput>
														</List.Item>
														<List.Item>{renderWarnings()}</List.Item>
														<List.Item>
															<Button disabled={mutationLoading} floated="right" positive type="submit">
																Save
															</Button>
															<Button type="button" floated="right" onClick={() => setSelectedContactId('')}>
																Cancel
															</Button>
														</List.Item>
													</List>
													<Divider />
												</Form>
											);
										}}
									</TForm>
								) : (
									<Button fluid color="blue" onClick={() => setSelectedContactId(contact.id)}>
										Add {getFullName(contact)} as a Director
									</Button>
								)}
							</List.Item>
						);
				  })
				: 'none available...'}
		</List>
	);
}
