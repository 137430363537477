import debug from 'debug';
import type {DateNavigatingInterval, DateRangeSelection, DefaultDateSetting} from '~common/types';

const d = debug('tacs.web.common.hooks.dateRangeSelection.dateRangeSelectionSingleton');

export interface DateRangeOptions {
	selection: DateRangeSelection;
	allowFuture?: boolean;
	navigatingInterval?: DateNavigatingInterval;
	lockRangeInFy?: boolean;
	defaultDateSetting?: DefaultDateSetting;
}

export interface DateRangeOptionsHookParams extends DateRangeOptions {
	routeMatch: (route: string) => any;
}

class DateRangeSelectionMatcher {
	private options: DateRangeOptionsHookParams[] = [];

	/**
	 * Registers a route matcher and the required DateRangeSelection
	 * @param matcher
	 */
	register(matcher: DateRangeOptionsHookParams) {
		if (!this.options.includes(matcher)) {
			this.options.push(matcher);
		}
	}

	/**
	 * Returns all the registers route matchers and selection.
	 */
	getOptions() {
		return this.options;
	}
}

/**
 * This is the singleton instance.
 */
let instance: DateRangeSelectionMatcher | null = null;

/**
 * Return the singleton instance or create a new one.
 */
export function getInstance() {
	if (!instance) {
		instance = new DateRangeSelectionMatcher();
	}
	return instance;
}
