import debug from 'debug';
import {Dropdown, DropdownProps} from 'semantic-ui-react';
import {EmployeeStatusFilterEnum} from '~core/graphql';
import {getFullName} from '~lib/getFullName';
import {useUserState} from '../../../../user/state';
import {useGetEmployeesDropdownEmployeesQuery} from './getEmployeesDropdownEmployees';

const d = debug('tacs.web.accounts.components.employees.EmployeesDropdown');

interface EmployeesDropdownProps extends Omit<DropdownProps, 'options' | 'loading' | 'onChange' | 'value'> {
	accountInfoId: string;
	onChange?: (val?: string) => void;
	value?: string | null;
	defaultNull?: boolean;
	defaultToUser?: boolean;
	active?: boolean;
}

export function EmployeesDropdown(props: EmployeesDropdownProps) {
	const {userId} = useUserState();
	const {accountInfoId, additionalOptions = [], onChange, value, defaultNull, defaultToUser, active, ...rest} = props;
	const {data, loading, error} = useGetEmployeesDropdownEmployeesQuery({
		variables: {accountInfoId, filter: {status: active ? EmployeeStatusFilterEnum.Employed : EmployeeStatusFilterEnum.All}},
		onCompleted: selectionData => {
			if (onChange && selectionData.getEmployeesByAccountInfoId?.length && !defaultNull) {
				if (!value) {
					if (defaultToUser) {
						const usersEmployee = selectionData.getEmployeesByAccountInfoId.find(employee => userId === employee?.contact?.authorizedUser?.id);
						if (usersEmployee) {
							onChange(usersEmployee.id);
						} else {
							onChange(selectionData.getEmployeesByAccountInfoId[0]?.id);
						}
					} else {
						onChange(selectionData.getEmployeesByAccountInfoId[0]?.id);
					}
				}
				// Forces employee change if the accountInfoId was updated
				if (
					selectionData.getEmployeesByAccountInfoId.length > 0 &&
					!selectionData.getEmployeesByAccountInfoId?.find(employee => employee?.id === value)
				) {
					const usersEmployee = selectionData.getEmployeesByAccountInfoId.find(employee => userId === employee?.contact?.authorizedUser?.id);
					onChange(usersEmployee?.id || selectionData.getEmployeesByAccountInfoId[0]?.id);
				}
			}
		},
	});

	const options = data?.getEmployeesByAccountInfoId?.reduce((acc, employee) => {
		if (employee?.contact) {
			const name = getFullName(employee.contact);
			if (name) acc.push({key: employee.id, value: employee.id, text: name});
		}
		return acc;
	}, [] as {key: string; value: string; text: string}[]);

	function handleChange(e: React.SyntheticEvent<HTMLElement, Event>, val: DropdownProps) {
		if (val.value) {
			onChange && onChange(val.value.toString());
		} else {
			onChange && onChange();
		}
	}

	if (error) throw error;
	if (!data) return <Dropdown {...rest} options={[]} disabled placeholder="The data couldn't be loaded" />;

	return (
		<Dropdown
			search
			disabled={!data.getEmployeesByAccountInfoId?.length}
			{...rest}
			value={value || undefined}
			options={[...additionalOptions, ...(options || [])]}
			loading={loading}
			onChange={handleChange}
		/>
	);
}
