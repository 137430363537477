import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getAllTicketCategories.graphql';

const defaultOptions = {} as const;
export type GetAllTicketCategoriesQueryVariables = Types.Exact<{[key: string]: never}>;

export type GetAllTicketCategoriesQuery = {
	getAllTicketCategories?: Array<{id: string; name: string; defaultAgent: {id: string; givenName: string; surname: string}}> | null;
};

export type GetAllTicketCategoriesType = {id: string; name: string; defaultAgent: {id: string; givenName: string; surname: string}};

/**
 * __useGetAllTicketCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllTicketCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTicketCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTicketCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllTicketCategoriesQuery(
	baseOptions?: Apollo.QueryHookOptions<GetAllTicketCategoriesQuery, GetAllTicketCategoriesQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetAllTicketCategoriesQuery, GetAllTicketCategoriesQueryVariables>(Operations, options);
}
export function useGetAllTicketCategoriesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetAllTicketCategoriesQuery, GetAllTicketCategoriesQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetAllTicketCategoriesQuery, GetAllTicketCategoriesQueryVariables>(Operations, options);
}
export type GetAllTicketCategoriesQueryHookResult = ReturnType<typeof useGetAllTicketCategoriesQuery>;
export type GetAllTicketCategoriesLazyQueryHookResult = ReturnType<typeof useGetAllTicketCategoriesLazyQuery>;
export type GetAllTicketCategoriesQueryResult = Apollo.QueryResult<GetAllTicketCategoriesQuery, GetAllTicketCategoriesQueryVariables>;
