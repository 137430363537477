import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Dropdown, Form, Grid, FormInput} from 'semantic-ui-react';
import {AccountEventFeatureEnum} from '~core/graphql';
import {createDropdownOptionsFromEnum} from '~lib/createDropdownOptionsFromEnum';
import type {routes} from '../../../routes';
import {setAccountEventFeatureFilter, setAccountEventOffsetFilter, useAccountState} from '../../../state';

export function AccountEventSidebar() {
	const {accountInfoId} = useParams<typeof routes.types.timeline>();
	const dispatch = useDispatch();
	const {accountEventFeatureFilter} = useAccountState();

	useEffect(() => {
		dispatch(setAccountEventFeatureFilter(''));
	}, [dispatch, accountInfoId]);

	return (
		<Grid.Column>
			<Form>
				<FormInput label="Filter by Feature">
					<Dropdown
						fluid
						clearable
						placeholder="No Filter"
						selection
						search
						options={createDropdownOptionsFromEnum(AccountEventFeatureEnum)}
						value={accountEventFeatureFilter || ''}
						onChange={(event, val) => {
							dispatch(setAccountEventFeatureFilter(val.value as AccountEventFeatureEnum));
							dispatch(setAccountEventOffsetFilter(0));
						}}
					/>
				</FormInput>
			</Form>
		</Grid.Column>
	);
}
