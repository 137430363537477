import * as Apollo from '@apollo/client';

/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './authorizeUserOnOrganization.graphql';

const defaultOptions = {} as const;
export type AuthorizeUserOnOrganizationMutationVariables = Types.Exact<{
	organizationId: Types.Scalars['String'];
	userId: Types.Scalars['String'];
}>;

export type AuthorizeUserOnOrganizationMutation = {authorizeUserOnOrganization?: {id: string; name: string} | null};

export type AuthorizeUserOnOrganizationType = {id: string; name: string};

export type AuthorizeUserOnOrganizationMutationFn = Apollo.MutationFunction<
	AuthorizeUserOnOrganizationMutation,
	AuthorizeUserOnOrganizationMutationVariables
>;

/**
 * __useAuthorizeUserOnOrganizationMutation__
 *
 * To run a mutation, you first call `useAuthorizeUserOnOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorizeUserOnOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorizeUserOnOrganizationMutation, { data, loading, error }] = useAuthorizeUserOnOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAuthorizeUserOnOrganizationMutation(
	baseOptions?: Apollo.MutationHookOptions<AuthorizeUserOnOrganizationMutation, AuthorizeUserOnOrganizationMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<AuthorizeUserOnOrganizationMutation, AuthorizeUserOnOrganizationMutationVariables>(Operations, options);
}
export type AuthorizeUserOnOrganizationMutationHookResult = ReturnType<typeof useAuthorizeUserOnOrganizationMutation>;
export type AuthorizeUserOnOrganizationMutationResult = Apollo.MutationResult<AuthorizeUserOnOrganizationMutation>;
export type AuthorizeUserOnOrganizationMutationOptions = Apollo.BaseMutationOptions<
	AuthorizeUserOnOrganizationMutation,
	AuthorizeUserOnOrganizationMutationVariables
>;
