/* eslint-disable no-param-reassign */
import {createSliceHook} from '@imperium/state';
import {createSlice, type PayloadAction} from '@reduxjs/toolkit';

export type TransactionsViewMode = 'both' | 'scheduled' | 'invoices';
export interface SetTransactionsViewMode {
	viewMode: TransactionsViewMode;
}

export const state = createSlice({
	name: 'receivables',
	initialState: {
		viewMode: 'both' as TransactionsViewMode,
	},
	reducers: {
		setViewMode: (st, action: PayloadAction<SetTransactionsViewMode>) => {
			st.viewMode = action.payload.viewMode;
		},
	},
});

export const useReceivablesState = createSliceHook(state);

export const {setViewMode} = state.actions;
