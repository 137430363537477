import {useTForm} from '@thx/controls';
import debug from 'debug';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Form} from 'semantic-ui-react';
import {OrganizationDropdown} from '~common/components/OrganizationDropdown';
import {useAsyncError} from '~lib/useAsyncError';
import type {routes} from '../../routes';
import {useAuthorizeUserOnOrganizationMutation} from './authorizeUserOnOrganization';

const d = debug('tacs.web.user.components.AuthorizeOnOrganization');

export function AuthorizeOnOrganization() {
	const throwError = useAsyncError();
	const {userId} = useParams<typeof routes.types.user>();
	const [editMutation, {loading: mutationLoading}] = useAuthorizeUserOnOrganizationMutation();
	const [editClicked, setEditClicked] = useState(false);
	const {hasErrors, hasWarnings, handleSubmit, setFieldValue, values, resetForm} = useTForm({
		initialValues: {organizationId: '', userId: ''},
		onSubmit: formValues => {
			editMutation({
				variables: {
					organizationId: formValues.organizationId,
					userId,
				},
				refetchQueries: ['getUserById'],
			})
				.then(async data => {
					toast.success(`User authorized on ${data.data?.authorizeUserOnOrganization?.name}`);
					setEditClicked(false);
				})
				.catch(throwError);
		},
		enableReinitialize: true,
	});

	if (editClicked) {
		return (
			<Form error={hasErrors} warning={hasWarnings} onSubmit={handleSubmit}>
				<Form.Input autoFocus label="Organization">
					<OrganizationDropdown fluid selection value={values.organizationId} onChange={val => setFieldValue('organizationId', val)} />
				</Form.Input>
				<Button disabled={mutationLoading} positive type="submit" floated="right">
					Save
				</Button>
				<Button
					type="button"
					onClick={() => {
						resetForm();
						setEditClicked(false);
					}}
				>
					Cancel
				</Button>
			</Form>
		);
	}

	return (
		<Button fluid color="blue" onClick={() => setEditClicked(true)} compact>
			Authorize User on Organization
		</Button>
	);
}
