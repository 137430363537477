import {startCase} from 'lodash-es';
import {useHistory, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Form} from 'semantic-ui-react';
import {ConfirmModal, Loading} from '~common/components';
import {useAsyncError} from '~lib/useAsyncError';
import {routes} from '../../routes';
import {useArchiveFinancialAccountByIdMutation} from './ViewFinancialAccount/archiveFinancialAccountById';
import {useGetFinancialAccountByIdQuery} from './ViewFinancialAccount/getFinancialAccountById';

export default function ViewFinancialAccountSidebar() {
	const {accountInfoId, financialAccountId} = useParams<typeof routes.types.viewFinancialAccount>();
	const {push} = useHistory();
	const throwError = useAsyncError();

	const {data, error, loading} = useGetFinancialAccountByIdQuery({variables: {id: financialAccountId}});

	const [archiveMutation, {loading: mutationLoading}] = useArchiveFinancialAccountByIdMutation();

	if (error) throwError(error);
	if (loading || !data) return <Loading />;

	async function archive() {
		if (data?.getFinancialAccountById.financialAccount.id) {
			await archiveMutation({
				variables: {id: data?.getFinancialAccountById.financialAccount.id, version: data.getFinancialAccountById.financialAccount.version},
			})
				.then(() => {
					toast.success('Financial account archived.');
					push(routes.to.financialAccounts({accountInfoId}));
				})
				.catch(throwError);
		}
	}

	const accountLabel = startCase(data?.getFinancialAccountById.financialAccount.type);
	const hasRunningBalance = !!data?.getFinancialAccountById.financialAccount.glAccount?.id && !data.getFinancialAccountById.currentBalance?.isZero();

	return (
		<Form>
			<Form.Input>
				<Button
					loading={loading}
					fluid
					positive
					onClick={() => {
						push(routes.to.editFinancialAccount({accountInfoId, financialAccountId}));
					}}
				>
					{`Edit ${accountLabel}`}
				</Button>
			</Form.Input>
			<Form.Input>
				<ConfirmModal
					onConfirm={hasRunningBalance ? undefined : archive}
					headerText={hasRunningBalance ? `Unable to Archive this ${accountLabel}` : `Archive this ${accountLabel}`}
					headerIcon={hasRunningBalance ? {name: 'warning', color: 'orange'} : null}
					text={
						hasRunningBalance
							? `There is a running balances on the General Ledger Account of this ${accountLabel}. It can be archived once the General Ledger Accounts carries a balance of zero.`
							: `Are you sure you want to archive this ${accountLabel}?`
					}
					trigger={
						<Button
							loading={loading}
							disabled={mutationLoading || data.getFinancialAccountById.financialAccount.archived}
							fluid
							secondary
							content={`Archive ${accountLabel}`}
						/>
					}
				/>
			</Form.Input>
		</Form>
	);
}
