export enum DateRangeSelection {
	DateRange,
	SingleDate,
	FullMonth,
	FullYear,
	None,
}

export enum DateNavigatingInterval {
	CalYear,
	FisYear,
	Month,
	Quarter,
	Day,
}

export enum DefaultDateSetting {
	CurrentFy,
	TodayFy,
	CurrentMonth,
	BalanceSheetDefault,
	LastQuarter,
	CurrentCalYear,
}
