/* eslint-disable no-param-reassign */
import {createSliceHook} from '@imperium/state';
import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {CommunicationTypeEnum} from '~core/graphql';

export const state = createSlice({
	name: 'communications',
	initialState: {
		communicationFilter: [CommunicationTypeEnum.Note, CommunicationTypeEnum.Call, CommunicationTypeEnum.Email],
	},
	reducers: {
		setCommunicationFilter: (st, action: PayloadAction<CommunicationTypeEnum[]>) => {
			st.communicationFilter = action.payload;
		},
	},
});

export const useCommunicationState = createSliceHook(state);

export const {setCommunicationFilter} = state.actions;
