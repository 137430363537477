interface Props {
	preferredName?: string | null;
	givenName: string;
	surname: string;
}

export function getFullName(user: Props | undefined | null) {
	if (!user) return '';
	const {givenName, surname, preferredName} = user;
	if (preferredName) return preferredName;
	return givenName?.concat(' ', surname);
}
